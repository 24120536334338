import { isEqual, omit } from 'lodash';

import { suggestionsDropdownTypes } from 'components/pages/questions/enums';

export function getSessionFilters({
  currentSessionFilters, selectedSegmentValue, currentBreakdownSegment, newBreakdownSegment,
}) {
  const comparableCurrentBreakdownSegment = omit(currentBreakdownSegment, ['disabled']);
  const comparableNewBreakdownSegment = omit(newBreakdownSegment, ['disabled']);
  if (isEqual(comparableCurrentBreakdownSegment, comparableNewBreakdownSegment)) {
    return currentSessionFilters;
  }

  const sessionFilters = [...currentSessionFilters];
  let segmentId = null;
  let segmentType = currentBreakdownSegment.value;
  if (currentBreakdownSegment.suggestionsDropdownType === suggestionsDropdownTypes.customFields) {
    segmentType = 'custom_field';
    segmentId = currentBreakdownSegment.value;
  }

  const value = selectedSegmentValue.firstSegment;
  const filter = { segmentType, segmentId, value };
  const existingSessionFilterIndex = sessionFilters.findIndex((sessionFilter) => isEqual(sessionFilter, filter));
  if (existingSessionFilterIndex > 0) {
    return sessionFilters.slice(0, existingSessionFilterIndex);
  }

  sessionFilters.push(filter);
  return sessionFilters;
}
