exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".emptyStateWithImage__wrapper-3-x{background:#fff;text-align:center;align-items:center;color:#000;font-weight:600;display:flex;border-radius:12px;flex-direction:row;justify-content:space-between;box-shadow:0 4px 24px rgba(24,32,51,.03)}.emptyStateWithImage__leftGroup-tnB{text-align:left;padding-left:10%;max-width:400px}.emptyStateWithImage__leftGroup-tnB h1{font-style:normal;font-weight:600;font-size:24px;line-height:32px;color:#000;margin:0 0 6px;padding-bottom:16px}.emptyStateWithImage__half-1Rg{width:50%;flex-grow:1}.emptyStateWithImage__subTitle-2-C{font-style:normal;font-weight:400;font-size:16px;color:var(--black);margin-bottom:24px}.emptyStateWithImage__contentCentered-1I8{display:flex;justify-content:center;align-items:center;flex-direction:column;gap:16px}.emptyStateWithImage__contentCentered-1I8 h1{text-align:center;margin-bottom:0}.emptyStateWithImage__contentCentered-1I8 .emptyStateWithImage__half-1Rg{flex-grow:revert}.emptyStateWithImage__contentCentered-1I8 .emptyStateWithImage__leftGroup-tnB{padding-left:0;max-width:revert;width:100%}.emptyStateWithImage__contentCentered-1I8 .emptyStateWithImage__subTitle-2-C{font-size:20px;text-align:center}.emptyStateWithImage__emptyStateImage-1P1{height:262px;width:220px;background:url(\"/assets/robot-sketch.svg\") no-repeat 50%}", ""]);

// exports
exports.locals = {
	"wrapper": "emptyStateWithImage__wrapper-3-x",
	"wrapper": "emptyStateWithImage__wrapper-3-x",
	"leftGroup": "emptyStateWithImage__leftGroup-tnB",
	"leftGroup": "emptyStateWithImage__leftGroup-tnB",
	"half": "emptyStateWithImage__half-1Rg",
	"half": "emptyStateWithImage__half-1Rg",
	"subTitle": "emptyStateWithImage__subTitle-2-C",
	"subTitle": "emptyStateWithImage__subTitle-2-C",
	"contentCentered": "emptyStateWithImage__contentCentered-1I8",
	"contentCentered": "emptyStateWithImage__contentCentered-1I8",
	"emptyStateImage": "emptyStateWithImage__emptyStateImage-1P1",
	"emptyStateImage": "emptyStateWithImage__emptyStateImage-1P1"
};