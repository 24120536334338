import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { orderBy } from 'lodash';

import useStyles from 'hooks/useStyles';

import servicesStore from 'stores/servicesStore';
import { Events } from 'trackers/analytics/enums';
import InfiniteScrollTable from 'components/common/InfiniteScrollTable';
import WidgetHeader from 'components/common/WidgetHeader';
import Switch from 'components/controls/Switch';
import MenuButton from 'components/common/MenuButton';
import EmptyStateWithImage from 'components/pages/analyze/EmptyStateWithImage';
import CheckListWithPresets from 'components/controls/CheckListWithPresets';
import Dropdown from 'components/controls/Dropdown';
import MultiLevelMenuPopup from 'components/common/MultiLevelMenuPopup';

import {
  getDefaultSelectedSubColumns, getColumnsOptionsByStage, getTableColumnByKey, getDrilldownMenuParams,
} from 'components/widgets/segmentsDrilldown/logic/segmentsDrilldown';
import { tableDataWithFiltersByColumns, getUpliftMinMaxByMetric } from 'components/pages/analyze/AttribuitonTable/logic/AttributionSegmentsTableParseData';
import { columnControllersKeys } from 'components/widgets/segmentsDrilldown/enums';
import { groupBySegmentsOptions, getOptionsWithSegmentMapping } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { getSegmentsOptionsFields } from 'components/pages/analyze/OverviewTab/logic';
import { segmentsKeys, upliftLabel, metricsTypes } from 'components/common/logic/enums';

import style from 'styles/segmentsDrilldown/segmentsDrilldown.css';

const styles = style.locals || {};

function SegmentsDrilldown({
  data,
  isAttribution,
  totals,
  isLoaded,
  isLoadedUplift,
  timeframe,
  isCompareToPrevious,
  onChangeCompareToPrevious,
  selectedStage,
  breakdownSegment,
  updateBreakdownSegment,
  customFieldsIdToLabelMap,
  customUtmsWhitelist,
  tableHeight,
  upliftColumnsKeys,
  updateUpliftColumnsKeys,
  defaultDrilldownSelectedMetric,
  previousBreakdownSegments,
}) {
  useStyles([style]);

  const [columnsOptions, setColumnsOptions] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedSubColumns, setSelectedSubColumns] = useState({});
  const [sortByColumn, setSortByColumn] = useState({ id: 'attributed', desc: true });
  const [filtersByColumn, setFiltersByColumn] = useState({});
  const [tableData, setTableData] = useState(data || []);
  const [menuPopupData, setMenuPopupData] = useState({
    isShow: false,
    position: {},
    rowData: {},
  });

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    const options = getColumnsOptionsByStage({ stage: selectedStage, breakdownSegment, isAttribution });
    const updatedSelectedColumns = [segmentsKeys.firstSegment, ...options.map(({ value }) => value)];

    setColumnsOptions(options);
    setSelectedColumns(updatedSelectedColumns);
    setSelectedSubColumns(getDefaultSelectedSubColumns({ columns: updatedSelectedColumns, metrics: [metricsTypes.attributed, defaultDrilldownSelectedMetric] }));
  }, [selectedStage, isAttribution, breakdownSegment]);

  function onUpdateFiltersByColumn({ filters }) {
    const filteredByColumnsItems = tableDataWithFiltersByColumns({ tableData: data, filtersByColumn: filters, isRemoveFunnelKey: false });
    setTableData(filteredByColumnsItems);
    setFiltersByColumn(filters);
  }

  function onChangeSubColumnsSelection({ parentColumn, subColumn }) {
    const selectedByColumn = selectedSubColumns[parentColumn] || [];
    if (subColumn === columnControllersKeys.uplift) {
      updateUpliftColumnsKeys({ columnKey: `${parentColumn}${upliftLabel}` });
    }

    if (selectedByColumn.includes(subColumn)) {
      const shouldRemoveSubColumnFromObject = selectedByColumn.length === 1;
      if (shouldRemoveSubColumnFromObject) {
        const updatedSelectedOptionsColumnController = { ...selectedSubColumns };
        delete updatedSelectedOptionsColumnController[parentColumn];
        setSelectedSubColumns(updatedSelectedOptionsColumnController);
        return;
      }
      setSelectedSubColumns((prev) => ({
        ...prev,
        [parentColumn]: selectedByColumn.filter((item) => item !== subColumn),
      }));
      return;
    }

    const updatedColumns = [...selectedByColumn, subColumn];
    setSelectedSubColumns((prev) => ({
      ...prev,
      [parentColumn]: updatedColumns,
    }));

    servicesStore.eventTracker.track({
      eventName: Events.funnelDrilldown.selectReferencesButton,
      properties: {
        column: parentColumn,
        isUpliftChecked: updatedColumns.includes(columnControllersKeys.uplift),
        isShareOfTotalChecked: updatedColumns.includes(columnControllersKeys.shareOfTotal),
        isCompareToAverageChecked: updatedColumns.includes(columnControllersKeys.compare),
        isPacingForChecked: updatedColumns.includes(columnControllersKeys.pacing),
      },
    });
  }

  function onChangeColumnsSelection({ columns }) {
    setSelectedColumns([segmentsKeys.firstSegment, ...columns]);

    servicesStore.eventTracker.track({
      eventName: Events.funnelDrilldown.selectColumns,
      properties: {
        metricsCount: columns.length,
        metrics: columns,
      },
    });
  }

  const upliftMinMaxByMetric = getUpliftMinMaxByMetric({ data: tableData, upliftColumnsKeys });
  const tableColumns = selectedColumns.map((columnKey) => getTableColumnByKey({
    columnKey,
    upliftMinMaxByMetric,
    selectedSubColumns,
    selectedStage,
    timeframe,
    breakdownSegment,
    sortByColumn,
    setSortByColumn,
    filtersByColumn,
    onUpdateFiltersByColumn,
    onChangeSubColumnsSelection,
    tableData,
    totals,
    isLoaded,
    isLoadedUplift,
    isCompareToPrevious,
    subColumnClassName: {
      header: styles.breakdownTableSubHeadCell,
      cell: styles.breakdownTableInnerCell,
      icon: styles.breakdownTableSubHeadIcon,
      tooltip: styles.breakdownTableSubHeadTooltip,
    },
  }));
  const orderedTableColumns = orderBy(tableColumns, ['priority'], ['asc']);

  function onClickDrilldownRow({ rowData, event }) {
    if (!rowData.firstSegment) {
      return;
    }

    const marginClickPosition = 84;
    const left = event.clientX - marginClickPosition;
    const top = event.clientY - marginClickPosition;
    setMenuPopupData({ isShow: true, position: { left, top }, rowData });
  }

  function onChangeBreakdownSegment({ segment, shouldAddNewBreadcrumb = true }) {
    const dataBySelectedRow = menuPopupData.rowData;
    updateBreakdownSegment({ segment, selectedRow: dataBySelectedRow, shouldAddNewBreadcrumb });

    servicesStore.eventTracker.track({
      eventName: Events.funnelDrilldown.selectBreakdown,
      properties: {
        dimension: segment.suggestionsDropdownType,
      },
    });
  }

  function onToggleCompareTo() {
    const isCompareToPreviousAfterChange = !isCompareToPrevious;
    onChangeCompareToPrevious({ isCompare: isCompareToPreviousAfterChange });

    servicesStore.eventTracker.track({
      eventName: Events.funnelDrilldown.toggledCompareTo,
      properties: {
        status: isCompareToPreviousAfterChange ? 'enabled' : 'disabled',
      },
    });
  }

  function closeMenuPopup() {
    setMenuPopupData({ isShow: false, position: {}, rowData: {} });
  }

  const breakdownSegmentsOptions = useMemo(() => getOptionsWithSegmentMapping({
    options: groupBySegmentsOptions({ options: getSegmentsOptionsFields({ customFieldsIdToLabelMap, customUtmsWhitelist }) }),
    disabledOptions: previousBreakdownSegments,
  }), [previousBreakdownSegments, customFieldsIdToLabelMap, customUtmsWhitelist]);

  return (
    <div>
      <WidgetHeader
        isHideAddToReport
        widgetHeaderConfig={{}}
      >
        <Dropdown
          selectedKey={breakdownSegment}
          options={breakdownSegmentsOptions}
          onChange={(segment) => onChangeBreakdownSegment({ segment, shouldAddNewBreadcrumb: false })}
          dropdownLabel="Breakdown by"
          isSearchable
          className={styles.segmentDropdownMenu}
        />
        <CheckListWithPresets
          options={columnsOptions}
          checkListTitle="Manage columns and metrics"
          checkListToggleLabel="Select Columns"
          checkListToggleLabelPrefix="Metrics "
          istSelectOptionsAsLabel
          selectedOptions={selectedColumns}
          setSelectedOptions={(columns) => onChangeColumnsSelection({ columns })}
        />
        <MenuButton
          title="Compare to prev. period"
          withArrowIndication={false}
          rightIconRerender={(
            <Switch
              onSwitchClick={() => onToggleCompareTo({ isCompareToPrevious })}
              isActive={isCompareToPrevious}
              dataTestId="switch-compare-to-prev"
            />
          )}
        />
      </WidgetHeader>

      {isLoaded && data.length === 0 ? (
        <EmptyStateWithImage
          imageClassName={styles.answersEmptyStateImage}
          title="Oops! Something went wrong"
          subTitle="Try changing the filters or the timeframe"
          height="460px"
          isContentCentered
        />
      ) : (
        <InfiniteScrollTable
          tableData={tableData}
          tableColumns={orderedTableColumns}
          sortByColumn={sortByColumn}
          isLoaded={isLoaded}
          className={styles.breakdownWrapper}
          tableClassName={styles.breakdownTable}
          cellClassName={styles.breakdownTableCell}
          tableHeight={tableHeight}
          shouldShowColumnsOnNoValues
          onRowClick={(rowData, rowIndex, event) => onClickDrilldownRow({ rowData, event })}
        />
      )}

      {menuPopupData.isShow && (
      <div className={styles.multiLevelMenuContainer} style={{ ...menuPopupData.position }}>
        <MultiLevelMenuPopup
          menuOptions={getDrilldownMenuParams({
            onClickOption: (segment) => {
              onChangeBreakdownSegment({ segment });
              closeMenuPopup();
            },
            classNameMenu: styles.multiLevelMenuDropdownMenu,
            selectedStage,
            timeframe,
            valueToNavigate: menuPopupData.rowData?.firstSegment,
            breakdownSegment,
            breakdownSegmentsOptions,
          })}
          onClosePopup={() => closeMenuPopup()}
        />
      </div>
      )}
    </div>
  );
}

export default inject(
  ({
    userStore: {
      userAccount: {
        customFieldsIdToLabelMap,
        customUtmsWhitelist,
      },
    },
  }) => ({
    customFieldsIdToLabelMap,
    customUtmsWhitelist,
  }),
  observer
)(SegmentsDrilldown);
