import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import Table from 'components/controls/Table';
import Spinner from 'components/pages/journeys/Spinner';
import SearchInput from 'components/controls/SearchInput';

import { skeletonTableRows } from 'components/common/enums';

import analyzeStyle from 'styles/analyze/analyze.css';
import style from 'styles/common/infiniteScrollTable.css';

const styles = style.locals || {};
const analyzeStyles = analyzeStyle.locals || {};

function InfiniteScrollTable({
  tableData,
  tableColumns,
  isLoadingMore,
  handleShowMoreClick,
  hasMoreData,
  defaultPageSize = 20,
  onSearch,
  isSearchIsDisabled,
  isLoaded,
  skeletonRowsCount = skeletonTableRows,
  tableHeight = 440,
  sortByColumn = {},
  shouldShowColumnsOnNoValues = false,
  className,
  tableClassName,
  cellClassName,
  onRowClick,
}) {
  useStyles([style, analyzeStyle]);
  const [updatedTableData, setUpdatedTableData] = useState([]);

  useEffect(() => {
    setUpdatedTableData(tableData);
  }, [tableData]);

  useEffect(() => {
    if (tableData.length === 0 && !isLoaded) {
      const emptyTableColumns = {};
      tableColumns.forEach((column) => {
        emptyTableColumns[column.id] = null;
      });
      setUpdatedTableData(Array(skeletonRowsCount).fill(emptyTableColumns));
    }
  }, [isLoaded, tableData]);

  return (
    <div className={styles.tableWrapper}>
      {onSearch && (
      <SearchInput
        placeholder="Search..."
        onSearch={(value) => onSearch(value)}
        classes={{ input: styles.inputSearch }}
        disabled={isSearchIsDisabled}
      />
      )}
      <div className={classnames(styles.backTable, className)}>
        {tableData.length === 0 && isLoaded && !shouldShowColumnsOnNoValues ? (
          <div className={styles.noValues}>
            No values
          </div>
        ) : (
          <>
            <Table
              style={{ height: tableHeight }}
              bodyCustomClass={analyzeStyles.customBodyClass}
              className={classnames(analyzeStyles.channelsImpactsTable, tableClassName)}
              rowGroupClassName={analyzeStyles.rowGroup}
              rowClassName={analyzeStyles.row}
              headerClassName={analyzeStyles.header}
              headGroupClassName={analyzeStyles.headGroup}
              headRowClassName={analyzeStyles.headerRow}
              footerClassName={analyzeStyles.footer}
              footRowClassName={analyzeStyles.footerRow}
              cellClassName={classnames(analyzeStyles.cell, cellClassName)}
              data={updatedTableData}
              columns={tableColumns}
              noPadding
              minRows={0}
              showPagination
              infiniteScroll
              infiniteScrollSelector="div > div.rt-table"
              handleShowMoreClick={() => (handleShowMoreClick ? handleShowMoreClick() : null)}
              hasMoreData={!isLoadingMore && hasMoreData}
              scrollTriggerOffset={1}
              defaultPageSize={defaultPageSize}
              defaultSorted={[sortByColumn]}
              onRowClick={onRowClick}
            />
            {isLoadingMore && (
              <div className={styles.loadingMore} data-testid="load-more-spinner">
                <Spinner />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default InfiniteScrollTable;
