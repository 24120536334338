import React from 'react';
import ReactDOMServer from 'react-dom/server';

export function getPermissionsTooltip() {
  return ReactDOMServer.renderToStaticMarkup(
    <div style={{
      display: 'flex', flexDirection: 'column', textAlign: 'left', gap: '8px', margin: '2px 0',
    }}
    >
      <b>Admin:</b>
      <ul style={{ padding: 0, margin: '0 0 0 12px' }}>
        <li>
          Full access to all pages in the platform.
        </li>
        <li>
          Can update funnel settings and integrations.
        </li>
        <li>
          Can manage user settings and invite new users.
        </li>
      </ul>

      <b>User:</b>
      <ul style={{ padding: 0, margin: '0 0 0 12px' }}>
        <li>
          Access to all analysis and planning features.
        </li>
        <li>
          Can edit channel mappings.
        </li>
        <li>
          Cannot update funnel settings or integrations.
        </li>
        <li>
          Cannot manage user settings and invite new users.
        </li>
      </ul>
    </div>
  );
}
