import moment from './moment-fquarter';

import { TIMEFRAME_VALUES } from '../components/utils/timeframe';

class Timeframe {
  constructor() {
    this.amountOfMonthsInYear = 12;
    this.timeFrameValueToMomentParams = {
      [TIMEFRAME_VALUES.PREV_DAY]: {
        unit: 'day',
        amountOfTimeUnitToAdd: -1,
      },
      [TIMEFRAME_VALUES.WEEK]: {
        unit: 'isoWeek',
        amountOfTimeUnitToAdd: 0,
      },
      [TIMEFRAME_VALUES.PREV_WEEK]: {
        unit: 'isoWeek',
        amountOfTimeUnitToAdd: -1,
      },
      [TIMEFRAME_VALUES.MONTH]: {
        unit: 'month',
        amountOfTimeUnitToAdd: 0,
      },
      [TIMEFRAME_VALUES.PREV_MONTH]: {
        unit: 'month',
        amountOfTimeUnitToAdd: -1,
      },
      [TIMEFRAME_VALUES.NEXT_MONTH]: {
        unit: 'month',
        amountOfTimeUnitToAdd: 1,
      },
      [TIMEFRAME_VALUES.QUARTER]: {
        unit: 'quarter',
        amountOfTimeUnitToAdd: 0,
      },
      [TIMEFRAME_VALUES.PREV_QUARTER]: {
        unit: 'quarter',
        amountOfTimeUnitToAdd: -1,
      },
      [TIMEFRAME_VALUES.NEXT_QUARTER]: {
        unit: 'quarter',
        amountOfTimeUnitToAdd: 1,
      },
      [TIMEFRAME_VALUES.YEAR]: {
        unit: 'year',
        amountOfTimeUnitToAdd: 0,
      },
      [TIMEFRAME_VALUES.PREV_YEAR]: {
        unit: 'year',
        amountOfTimeUnitToAdd: -1,
      },
      [TIMEFRAME_VALUES.NEXT_YEAR]: {
        unit: 'year',
        amountOfTimeUnitToAdd: 1,
      },
    };
  }

  getTimeframeParams({
    value,
    startDate,
    endDate,
    isRelative,
    excludeToday,
    excludeThisMonthData,
    fiscalYearFirstMonth = 0,
    useEndOfToday = true,
    rollingValue,
    shouldUseEndOfPeriod,
  }) {
    const timeFrameParams = {
      startDate: moment().startOf('month').toDate(),
      endDate: new Date(),
    };
    const type = value;
    const endOfToday = moment().endOf('day').toDate();
    const timeFrameMomentParams = this.timeFrameValueToMomentParams[type];
    const useEndOfTodayInsteadEndOfPeriod = !shouldUseEndOfPeriod && timeFrameMomentParams?.amountOfTimeUnitToAdd === 0 && useEndOfToday;

    let userFiscalYearFirstMonth = fiscalYearFirstMonth + 1;
    const fiscalYearStartsBeforeEndOfYear = fiscalYearFirstMonth < 0;
    if (fiscalYearStartsBeforeEndOfYear) {
      userFiscalYearFirstMonth = this.amountOfMonthsInYear + fiscalYearFirstMonth + 1;
    }

    if (type === 'rolling') {
      const [, amountOfUnit, unit] = rollingValue.split('_');
      const dontIncludeToday = rollingValue.includes('day') || excludeThisMonthData;
      const amount = dontIncludeToday ? amountOfUnit - 1 : amountOfUnit;

      let endDateCalculated = endOfToday;
      if (excludeToday) {
        endDateCalculated = moment().subtract(1, 'day').endOf('day').toDate();
      }
      if (excludeThisMonthData && !isRelative) {
        endDateCalculated = moment().subtract(1, unit).endOf(unit).toDate();
      }

      let startDateCalculated = moment(endDateCalculated).subtract(amount, unit).startOf('day').toDate();
      if (!isRelative) {
        startDateCalculated = moment(endDateCalculated).subtract(amount, unit).startOf(unit).toDate();
      }

      timeFrameParams.startDate = startDateCalculated;
      timeFrameParams.endDate = endDateCalculated;

      timeFrameParams.previousEndDate = moment(startDateCalculated).subtract(1, 'days').endOf('day').toDate();
      if (isRelative) {
        timeFrameParams.previousStartDate = moment(timeFrameParams.previousEndDate).subtract(amount, unit).startOf('day').toDate();
      } else {
        const daysDiff = moment(endDateCalculated).diff(moment(startDateCalculated), 'days');
        timeFrameParams.previousStartDate = moment(timeFrameParams.previousEndDate).subtract(daysDiff, 'days').startOf('day').toDate();
      }

      return timeFrameParams;
    }

    if (type.includes('month') || type.includes('week') || type.includes('day')) {
      const dateDetails = moment().add(timeFrameMomentParams.amountOfTimeUnitToAdd, timeFrameMomentParams.unit);
      const startDateCalculated = moment(dateDetails).startOf(timeFrameMomentParams.unit).toDate();
      const endDateCalculated = useEndOfTodayInsteadEndOfPeriod ? endOfToday : moment(dateDetails).endOf(timeFrameMomentParams.unit).toDate();

      timeFrameParams.startDate = startDateCalculated;
      timeFrameParams.endDate = endDateCalculated;

      timeFrameParams.previousStartDate = moment(startDateCalculated).subtract(1, timeFrameMomentParams.unit).toDate();
      timeFrameParams.previousEndDate = moment(startDateCalculated).subtract(1, 'days').endOf('day').toDate();

      return timeFrameParams;
    }

    if (type.includes('quarter')) {
      const dateDetails = moment().add(timeFrameMomentParams.amountOfTimeUnitToAdd, timeFrameMomentParams.unit).fquarter(userFiscalYearFirstMonth);
      const startDateCalculated = moment(dateDetails.start).startOf('day').toDate();
      const endDateCalculated = useEndOfTodayInsteadEndOfPeriod ? endOfToday : moment(dateDetails.end).endOf('day').toDate();

      timeFrameParams.startDate = startDateCalculated;
      timeFrameParams.endDate = endDateCalculated;

      timeFrameParams.previousStartDate = moment(startDateCalculated).subtract(3, 'month').toDate();
      timeFrameParams.previousEndDate = moment(startDateCalculated).subtract(1, 'days').endOf('day').toDate();

      return timeFrameParams;
    }

    if (type.includes('year')) {
      const currentDate = endOfToday;
      const year = currentDate.getFullYear();
      const fiscalYearStartsBeforeJanuary = fiscalYearFirstMonth < 0;
      let fiscalMonth = fiscalYearFirstMonth;
      if (fiscalYearStartsBeforeJanuary) {
        fiscalMonth = this.amountOfMonthsInYear + fiscalYearFirstMonth;
      }
      const fiscalMonthBeforeCurrentMonth = currentDate.getMonth() >= fiscalMonth;
      let fiscalYear = year;
      if (!fiscalMonthBeforeCurrentMonth) {
        fiscalYear = year - 1;
      }
      const fiscalYearStart = moment(`${fiscalYear} ${fiscalMonth + 1}`, 'YYYY M');

      const startDateCalculated = moment(fiscalYearStart).add(timeFrameMomentParams.amountOfTimeUnitToAdd, timeFrameMomentParams.unit).toDate();
      const endDateCalculated = useEndOfTodayInsteadEndOfPeriod ? endOfToday : moment(startDateCalculated).add(this.amountOfMonthsInYear, 'month').subtract(1, 'day').endOf('day')
        .toDate();

      timeFrameParams.startDate = startDateCalculated;
      timeFrameParams.endDate = endDateCalculated;

      timeFrameParams.previousStartDate = moment(startDateCalculated).subtract(this.amountOfMonthsInYear, 'month').toDate();
      timeFrameParams.previousEndDate = moment(startDateCalculated).subtract(1, 'days').endOf('day').toDate();

      return timeFrameParams;
    }

    if (type === 'custom') {
      timeFrameParams.startDate = new Date(startDate);
      timeFrameParams.endDate = new Date(endDate);

      const diffOfDays = Math.ceil(moment(endDate).diff(moment(startDate), 'days', true));
      timeFrameParams.previousStartDate = moment(startDate).clone().subtract(diffOfDays, 'days').startOf('day')
        .toDate();
      timeFrameParams.previousEndDate = moment(startDate).subtract(1, 'days').endOf('day').toDate();

      return timeFrameParams;
    }

    return timeFrameParams;
  }
}

export default new Timeframe();
