import React from 'react';
import Component from 'components/Component';
import Page from 'components/Page';
import style from 'styles/plan/plan.css';
import Attribution from 'components/pages/Attribution';
import ChannelsSettings from 'components/pages/settings/channels/Channels';
import Goals from 'components/pages/settings/goals/Goals';
import PlatformsIntegration from 'components/pages/PlatformsIntegration';

export default class Settings extends Component {
  style = style;

  render() {
    const { children, ...otherProps } = this.props;
    const childrenWithProps = React.Children.map(
      children,
      (child) => React.cloneElement(child, otherProps)
    );

    const attributionActive = this.props.children ? this.props.children.type === Attribution : null;
    const channelsSettingsActive = this.props.children ? this.props.children.type === ChannelsSettings : null;
    const goalsActive = this.props.children ? this.props.children.type === Goals : null;
    const platformsActive = this.props.children ? this.props.children.type === PlatformsIntegration : null;

    return (
      <div>
        <Page contentClassName={this.classes.content} innerClassName={(attributionActive || channelsSettingsActive || goalsActive || platformsActive) ? '' : this.classes.pageInner} width="100%">
          <div>
            {childrenWithProps}
          </div>
        </Page>
      </div>
    );
  }
}
