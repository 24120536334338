import React from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import Tooltip from 'components/controls/Tooltip';

import style from 'styles/common/breadcrumbsBudges.css';

import arrowIcon from 'assets/funnel-view-icons/arrowBreakdownRight.svg';

const styles = style.locals || {};

export default function BreadcrumbsBudges({ breadcrumbs = [] }) {
  useStyles([style]);

  return (
    <div className={styles.breadcrumbsBudgesContainer}>
      {breadcrumbs.map((item, index) => (
        <React.Fragment key={`breadcrumb-${item.label}-index`}>
          <div
            className={classnames(styles.breadcrumbsBudge, item.disabled && styles.disabled)}
            onClick={() => (!item.disabled && item.onClick ? item.onClick(index) : null)}
          >
            <Tooltip
              place="bottom"
              id={`${item.label}-breadcrumbs-tooltip`}
              className={styles.breadcrumbsBudgeTitleContainer}
              tip={`${item.label} ${item.selectedOption ? `(${item.selectedOption})` : ''}`}
            >
              <div className={styles.selectedOptionTooltip}>
                {item.label}
              </div>
              {item.selectedOption ? (
                <div className={classnames(styles.selectedOption, styles.selectedOptionTooltip)}>
                  {`(${item.selectedOption})`}
                </div>
              ) : null}
            </Tooltip>
          </div>
          {index !== breadcrumbs.length - 1
            ? (
              <div className={styles.iconContainer}>
                <img src={arrowIcon} alt="breakdown by" />
              </div>
            )
            : null}
        </React.Fragment>
      ))}
    </div>
  );
}
