import { action, decorate, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';

import hydrate from 'stores/hydrate';
import serverCommunication from 'data/serverCommunication';
import userStore from 'stores/userStore';
import attributionStore from 'stores/attributionStore';
import servicesStore from 'stores/servicesStore';

import { customMetricsNames } from 'enums';
import { Coralogix } from 'modules/infra/coralogix';
import { widgetsConcatResultFunction } from 'components/pages/analyze/enums';
import { getFlagByWidgetName } from 'stores/logic/attributionStore';

class AnalysisStore {
  constructor() {
    this.accountWithoutData = false;
    this.dataPerWidget = {};
    this.configPerWidget = {};
    this.widgetRequestIdToRequestTime = {};
    this.LDFlags = [];
  }

  updateConfigPerWidget({
    widget, configKeyName, configValue, resultKeyName = 'current',
  }) {
    const configPerWidget = cloneDeep(this.configPerWidget);
    if (!configPerWidget[widget]) {
      configPerWidget[widget] = {};
    }
    if (!configPerWidget[widget][resultKeyName]) {
      configPerWidget[widget][resultKeyName] = [{}];
    }
    configPerWidget[widget][resultKeyName][0][configKeyName] = configValue;
    this.configPerWidget = { ...configPerWidget };
  }

  async getWidgetsData({
    widgets, config = {}, resultKeyName = 'current', isConcatResult = false,
  }) {
    const { UID } = userStore.userMonthPlan;
    const region = attributionStore.isAccountMode ? userStore.userMonthPlan.accountViewRegion : userStore.userMonthPlan?.region;

    const requestId = uuidv4();
    const requestTime = new Date().getTime();
    const configPerWidget = { ...this.configPerWidget };

    const body = {
      region,
      requestId,
      requestStoreParams: {
        widgets,
        widgetsConfig: config,
      },
    };

    try {
      for (const widget of widgets) {
        if (!this.dataPerWidget[widget]) {
          this.dataPerWidget[widget] = {};
        }
        if (!configPerWidget[widget]) {
          configPerWidget[widget] = {};
        }
        if (!this.widgetRequestIdToRequestTime[widget]) {
          this.widgetRequestIdToRequestTime[widget] = {};
        }
        if (!this.dataPerWidget[widget][resultKeyName]) {
          this.dataPerWidget[widget][resultKeyName] = {};
        }
        if (!configPerWidget[widget][resultKeyName]) {
          configPerWidget[widget][resultKeyName] = [{}];
        }
        if (!this.widgetRequestIdToRequestTime[widget][resultKeyName]) {
          this.widgetRequestIdToRequestTime[widget][resultKeyName] = {};
        }

        this.dataPerWidget[widget][resultKeyName].isLoaded = false;
        this.dataPerWidget[widget][resultKeyName].requestId = requestId;
        this.widgetRequestIdToRequestTime[widget][resultKeyName][requestId] = requestTime;
      }

      const response = await serverCommunication.serverRequest('POST', 'analysis/widgets', JSON.stringify(body), region);
      const responseData = await response.json();
      const { data } = responseData;
      if (data.errorCode === 'ACCOUNT_WITHOUT_DATA') {
        this.accountWithoutData = true;
      }
      const { requestId: responseRequestId } = data;

      for (const widget of widgets) {
        const widgetDataRequestId = this.dataPerWidget[widget][resultKeyName].requestId;
        const isHavingWidgetDataNewer = this.widgetRequestIdToRequestTime[widget][resultKeyName][widgetDataRequestId] > this.widgetRequestIdToRequestTime[widget][resultKeyName][responseRequestId];
        if (isHavingWidgetDataNewer && widgetDataRequestId !== responseRequestId) {
          continue;
        }

        let newWidgetData = data[widget];
        const currentWidgetData = this.dataPerWidget[widget][resultKeyName]?.data;
        if (isConcatResult && currentWidgetData) {
          if (widgetsConcatResultFunction[widget]) {
            newWidgetData = widgetsConcatResultFunction[widget].concatResultFunction({ currentWidgetData, newWidgetData });
          } else {
            newWidgetData = [...currentWidgetData, ...newWidgetData];
          }
        }
        const updatedDataPerWidget = { ...this.dataPerWidget[widget] };
        updatedDataPerWidget[resultKeyName] = {
          data: newWidgetData,
          errorCode: data.errorCode,
          requestId: responseRequestId,
          isLoaded: true,
        };

        config[widget].forEach((widgetConfig) => {
          delete widgetConfig.filters;
          delete widgetConfig.timeFrame;
          delete widgetConfig.attributionModel;
          delete widgetConfig.conversionIndicator;
        });

        configPerWidget[widget][resultKeyName] = config[widget];
        this.configPerWidget = { ...configPerWidget };

        Coralogix.sendTimingCustomMetric({
          metric: customMetricsNames.performancePerWidget,
          startTime: requestTime,
          attributes: {
            widget,
            status: 'finished',
          },
        });
        this.dataPerWidget[widget] = updatedDataPerWidget;
      }
    } catch (exception) {
      servicesStore.logger.error('failed to get widgets data from the server', {
        UID,
        region,
        resultKeyName,
        widgets,
        exception,
      });

      for (const widget of widgets) {
        Coralogix.sendTimingCustomMetric({
          metric: customMetricsNames.performancePerWidget,
          startTime: requestTime,
          attributes: {
            widget,
            status: 'failed',
          },
        });
      }
    }
  }

  disabledWidgetsByFlags(widgets) {
    const widgetsNames = [];
    for (const widget of widgets) {
      const widgetFlagName = getFlagByWidgetName({ widgetName: widget });
      if (widgetFlagName) {
        const flagValue = this.LDFlags[widgetFlagName];
        if ((typeof flagValue === 'boolean' && flagValue) || (typeof flagValue === 'string' && flagValue === 'show')) {
          widgetsNames.push(widget);
        }
      } else {
        widgetsNames.push(widget);
      }
    }
    return widgetsNames;
  }

  restIsLoadedWidgetIndication = (widgetToReset, resultKeyNameToReset) => {
    if (widgetToReset && resultKeyNameToReset) {
      const widgetData = this.dataPerWidget[widgetToReset][resultKeyNameToReset];
      if (widgetData.isLoaded) {
        this.dataPerWidget[widgetToReset][resultKeyNameToReset].isLoaded = false;
      }
    } else {
      for (const [widget, widgetData] of Object.entries(this.dataPerWidget)) {
        for (const resultKeyName of Object.keys(widgetData)) {
          if (widgetData[resultKeyName].isLoaded) {
            this.dataPerWidget[widget][resultKeyName].isLoaded = false;
          }
        }
      }
    }
  };
}

decorate(AnalysisStore, {
  dataPerWidget: observable,
  accountWithoutData: observable,
  configPerWidget: observable.ref,
  getWidgetData: action.bound,
  restIsLoadedWidgetIndication: action.bound,
  updateConfigPerWidget: action.bound,
});

const schema = {
  configPerWidget: {
    type: 'object',
  },
};

const store = persist(schema)(new AnalysisStore());

hydrate('analysisStore', store);

export default store;
