import React, { useState } from 'react';
import history from 'history';

import useStyles from 'hooks/useStyles';

import AskQuestionWithSuggestions from 'components/pages/questions/AskQuestionWithSuggestions';

import { tabsPaths } from 'enums';

import style from 'styles/questions/ai-answers.css';

const styles = style.locals || {};

export default function AIAnswersAskAnythingBox() {
  useStyles([style]);

  const [questionInputText, setQuestionInputText] = useState('');

  function onClickSuggestion() {
    history.push(tabsPaths.answers);
  }

  return (
    <div className={styles.aiAnswersWidget}>
      <div className={styles.aiAnswersWidgetTitle}>
        <b>Ask anything with InfiniGrow AI!</b>
        <p>Ask a question, follow up, and drill down to uncover insights in seconds.</p>
      </div>
      <AskQuestionWithSuggestions
        questionInputText={questionInputText}
        setQuestionInputText={setQuestionInputText}
        onClickSuggestion={() => onClickSuggestion()}
      />
    </div>
  );
}
