import { isNil } from 'lodash';

import userStore from 'stores/userStore';
import servicesStore from 'stores/servicesStore';

import { Events } from 'trackers/analytics/enums';
import { getNickname } from 'components/utils/channels';

export function getBreadcrumbs({
  currentBreadcrumbs = [],
  selectedStage,
  breakdownSegment,
  selectedOption,
  onClickBreadcrumb,
  shouldAddNewBreadcrumb = true,
}) {
  const breadcrumbs = [...currentBreadcrumbs];
  const isBreadcrumbsEmpty = breadcrumbs.length === 0;
  const isSelectionValid = selectedStage && breakdownSegment;

  if (!isSelectionValid) {
    return breadcrumbs;
  }

  if (isBreadcrumbsEmpty) {
    breadcrumbs.push({ label: userStore.getMetricNickname({ metric: selectedStage, isSingular: true }), value: selectedStage, disabled: true });
  }

  const shouldReplaceLastBreadcrumb = !selectedOption && !shouldAddNewBreadcrumb;
  const shouldAddSelectedOptionToCurrentBreadcrumb = selectedOption && currentBreadcrumbs.length > 1;
  const lastBreadcrumbIndex = currentBreadcrumbs.length - 1;

  if (shouldAddSelectedOptionToCurrentBreadcrumb) {
    breadcrumbs[lastBreadcrumbIndex] = {
      ...breadcrumbs[lastBreadcrumbIndex],
      selectedOption: getNickname(selectedOption),
      disabled: false,
      onClick: (currentStepIndex) => {
        onClickBreadcrumb({ currentStepIndex });

        servicesStore.eventTracker.track({
          eventName: Events.funnelDrilldown.rollupTable,
          properties: {
            breakdownsCount: breadcrumbs.length,
            selectedBreakdownIndex: currentStepIndex,
          },
        });
      },
    };
  }

  const hasClickedBreadcrumb = breadcrumbs.findIndex((breadcrumb) => breadcrumb.value === breakdownSegment.value) > -1;
  if (hasClickedBreadcrumb) {
    return breadcrumbs;
  }

  if (shouldReplaceLastBreadcrumb) {
    breadcrumbs.pop();
  }

  breadcrumbs.push({ ...breakdownSegment, disabled: true });

  return breadcrumbs;
}

export function getDrilldownMetricsData({ drilldownMetricsOptions, data, isLoaded }) {
  const drilldownMetricsDataObject = {};
  for (const drilldownMetric of drilldownMetricsOptions) {
    drilldownMetricsDataObject[drilldownMetric.value] = {
      [`${drilldownMetric.value}ConversionRate`]: isLoaded && !isNil(drilldownMetric.value) ? data[`${drilldownMetric.value}ConversionRate`] : null,
      [`${drilldownMetric.value}Velocity`]: isLoaded && !isNil(drilldownMetric.value) ? data[`${drilldownMetric.value}Velocity`] : null,
    };
  }

  return drilldownMetricsDataObject;
}
