exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".CurtainOverlay__slidingCurtain-2d6{position:fixed;top:60px;right:-100vw;height:calc(100vh - 60px);width:calc(100vw - 100px);background-color:var(--colorsBackgroundsBackgroundWhite);box-shadow:0 0 8px rgba(0,0,0,.1);z-index:999;transition:right .5s ease-in-out}.CurtainOverlay__open-VTq{right:0}.CurtainOverlay__curtainContent-1B6{height:100%;position:relative}.CurtainOverlay__closeButton-1Ae{position:absolute;top:20px;right:24px;width:24px;height:24px;background:url(/assets/close-icon-big.svg) 50% no-repeat;cursor:pointer}body:has(.CurtainOverlay__slidingCurtain-2d6.CurtainOverlay__open-VTq){overflow:hidden}", ""]);

// exports
exports.locals = {
	"slidingCurtain": "CurtainOverlay__slidingCurtain-2d6",
	"slidingCurtain": "CurtainOverlay__slidingCurtain-2d6",
	"open": "CurtainOverlay__open-VTq",
	"open": "CurtainOverlay__open-VTq",
	"curtainContent": "CurtainOverlay__curtainContent-1B6",
	"curtainContent": "CurtainOverlay__curtainContent-1B6",
	"closeButton": "CurtainOverlay__closeButton-1Ae",
	"closeButton": "CurtainOverlay__closeButton-1Ae"
};