export const historicalPerformanceMockData = {
  funnel1ToFunnel2Velocity: {
    value: 7,
    growth: -24,
  },
  funnel1ToFunnel3Velocity: {
    value: 21,
    growth: 13,
  },
  funnel2ToFunnel3Velocity: {
    value: 17,
    growth: 10,
  },
  funnel2ToFunnel4Velocity: {
    value: 74,
    growth: 39,
  },
  funnel3ToFunnel4Velocity: {
    value: 52,
    growth: null,
  },
  funnel1ToFunnel2ConversionRate: {
    value: 65.35,
    growth: -16.6,
  },
  funnel1ToFunnel3ConversionRate: {
    value: 20.27,
    growth: 18.22,
  },
  funnel1ToFunnel4ConversionRate: {
    value: 4.29,
    growth: -1.74,
  },
  funnel2ToFunnel3ConversionRate: {
    value: 31.01,
    growth: 28.5,
  },
  funnel2ToFunnel4ConversionRate: {
    value: 6.57,
    growth: -0.79,
  },
  funnel3ToFunnel4ConversionRate: {
    value: 21.19,
    growth: -272.64,
  },
  funnel1: {
    value: 13994,
    growth: 254.82,
  },
  costPerFunnel1: {
    value: 413,
    growth: -35.77,
  },
  funnel2: {
    value: 9145,
    growth: 182.95,
  },
  costPerFunnel2: {
    value: 632,
    growth: -19.49,
  },
  funnel3: {
    value: 2836,
    growth: 340.23,
  },
  costPerFunnel3: {
    value: 2037,
    growth: -93.5,
  },
  funnel4: {
    value: 601,
    growth: 152.52,
  },
  costPerFunnel4: {
    value: 9613,
    growth: -9.86,
  },
  stagesConfigs: {
    funnelStagesToTheirGroupByType: {
      funnel1: 'contacts',
      funnel2: 'contacts',
      funnel3: 'deals',
      funnel4: 'deals',
    },
    isSimpleConversionRate: [],
  },
  revenue: {
    value: 43193938,
    growth: 30752.81,
  },
  revenueROI: {
    value: 7.476718247661058,
    growth: 13454.01,
  },
  revenueAvgDealSize: {
    value: 5989.18,
    growth: 12117.83,
  },
  pipeline: {
    value: 134805287,
    growth: 5292.83,
  },
  pipelineROI: {
    value: 23.334319487009633,
    growth: 2269.13,
  },
  pipelineAvgDealSize: {
    value: 4747.13,
    growth: 108.44,
  },
};
