import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {
  camelCase, isEmpty, isNaN, sumBy,
} from 'lodash';

import userStore from 'stores/userStore';
import CellByColumn from 'components/widgets/segmentsDrilldown/CellByColumn';
import timeFrameModule from 'modules/timeframe';
import Dropdown from 'components/controls/Dropdown';
import InfoMarker from 'components/pages/InfoMarker';

import { getColumnOptionsByData, isShowCostMetric } from 'components/pages/analyze/AttribuitonTable/logic/AttributionSegmentsTableParseData';
import { getColumnMetricNickName } from 'components/pages/analyze/AttribuitonTable/logic/AttributionTable';
import {
  segmentsKeys, upliftLabel,
  nonCumulativeMetricTypes, costMetrics, mappingMetricsKeys, metricsTypes, columnsBlackList,
} from 'components/common/logic/enums';
import { formatIndicatorDisplay, getRevenueFunnel, getPipelineFunnel } from 'components/utils/indicators';
import {
  columnControllersLabels,
  columnControllersKeys,
  defaultSubColumnsOptionsByType,
  defaultSelectedSubColumnsByType,
} from 'components/widgets/segmentsDrilldown/enums';
import { formatBudget } from 'components/utils/budget';
import { getFiltersForViewJourneysRequest, getSegmentLabel } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { navigateBetweenAnalyzeTabs } from 'stores/analyze/logic/navigations';
import { attributionMetrics } from 'components/pages/analyze/AttribuitonTable/enums';
import { getCostPerMetricLabel } from 'components/utils/logic/utils';

export function getColumnTypeByMetric({ metric }) {
  return Object.keys(metricsTypes).find((key) => metric.toLowerCase().includes(key.toLowerCase()));
}

export function getSubColumnsOptionsByColumnKey({ columnKey, timeframe }) {
  const fullTimeFrame = timeFrameModule.getTimeframeParams({
    ...timeframe,
    fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth,
    shouldUseEndOfPeriod: true,
  });
  const isPastFrame = fullTimeFrame.endDate <= new Date();

  const columnType = getColumnTypeByMetric({ metric: columnKey });
  let subColumnsOptions = defaultSubColumnsOptionsByType[columnType];

  if (subColumnsOptions) {
    if (isPastFrame) {
      subColumnsOptions = subColumnsOptions.filter((option) => option !== columnControllersKeys.pacing);
    }
    return subColumnsOptions.map((option) => ({ value: option, label: columnControllersLabels[option].label }));
  }

  return [];
}

export function getDefaultSelectedSubColumns({ columns, metrics }) {
  const selectedColumnController = {};

  for (const columnKey of columns) {
    const columnType = getColumnTypeByMetric({ metric: columnKey });
    const selectedSubColumns = defaultSelectedSubColumnsByType[columnType];
    if (selectedSubColumns && metrics.includes(columnKey)) {
      selectedColumnController[columnKey] = selectedSubColumns;
    }
  }

  return selectedColumnController;
}

export function getTotalsByColumn({
  tableData, totals = {}, columnKey, shouldCalculateTotals, stage,
}) {
  if (columnKey.includes('touched')) {
    return '-';
  }

  let totalByColumn = totals[columnKey];
  const columnType = getColumnTypeByMetric({ metric: columnKey });
  const avoidCalculate = nonCumulativeMetricTypes.includes(columnType);

  if ((shouldCalculateTotals && !avoidCalculate) || !totalByColumn) {
    totalByColumn = sumBy(tableData, (item) => item[columnKey]);
  }

  let totalValue = totalByColumn;
  if (!totalValue) {
    totalValue = 0;
  }

  if (columnKey === metricsTypes.efficiency) {
    return getCostPerMetricLabel({ value: totalValue, stage });
  }

  const isCostMetric = costMetrics.includes(columnKey);
  if (isCostMetric) {
    return formatBudget(totalValue);
  }

  return formatIndicatorDisplay(columnKey, totalValue, true, true, true, 1);
}

export function getColumnsOptionsByStage({ stage, breakdownSegment, isAttribution }) {
  const groupedUserMetrics = userStore.getUserDynamicMetricsPerFunnel(stage);
  const metricsByStage = groupedUserMetrics[stage] || [];
  const metricsColumns = metricsByStage.filter((metric) => !columnsBlackList.includes(metric));

  const columnsOfSelectedMetric = [{
    value: 'touched',
    label: userStore.getMetricNickname({ metric: camelCase(`touched ${stage}`) }),
  }];

  if (isAttribution) {
    columnsOfSelectedMetric.push({
      value: 'attributed',
      label: userStore.getMetricNickname({ metric: camelCase(`attributed ${stage}`) }),
    });
  }

  const showCostMetric = isShowCostMetric({ firstSegmentValue: breakdownSegment });
  if (showCostMetric) {
    columnsOfSelectedMetric.push({
      label: 'Cost',
      value: 'cost',
    });
  }

  for (const column of metricsColumns) {
    const columnLabel = userStore.getMetricNickname({ metric: column });
    if (column.includes('costPer')) {
      if (showCostMetric) {
        columnsOfSelectedMetric.push({
          value: metricsTypes.efficiency,
          label: columnLabel,
        });
      }
      continue;
    }

    if (!isAttribution && attributionMetrics.some((attributedMetric) => column.includes(attributedMetric))) {
      continue;
    }

    columnsOfSelectedMetric.push({
      value: mappingMetricsKeys[column] || column,
      label: columnLabel,
    });
  }

  if (stage === getPipelineFunnel()) {
    columnsOfSelectedMetric.push({
      value: 'touchedPipeline',
      label: 'Touched Pipeline',
    });
  }

  if (stage === getRevenueFunnel()) {
    columnsOfSelectedMetric.push({
      value: 'touchedRevenue',
      label: 'Touched Revenue',
    });
  }

  return columnsOfSelectedMetric;
}

export function getColumnPriorityBySMetric({ metric }) {
  if (metric === metricsTypes.cost) {
    return 0;
  }

  if (metric === metricsTypes.attributed || metric === metricsTypes.touched) {
    return 1;
  }

  if (metric === metricsTypes.efficiency) {
    return 6;
  }

  const lowerMetric = metric.toLowerCase();
  const funnelsInMetric = lowerMetric.match(/funnel(\d+)/g);

  if (funnelsInMetric) {
    const funnelNumbers = funnelsInMetric.map((match) => parseInt(match.replace('funnel', ''), 10));
    const maxFunnelNumber = Math.max(...funnelNumbers);

    if (!isNaN(maxFunnelNumber)) {
      const priorityByFunnelNumber = maxFunnelNumber * 0.1;
      const basePriority = 1.01;
      return priorityByFunnelNumber + basePriority;
    }
  }

  return 5;
}

export function getTableColumnByKey({
  columnKey,
  upliftMinMaxByMetric,
  selectedSubColumns,
  selectedStage,
  timeframe,
  breakdownSegment,
  sortByColumn,
  setSortByColumn,
  filtersByColumn,
  onUpdateFiltersByColumn,
  onChangeSubColumnsSelection,
  tableData,
  totals,
  isLoaded,
  isLoadedUplift,
  isCompareToPrevious,
  subColumnClassName,
}) {
  let columnTitle = getColumnMetricNickName({ metric: columnKey });

  if (columnKey === 'attributed' || columnKey === 'touched') {
    columnTitle = `${columnTitle} ${userStore.getMetricNickname({ metric: selectedStage })}`;
  }

  const subColumnsByColumnKey = selectedSubColumns[columnKey];
  const shouldCalculateTotals = !isEmpty(filtersByColumn);

  const totalByColumn = getTotalsByColumn({
    tableData, totals, columnKey, shouldCalculateTotals, stage: selectedStage,
  });

  const inlineSubColumnsOptions = getSubColumnsOptionsByColumnKey({ columnKey, timeframe });

  const parentColumn = {
    id: columnKey,
    accessor: columnKey,
    header: columnTitle,
    priority: getColumnPriorityBySMetric({ metric: columnKey }),
    inlineFiltersParams: {
      columnKey,
      selectedSegments: {
        firstSegment: breakdownSegment,
      },
      sortByColumn,
      filtersByColumn,
      setSortByColumn: ({ id, desc }) => setSortByColumn({ id, desc }),
      updateFiltersByColumn: ({ filters }) => onUpdateFiltersByColumn({ filters }),
      isNumeric: true,
    },
    inlineColumnControllerParams: inlineSubColumnsOptions.length > 0 ? {
      options: inlineSubColumnsOptions,
      selectedOptions: subColumnsByColumnKey || [],
      onChange: (subColumn) => onChangeSubColumnsSelection({ parentColumn: columnKey, subColumn }),
    } : undefined,
    columns: [{
      sortable: false,
      accessor: columnKey,
      Header: '',
      Cell: (row) => (
        <CellByColumn
          value={row.value}
          isCompareToPrevious={isCompareToPrevious}
          growthPerMetric={isCompareToPrevious ? row.original.growthPerMetric : null}
          isLoaded={isLoaded}
          columnKey={columnKey}
          breakdownSegment={breakdownSegment}
          stage={selectedStage}
        />
      ),
      Footer: totalByColumn,
      minWidth: 160,
    }],
  };

  if (subColumnsByColumnKey) {
    const subColumns = subColumnsByColumnKey.map((subColumnKey) => {
      const headerLabel = columnControllersLabels[subColumnKey]?.shortLabel || subColumnKey;
      const isUpliftColumn = subColumnKey === columnControllersKeys.uplift;
      const tooltip = getSubHeaderTooltip({
        columnKeyLabel: columnTitle, subHeaderLabel: headerLabel, subColumnKey, segmentName: breakdownSegment,
      });
      return ({
        sortable: false,
        accessor: isUpliftColumn ? `${columnKey}${upliftLabel}` : columnKey,
        Header: (
          <div className={subColumnClassName.header}>
            {headerLabel}
            {tooltip ? (
              <InfoMarker
                tooltipText={tooltip}
                iconClassName={subColumnClassName.icon}
                TooltipProps={{ className: subColumnClassName.tooltip }}
              />
            ) : null}
          </div>
        ),
        Cell: (row) => (
          <CellByColumn
            value={row.value}
            isLoaded={isLoaded}
            isLoadedUplift={isLoadedUplift}
            columnKey={subColumnKey}
            timeframe={timeframe}
            tableData={tableData}
            metric={columnKey}
            upliftMinMaxByMetric={upliftMinMaxByMetric}
          />
        ),
        Footer: '',
        getProps: () => ({
          className: subColumnClassName.cell,
        }),
        minWidth: isUpliftColumn ? 200 : 124,
      });
    });

    parentColumn.columns = [
      {
        sortable: false,
        accessor: columnKey,
        Header: (<div className={subColumnClassName.header}>Volume</div>),
        Cell: (row) => (
          <CellByColumn
            value={row.value}
            isLoaded={isLoaded}
            columnKey={columnKey}
            isCompareToPrevious={isCompareToPrevious}
            growthPerMetric={isCompareToPrevious ? row.original.growthPerMetric : null}
            breakdownSegment={breakdownSegment}
            stage={selectedStage}
          />
        ),
        Footer: totalByColumn,
        minWidth: isCompareToPrevious ? 140 : 100,
      },
      ...subColumns,
    ];
  }

  if (columnKey === segmentsKeys.firstSegment) {
    parentColumn.inlineFiltersParams.columnFiltersOptions = getColumnOptionsByData({ selectedSegments: { firstSegment: breakdownSegment }, tableData, columnKey });
    parentColumn.inlineFiltersParams.isNumeric = false;
    parentColumn.fixed = 'left';
    parentColumn.columns[0].Footer = 'Total';
    parentColumn.columns[0].minWidth = 200;
    parentColumn.header = getSegmentLabel({ segment: breakdownSegment, customFieldsIdToLabelMap: userStore.userAccount?.customFieldsIdToLabelMap, customUtmsWhitelist: userStore.userAccount?.customUtmsWhitelist });
  }

  if (columnKey === metricsTypes.efficiency) {
    parentColumn.header = `Cost per ${userStore.getMetricNickname({ metric: selectedStage })}`;
  }

  return parentColumn;
}

export function getDrilldownMenuParams({
  onClickOption,
  selectedStage,
  breakdownSegment,
  classNameMenu,
  timeframe,
  valueToNavigate,
  breakdownSegmentsOptions,
}) {
  return (
    [
      {
        label: 'Breakdown by',
        component: (
          <Dropdown
            options={breakdownSegmentsOptions}
            selectedKey=""
            onChange={(option) => onClickOption(option)}
            menuIsOpen
            isSearchable
            classNameMenu={classNameMenu}
            isShowDropdownIndicator={false}
            placeholder="Search..."
            autoFocus
            menuListMaxHeight="184px"
            menuShouldScrollIntoView={false}
            menuListWidth="240px"
          />),
        leftIcon: 'breakdown:arrow',
      },
      {
        label: 'View Journeys',
        leftIcon: 'breakdown:queryStats',
        action: () => navigateBetweenAnalyzeTabs({
          filters: getFiltersForViewJourneysRequest({
            firstSegment: breakdownSegment,
            value: valueToNavigate,
            kpiFocus: selectedStage,
            funnels: userStore.funnels,
          }),
          tabName: 'journeys',
          timeFrame: timeframe,
        }),
      }]
  );
}

export function getSubHeaderTooltip({
  columnKeyLabel, subHeaderLabel, subColumnKey, segmentName,
}) {
  let body;
  switch (subColumnKey) {
    case columnControllersKeys.uplift:
      body = 'Measures the impact of the segment by comparing conversion rates of journeys that include it vs. those that don’t.';
      break;
    case columnControllersKeys.pacing:
      body = `Projected ${columnKeyLabel} by the period’s end.`;
      break;
    case columnControllersKeys.compare:
      body = `Difference in ${columnKeyLabel} compared to the average.`;
      break;
    case columnControllersKeys.shareOfTotal: {
      const isAttributed = columnKeyLabel.includes('Attributed');
      if (isAttributed) {
        body = `The share of ${columnKeyLabel} out of the total number of ${columnKeyLabel}.`;
        break;
      }
      body = `How common is a ${segmentName}, in terms of influence on total ${columnKeyLabel}`;
      break;
    }
    default:
      return null;
  }

  return ReactDOMServer.renderToStaticMarkup(
    <>
      <div style={{ fontSize: '14px', fontWeight: 600, paddingBottom: '4px' }}>
        {subHeaderLabel}
      </div>
      <div className="body">
        {body}
      </div>
    </>
  );
}
