import React from 'react';
import moment from 'moment-timezone';
import classnames from 'classnames';
import { isNil } from 'lodash';

import useStyles from 'hooks/useStyles';

import userStore from 'stores/userStore';

import IndicationTag from 'components/common/IndicationTag';
import FooterItem from 'components/pages/insights/overviewTab/goals/FooterItem';
import Skeleton from 'components/common/Skeleton';
import NavigationMenuPopup from 'components/pages/analyze/NavigationMenuPopup';

import { isTrendPositive } from 'components/utils/logic/trend';
import { isGoalOnTrack, getTimeData, getGoalProgress } from 'components/utils/goals';
import { formatIndicatorDisplay } from 'components/utils/indicators';
import { frequencyTypesToLabel, goalSkeletonId } from 'components/pages/settings/goals/logic/enums';
import { DEFAULT_TIME_ZONE } from 'components/utils/timeframe';
import { getPacingCalculate } from 'components/utils/logic/utils';

import style from 'styles/insightsPage/goals/goalStats.css';

const styles = style.locals;

export default function StatsMetricWithGoal({
  data,
  isSelected,
  setSelectedGoal,
  showProgressBar = true,
  showGoalTag = true,
  showTrendTag = true,
  isLoaded = true,
  isLoadedTrend = true,
  showNoTrendBottomTag,
  hasTimeframe,
  navigationMenuPopupParams,
}) {
  useStyles([style]);

  const timeframeLabelFormat = 'MMM, D YYYY';
  const isRealGoal = !isNil(data.targetValue);
  const isHavingTrendData = !isNil(data.trendValue);
  const trendNumber = Number(data.trendValue).toFixed(2);
  let isGoalTrendPositive = false;
  if (isHavingTrendData) {
    isGoalTrendPositive = isTrendPositive({ metric: data.metricType, trendValue: data.trendValue });
  }
  const usePacingForGoal = data.cumulativeMetric && isRealGoal;

  let pacing = 0;
  if (usePacingForGoal) {
    pacing = getPacingCalculate({ timeframe: data.timeframe, actual: data.actualValue });
  }
  const valueForOnTrackCalculation = usePacingForGoal ? pacing : data.targetValue;
  const isOnTrack = isGoalOnTrack({ metricType: data.metricType, project: valueForOnTrackCalculation, target: data.targetValue });
  const pacingForPercent = Math.round((valueForOnTrackCalculation / data.targetValue) * 100);

  const timeframe = data.timeframe || {};
  const timezone = userStore.userMonthPlan.CRMConfig.timezone;
  const goalStartDate = moment.parseZone(timeframe.startDate || DEFAULT_TIME_ZONE).tz(timezone);
  const goalEndDate = moment.parseZone(timeframe.endDate || DEFAULT_TIME_ZONE).tz(timezone);
  const { leftToFinish, isCompleted } = getTimeData(goalStartDate, goalEndDate);

  const goalProgress = getGoalProgress({ metricType: data.metricType, planned: data.targetValue, actual: data.actualValue });
  const percentOfProgress = Math.floor(goalProgress * 100);

  const showVsTarget = isRealGoal && !usePacingForGoal;
  const goalOnTrack = usePacingForGoal ? isOnTrack : percentOfProgress >= 100;

  const timeframeLabels = {};
  if (hasTimeframe) {
    timeframeLabels.title = 'Time left';
    timeframeLabels.body = leftToFinish;
  } else {
    timeframeLabels.title = 'Timeframe';
    timeframeLabels.body = `${frequencyTypesToLabel[data.frequencyType]} (${leftToFinish} left)`;
    if (isCompleted) {
      timeframeLabels.body = `${goalStartDate.format(timeframeLabelFormat)} - ${goalEndDate.format(timeframeLabelFormat)}`;
    }
  }

  const isEnabledClick = data.actualValue >= 0 && data.metricName && setSelectedGoal;

  const [showNavigationMenuPopup, setShowNavigationMenuPopup] = React.useState(false);

  if (data.id === goalSkeletonId && isLoaded) {
    return null;
  }

  return (
    <div
      className={classnames(styles.wrapper, isSelected ? styles.wrapperClicked : null, (isEnabledClick || navigationMenuPopupParams) ? null : styles.withoutHover)}
      onClick={isEnabledClick ? setSelectedGoal : navigationMenuPopupParams ? () => setShowNavigationMenuPopup((prev) => !prev) : null}
      id={data.tagId}
    >

      {showNavigationMenuPopup ? (
        <div className={styles.navigationMenu}>
          <NavigationMenuPopup
            navigationOptions={navigationMenuPopupParams(data.id)}
            onClosePopup={(showNavigationPopup) => setShowNavigationMenuPopup(showNavigationPopup)}
          />
        </div>
      ) : null}

      <div className={styles.header}>
        {isLoaded
          ? (
            <>
              {showTrendTag ? (isHavingTrendData ? (
                <IndicationTag
                  icon={isGoalTrendPositive ? 'indicationTag:trendUp' : 'indicationTag:trendDown'}
                  text={isGoalTrendPositive ? 'Trending' : 'Downtrending'}
                  colorType={isGoalTrendPositive ? 'green' : 'red'}
                />
              ) : (
                <IndicationTag
                  text="No trend data"
                  tooltip="This metric’s trendline could not be calculated. Trends require 3 months of non-zero values."
                />
              )) : null}

              {showGoalTag ? (
                <>
                  {usePacingForGoal && (
                  <IndicationTag
                    icon={isOnTrack ? 'indicationTag:performanceUp' : 'indicationTag:performanceDown'}
                    text={isOnTrack ? 'On track' : 'Off track'}
                    colorType={isOnTrack ? 'green' : 'red'}
                  />
                  )}
                  {!isRealGoal && (
                  <IndicationTag text="No goal" />
                  )}
                </>
              ) : null}
            </>
          ) : (<Skeleton height={24} width={60} />)}
      </div>

      <div className={styles.body}>
        {!(data.title || data.metricName) ? (
          <Skeleton height={22} width={120} isLightTheme />
        ) : data.title || userStore.getMetricNickname({ metric: data.metricName })}

        <div className={styles.row}>
          {!isLoaded ? (
            <Skeleton height={40} width={80} />
          ) : (
            <>
              <span className={styles.metricNumber}>
                {data.metricName ? formatIndicatorDisplay(data.metricName, data.actualValue, true, false) || 0 : 0}
              </span>
              {isRealGoal && (
              <span className={styles.lightTextColor}>
                /
                {data.metricName ? formatIndicatorDisplay(data.metricName, data.targetValue, true, false) || 0 : 0}
              </span>
              )}
            </>
          )}
        </div>
      </div>
      {showProgressBar ? (isLoaded ? (
        <div className={styles.progressBarWrapper}>
          <div
            className={classnames(styles.progressBar, styles.progressBarBackground)}
          />
          <div
            className={classnames(styles.progressBar, goalOnTrack ? styles.greenProgressBar : styles.redProgressBar)}
            style={{ width: percentOfProgress < 100 ? `${percentOfProgress}%` : '100%' }}
          />
          <div
            className={classnames(styles.progressBar, goalOnTrack ? styles.pacingGreenProgressBar : styles.pacingRedProgressBar)}
            style={{ width: pacingForPercent < 100 ? `${pacingForPercent}%` : '100%' }}
          />
        </div>
      ) : (<Skeleton height={6} />)) : null}

      <div className={styles.footer}>
        {isLoaded ? (
          <>
            {isHavingTrendData || !isLoadedTrend ? (
              <FooterItem
                title="Trending"
                bodyColorClass={isGoalTrendPositive ? styles.greenText : styles.redText}
                bodyText={`${trendNumber > 0 ? '+' : ''}${trendNumber}%`}
                bodyTextDetails="MoM"
                tooltipText="The monthly trend metric calculates a trendline for the last three months of your selected time frame."
                isLoaded={isLoadedTrend}
              />
            ) : (showNoTrendBottomTag ? (
              <IndicationTag
                text="No trend data"
                tooltip="This metric’s trendline could not be calculated. Trends require 3 months of non-zero values."
              />
            ) : null)}

            {!isCompleted && usePacingForGoal && (
            <FooterItem
              title="Pacing for"
              bodyColorClass={isOnTrack ? styles.greenText : styles.redText}
              bodyText={`${pacingForPercent}%`}
              tooltipText={`Pacing for ${valueForOnTrackCalculation} ${data.title}`}
            />
            )}
            {showVsTarget && (
            <FooterItem
              title="Vs. Target"
              bodyColorClass={percentOfProgress >= 100 ? styles.greenText : styles.redText}
              bodyText={`${percentOfProgress}%`}
            />
            )}
            {isRealGoal && (
            <FooterItem
              title={timeframeLabels.title}
              bodyText={timeframeLabels.body}
              tooltipText={isCompleted ? null : `${goalStartDate.format(timeframeLabelFormat)} - ${goalEndDate.format(timeframeLabelFormat)}`}
            />
            )}

          </>
        ) : <Skeleton height={35} width={70} isLightTheme />}
      </div>
    </div>
  );
}
