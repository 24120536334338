import React, { useEffect, useMemo } from 'react';
import { Button } from '@infinigrow/libs';

import useWindowDimensions from 'components/utils/getWindowDimensions';
import { defaultWidget, blurModeWidgets } from 'components/pages/analyze/enums';

import blurModeStyle from 'styles/analyze/feature-flags-with-blur.css';

const styles = blurModeStyle.locals;

export default function BluredWidget({ flagName }) {
  useEffect(() => {
    blurModeStyle.use();
    return () => {
      blurModeStyle.unuse();
    };
  }, []);

  const currentWidgetByFlag = blurModeWidgets[flagName];
  const widgetBlurModeData = { ...defaultWidget, ...currentWidgetByFlag };
  const { width: screenWidth } = useWindowDimensions();

  const imageSize = useMemo(() => {
    if (screenWidth > 1600) {
      return '-x3';
    }
    if (screenWidth > 1400) {
      return '-x2';
    }
    return '';
  }, [screenWidth]);

  function getButtonActionEmail({ widgetName }) {
    const emailSubject = `Regarding the ${widgetName} widget`;
    const emailBody = `Hey, I would like to receive more information about the following widget: ${widgetName}`;

    return (
      () => window.open(`mailto:info@infinigrow.com?subject=${emailSubject}&body=${emailBody}`)
    );
  }

  return (
    <div data-testid="blured-widget">
      <div className={styles.header}>
        <h2>{widgetBlurModeData.title}</h2>
      </div>
      <div
        className={styles.wrapper}
        style={{
          backgroundImage: `url(/assets/blured-widgets/${widgetBlurModeData.image}${imageSize}.svg)`,
          height: widgetBlurModeData.height,
        }}
      >
        <div className={styles.text}>
          {widgetBlurModeData.content}
        </div>
        <Button
          type="primaryBlue"
          onClick={widgetBlurModeData.buttonAction || getButtonActionEmail({ widgetName: widgetBlurModeData.title })}
        >
          {widgetBlurModeData.buttonText}
        </Button>
      </div>
    </div>

  );
}
