import React, { useEffect, useMemo, useState } from 'react';
import history from 'history';
import { inject, observer } from 'mobx-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { cloneDeep, isEmpty } from 'lodash';

import WhatIfScenarios from 'components/widgets/whatIf/WhatIfScenarios';
import BreadcrumbsTitle from 'components/common/BreadcrumbsTitle';

import { defaultScenario, defaultSettings } from 'components/widgets/whatIf/enums';
import { TIMEFRAME_VALUES } from 'components/utils/timeframe';
import { FREQUENCY_VALUES } from 'components/utils/frequency';
import { getWidgetsDataV2, getWidgetFullConfig } from 'components/pages/analyze/widgetsRequest';
import { widgetTypes } from 'components/pages/analyze/enums';
import {
  getBaseConfigWithSampleTimeframeMonths,
  getParsedAccountBaseData,
  getParsedChannelBaseData,
  getParsedScenarioChartData,
  getParsedMockBaseData,
  getSelectedMetricsForRequest,
  getParsedAvgMonthlySpendData,
  getDefaultKpiFocus,
} from 'components/widgets/whatIf/logic/whatIfScenarios';
import { getWidgetHashKey } from 'stores/logic/widgetsAnalysisStore';
import { pageLabels } from 'enums';

function WhatIfTab({
  segmentsAnalysis,
  historicalPerformance,
  getWidgetRequestId,
  whatIf,
  flags,
  requestAvgMonthlySpendData,
  avgMonthlySpendData,
  loadedAvgMonthlySpendByMonth,
}) {
  if (!flags.whatIfAnalyzeTab) {
    history.push('/analyze/overview');
    return null;
  }

  const [timeframe, setTimeframe] = useState({ value: TIMEFRAME_VALUES.QUARTER });
  const [kpiFocus, setKpiFocus] = useState(getDefaultKpiFocus());
  const [scenarios, setScenarios] = useState(cloneDeep(defaultScenario));
  const [sampleTimeframeMonths, setSampleTimeframeMonths] = useState(defaultSettings.sampleTimeframeMonths);
  const [isLoaded, setIsLoaded] = useState({
    historicalPerformanceData: true, segmentAnalysisData: true, whatIfData: true,
  });
  const [isFailedToLoad, setIsFailedToLoad] = useState(false);

  function requestWhatIfData({
    newScenarios = scenarios,
    newKpiFocus = kpiFocus,
    newTimeframe = timeframe,
    newSampleTimeframeMonths = sampleTimeframeMonths,
  }) {
    setIsLoaded((prev) => ({ ...prev, whatIfData: false }));

    getWidgetsDataV2({
      widget: widgetTypes.whatIf,
      widgetConfig: {
        whatIfParams: {
          timeframe: { value: newTimeframe.value },
          sampleTimeframeMonths: newSampleTimeframeMonths,
          kpiFocus: newKpiFocus,
          scenarios: newScenarios,
        },
      },
    });
  }

  function requestScenariosBaseData({ newSampleTimeframeMonths = sampleTimeframeMonths }) {
    if (flags.whatIfDemoOnly) {
      return;
    }
    setIsLoaded((prev) => ({ ...prev, historicalPerformanceData: false, segmentAnalysisData: false }));

    getWidgetsDataV2({
      widget: widgetTypes.segmentsAnalysis,
      addBaseAnalyzeConfig: false,
      widgetConfig: {
        ...getBaseConfigWithSampleTimeframeMonths({ amountOfSampleTimeframeMonths: newSampleTimeframeMonths }),
        segmentsAnalysisParams: {
          firstSegment: 'channel',
          secondSegment: null,
        },
        conversionIndicator: kpiFocus,
      },
    });

    getWidgetsDataV2({
      widget: widgetTypes.historicalPerformance,
      addBaseAnalyzeConfig: false,
      widgetConfig: {
        ...getBaseConfigWithSampleTimeframeMonths({ amountOfSampleTimeframeMonths: newSampleTimeframeMonths }),
        historicalPerformanceFrequency: FREQUENCY_VALUES.MONTH,
        selectedMetrics: getSelectedMetricsForRequest(),
      },
    });

    requestAvgMonthlySpendData({ sampleTimeframeMonths: newSampleTimeframeMonths, timeframe });
  }

  useEffect(() => {
    requestWhatIfData({});
    requestScenariosBaseData({});
  }, []);

  function getWhatIfResponseData() {
    const whatIfFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        whatIfParams: {
          timeframe: { value: timeframe.value },
          sampleTimeframeMonths,
          kpiFocus,
          scenarios,
        },
      },
    });
    const whatIfRequestId = getWidgetRequestId({ widget: widgetTypes.whatIf, widgetConfig: whatIfFullWidgetConfig });
    if (whatIf?.[whatIfRequestId]?.status === 'finished') {
      setIsLoaded((prev) => ({ ...prev, whatIfData: true }));
    }
    if (whatIf?.[whatIfRequestId]?.status === 'failed') {
      setIsFailedToLoad(true);
    }
    return whatIf?.[whatIfRequestId]?.result?.data ?? {};
  }

  function getScenariosBaseResponseData() {
    const segmentsAnalysisFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        ...getBaseConfigWithSampleTimeframeMonths({ amountOfSampleTimeframeMonths: sampleTimeframeMonths }),
        segmentsAnalysisParams: { firstSegment: 'channel', secondSegment: null },
        conversionIndicator: kpiFocus,
      },
      addBaseAnalyzeConfig: false,
    });
    const segmentsAnalysisRequestId = getWidgetRequestId({ widget: widgetTypes.segmentsAnalysis, widgetConfig: segmentsAnalysisFullWidgetConfig });
    const segmentAnalysisData = segmentsAnalysis?.[segmentsAnalysisRequestId]?.result?.dataByFunnel ?? {};
    const isSegmentAnalysisLoaded = segmentsAnalysis?.[segmentsAnalysisRequestId]?.status === 'finished';
    const historicalPerformanceFullWidgetConfig = getWidgetFullConfig({
      widgetConfig: {
        ...getBaseConfigWithSampleTimeframeMonths({ amountOfSampleTimeframeMonths: sampleTimeframeMonths }),
        historicalPerformanceFrequency: FREQUENCY_VALUES.MONTH,
        selectedMetrics: getSelectedMetricsForRequest(),
      },
      addBaseAnalyzeConfig: false,
    });
    const historicalPerformanceRequestId = getWidgetRequestId({ widget: widgetTypes.historicalPerformance, widgetConfig: historicalPerformanceFullWidgetConfig });
    const historicalPerformanceData = historicalPerformance?.[historicalPerformanceRequestId]?.result;
    const isHistoricalPerformanceLoaded = historicalPerformance?.[historicalPerformanceRequestId]?.status === 'finished';
    setIsLoaded((prev) => ({ ...prev, historicalPerformanceData: isHistoricalPerformanceLoaded, segmentAnalysisData: isSegmentAnalysisLoaded }));
    return { segmentAnalysisData, historicalPerformanceData };
  }

  const whatIfScenariosData = useMemo(() => {
    const whatIfData = getWhatIfResponseData();
    let whatIfDataScenarios = whatIfData.scenarios || [];
    if (whatIfDataScenarios.length > 0) {
      whatIfDataScenarios = getParsedScenarioChartData({ data: whatIfDataScenarios, timeFrameLabel: timeframe.value });
    }
    return whatIfDataScenarios;
  }, [whatIf, isLoaded.whatIfData]);

  const avgMonthlySpendKey = getWidgetHashKey({ widgetConfig: { sampleTimeframeMonths, timeframe } });

  const scenariosBaseData = useMemo(() => {
    if (flags.whatIfDemoOnly) {
      setIsLoaded((prev) => ({ ...prev, historicalPerformanceData: false, segmentAnalysisData: false }));
      const whatIfData = getWhatIfResponseData();
      if (!isEmpty(whatIfData)) {
        const baselineConfig = whatIfData.baselineConfig;
        const channelConfig = whatIfData.channelConfig;
        setIsLoaded({ whatIfData: true, historicalPerformanceData: true, segmentAnalysisData: true });
        return getParsedMockBaseData({ baselineConfig, channelConfig });
      }
      return {};
    }
    const { segmentAnalysisData, historicalPerformanceData } = getScenariosBaseResponseData();

    const globalAvgMonthlySpend = avgMonthlySpendData[avgMonthlySpendKey]?.all;
    const accountBaseData = getParsedAccountBaseData({ data: historicalPerformanceData, avgMonthlySpend: globalAvgMonthlySpend });
    const channelsBaseData = getParsedChannelBaseData({ data: segmentAnalysisData, kpiFocus });
    const channelsBaseDataWithAvgMonthlySpend = getParsedAvgMonthlySpendData({ data: avgMonthlySpendData[avgMonthlySpendKey], channelsBaseData });
    return { ...accountBaseData, ...channelsBaseDataWithAvgMonthlySpend };
  }, [
    whatIf,
    segmentsAnalysis,
    historicalPerformance,
    avgMonthlySpendData[avgMonthlySpendKey],
    isLoaded.whatIfData,
    isLoaded.segmentAnalysisData,
    isLoaded.historicalPerformanceData,
    loadedAvgMonthlySpendByMonth[avgMonthlySpendKey],
  ]);

  function onSaveScenarioSettings({ newScenarios, newKpiFocus }) {
    setScenarios(newScenarios);
    const requestSettings = { newScenarios };
    if (newKpiFocus) {
      setKpiFocus(newKpiFocus);
      requestSettings.newKpiFocus = newKpiFocus;
    }
    requestWhatIfData({ ...requestSettings });
  }

  function onUpdateKpiFocus({ newKpiFocus }) {
    setKpiFocus(newKpiFocus);
    requestWhatIfData({ newKpiFocus });
  }

  function onUpdateTimeframe({ newTimeframe }) {
    setTimeframe(newTimeframe);
    requestAvgMonthlySpendData({ sampleTimeframeMonths, timeframe: newTimeframe });
    requestWhatIfData({ newTimeframe });
  }

  function onSaveSampleTimeframeMonths({ newSampleTimeframeMonths }) {
    setSampleTimeframeMonths(newSampleTimeframeMonths);
    requestScenariosBaseData({ newSampleTimeframeMonths });
    setScenarios(defaultScenario);
    requestWhatIfData({ newScenarios: defaultScenario, newSampleTimeframeMonths });
  }

  return (
    <div>
      <BreadcrumbsTitle breadcrumbs={[pageLabels.analyze, pageLabels.whatIf]} />

      <WhatIfScenarios
        isLoaded={isLoaded.whatIfData}
        isLoadedBaseData={isLoaded.segmentAnalysisData && isLoaded.historicalPerformanceData}
        isFailedToLoad={isFailedToLoad}
        scenariosData={whatIfScenariosData}
        kpiFocus={kpiFocus}
        updateKpiFocus={(newKpiFocus) => onUpdateKpiFocus({ newKpiFocus })}
        scenarios={scenarios}
        timeframe={timeframe}
        updateTimeframe={({ newTimeframe }) => onUpdateTimeframe({ newTimeframe })}
        sampleTimeframeMonths={sampleTimeframeMonths}
        onSaveSampleTimeframeMonths={({ newSampleTimeframeMonths }) => onSaveSampleTimeframeMonths({ newSampleTimeframeMonths })}
        onSaveScenarioSettings={({ newScenarios, newKpiFocus }) => onSaveScenarioSettings({ newScenarios, newKpiFocus })}
        scenariosBaseData={scenariosBaseData}
      />
    </div>
  );
}

export default withLDConsumer()(inject(
  ({
    widgetsAnalysisStore: {
      getWidgetRequestId,
      dataPerWidget: {
        [widgetTypes.whatIf]: whatIf,
        [widgetTypes.segmentsAnalysis]: segmentsAnalysis,
        [widgetTypes.historicalPerformance]: historicalPerformance,
      },
    },
    forecastStore: {
      requestAvgMonthlySpendData,
      avgMonthlySpendData,
      loadedAvgMonthlySpendByMonth,
    },
  }) => ({
    whatIf,
    segmentsAnalysis,
    historicalPerformance,
    getWidgetRequestId,
    requestAvgMonthlySpendData,
    avgMonthlySpendData,
    loadedAvgMonthlySpendByMonth,
  }),
  observer
)(WhatIfTab));
