import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { frequencyTypes } from './enums';

require('moment-timezone');

const numberOfQuartersInYear = 4;
const numberOfMonthsInQuarter = 3;
const numberOfMonthsInYear = 12;

function equalValues({ amount, array }) {
  const len = array.length;
  const fraction = Math.floor(amount / len);
  const remainder = amount % len;
  return array.map((_, index) => (index === 0 ? fraction + remainder : fraction));
}

export function getMilestones({
  timeframe,
  timezone,
  goalFrequencyType,
  milestonesFrequencyType,
  amount,
}) {
  let numberOfMilestones = 1;
  if (goalFrequencyType === frequencyTypes.YEAR) {
    numberOfMilestones = milestonesFrequencyType === frequencyTypes.QUARTER ? numberOfQuartersInYear : numberOfMonthsInYear;
  } else if (goalFrequencyType === frequencyTypes.QUARTER) {
    numberOfMilestones = numberOfMonthsInQuarter;
  }

  const monthsDiff = milestonesFrequencyType === frequencyTypes.QUARTER ? numberOfMonthsInQuarter : 1;
  const start = moment.parseZone(timeframe.startDate).tz(timezone);

  const milestones = [];
  for (let milestonesIndex = 0; milestonesIndex < numberOfMilestones; milestonesIndex++) {
    const milestoneStartDate = start.clone().add(monthsDiff * milestonesIndex, 'M').startOf('month');
    const milestoneEndDate = milestoneStartDate.clone().endOf('month');

    milestones.push(
      {
        id: uuidv4(),
        frequencyType: milestonesFrequencyType,
        timeframe: {
          startDate: milestoneStartDate.toDate(),
          endDate: milestoneEndDate.toDate(),
        },
      }
    );
  }

  return getMilestonesWithEqualAmounts({ milestones, amount });
}

export function getMilestonesWithEqualAmounts({ milestones, amount }) {
  const milestoneAmounts = equalValues({ amount, array: milestones });
  return milestones.map((milestone, i) => ({
    ...milestone,
    targetValue: milestoneAmounts[i],
  }));
}
