import { isNil } from 'lodash';
import { isGoalOnTrack } from 'components/utils/goals';
import { onboardingTagsIds } from 'components/pages/analyze/enums';
import { getPacingCalculate } from 'components/utils/logic/utils';

export function getGoalsWithPacing({ goals }) {
  return goals.map((goal) => {
    const pacing = getPacingCalculate({ timeframe: goal.timeframe, actual: goal.actualValue });
    const isRealGoal = !isNil(goal.targetValue);
    const usePacingForGoal = goal.cumulativeMetric && isRealGoal;
    const valueForOnTrackCalculation = usePacingForGoal ? pacing : goal.targetValue;
    const isOnTrack = isGoalOnTrack({ metricType: goal.metricType, project: valueForOnTrackCalculation, target: goal.targetValue });
    return {
      ...goal,
      pacing,
      isOnTrack,
    };
  });
}

export function setTagIdOnGoalByPacingAndMostAdvancedStage({ goals }) {
  let goalToTag = null;

  const goalsWithPacingAndOnTrack = goals.filter((goal) => goal.pacing !== 0 && !goal.isOnTrack);

  if (goalsWithPacingAndOnTrack.length > 0) {
    goalToTag = goalsWithPacingAndOnTrack[0];
    for (const goal of goalsWithPacingAndOnTrack) {
      if (goal.actualValue < goalToTag.actualValue) {
        goalToTag = goal;
      }
    }
  } else {
    const metricsPriorityOrder = ['newBookings', 'newPipeline'];
    for (const metric of metricsPriorityOrder) {
      const metricGoal = goals.find((goal) => goal.metricName === metric);
      if (metricGoal) {
        goalToTag = metricGoal;
        break;
      }
    }

    if (!goalToTag) {
      const funnelGoals = goals.filter((goal) => goal.metricType === 'funnelStage');
      if (funnelGoals.length > 0) {
        goalToTag = funnelGoals[0];
        for (const goal of funnelGoals) {
          const prevNum = parseInt(goalToTag.metricName.split('funnel')[1], 10);
          const currentNum = parseInt(goal.metricName.split('funnel')[1], 10);
          if (currentNum > prevNum) {
            goalToTag = goal;
          }
        }
      }
    }
  }

  if (goalToTag) {
    goalToTag.tagId = onboardingTagsIds.selectedGoal;
    const remainingGoals = goals.filter((goal) => goal.id !== goalToTag.id);
    remainingGoals.unshift(goalToTag);
    return remainingGoals;
  }

  return goals;
}
