import React, { useEffect } from 'react';

import ImpactBarChart from 'components/pages/analyze/ImpactBoard/ImpactBarChart';
import { getParseJourneyImpactByChartData } from 'components/pages/users/journeyStatsTab/logic/JourneyImpactByChart';

import style from 'styles/users/journey-stats.css';

const styles = style.locals || {};

function JourneyImpactByChart({
  kpiFocus, isLoaded, data, selectedSegment, currentSegmentLabel,
}) {
  useEffect(() => {
    style.use();
    return () => {
      if (!style.unuse) {
        return;
      }
      style.unuse();
    };
  }, []);

  const { chartData, dataNames } = getParseJourneyImpactByChartData({ impactByChartData: data });
  const colorIndexs = {};
  for (const [index, barName] of dataNames.entries()) {
    colorIndexs[barName] = index;
  }

  return (
    <div className={styles.journeyImpactByChartWrapper}>
      <ImpactBarChart
        dataNames={dataNames}
        chartData={chartData}
        indicator={kpiFocus}
        isCumulativeMetric
        colorIndexs={colorIndexs}
        scrollToPosition={chartData.length * 84}
        chartHeight={300}
        firstSegment={selectedSegment}
        currentSegmentLabel={currentSegmentLabel}
        impactChartTooltipWidth={260}
        isLoaded={isLoaded}
        tooltipDisplayName="Touchpoints"
      />
    </div>
  );
}

export default JourneyImpactByChart;
