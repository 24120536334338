import { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { inject, observer } from 'mobx-react';

import useStyles from 'hooks/useStyles';

import WidgetHeader from 'components/common/WidgetHeader';
import ErrorWidgetWithBlur from 'components/common/ErrorWidgetWithBlur';
import Toggle from 'components/controls/Toggle';
import servicesStore from 'stores/servicesStore';
import VolumeView from 'components/widgets/funnelAnalysis/VolumeView';
import RevenueView from 'components/widgets/funnelAnalysis/RevenueView';
import MultiCheckSelect from 'components/controls/MultiCheckSelect';

import { Events } from 'trackers/analytics/enums';

import { widgetTypes } from 'components/pages/analyze/enums';
import { getReportSyncStatus } from 'components/pages/createIntegration/logic/CRMConfigFunnel';
import {
  getFunnelStagesConfigurationData,
  getRevenueViewStages,
  getStageMenuParams,
  getNavigationMenuParams, filterPacingForPastTimeframes,
} from 'components/widgets/funnelAnalysis/logic/funnelStage';
import {
  funnelAnalysisViewTypes, funnelAnalysisViewTypesLabels, volumeViewMetricsOptions, revenueViewMetricsOptions, supportedMetricsKeys,
} from 'components/widgets/funnelAnalysis/enums';
import { reorderArrayByArray } from 'components/pages/analyze/SegmentsTab/logic/ImpactBySegmentChartData';

import style from 'styles/funnelAnalysis/funnelAnalysis.css';

const styles = style.locals || {};

function FunnelAnalysis({
  widgetHeaderConfig,
  widgetHeaderProps = {},
  isLoaded,
  isFailedToLoad,
  timeframe,
  fullTimeFrame,
  funnels,
  funnelsOptions,
  data = {},
  region,
  salesforceapi,
  hubspotapi,
  predefinedFiltersConfig,
  openCurtain,
}) {
  useStyles([style]);

  if (isFailedToLoad) {
    return (
      <ErrorWidgetWithBlur
        status="error"
        widgetType={widgetTypes.funnelAnalysis}
      />
    );
  }

  const [maxStageValue, setMaxStageValue] = useState(0);
  const [reportSyncData, setReportSyncData] = useState({});
  const [selectedViewType, setSelectedViewType] = useState(funnelAnalysisViewTypes.volumeView);

  const [supportedMetricsByView, setSupportedMetricsView] = useState({
    [funnelAnalysisViewTypes.volumeView]: volumeViewMetricsOptions,
    [funnelAnalysisViewTypes.revenueView]: revenueViewMetricsOptions,
  });
  const [selectedStagesByView, setSelectedStagesByView] = useState({
    [funnelAnalysisViewTypes.volumeView]: funnelsOptions || [],
    [funnelAnalysisViewTypes.revenueView]: getRevenueViewStages(),
  });

  useEffect(() => {
    getReportSyncStatus({
      region,
      handleResponseData: ({ responseData }) => {
        setReportSyncData(responseData);
      },
    });
  }, [region]);

  useEffect(() => {
    if (selectedViewType === funnelAnalysisViewTypes.volumeView) {
      if ((!selectedStagesByView && funnelsOptions) || !isEqual(selectedStagesByView, funnelsOptions)) {
        setSelectedStagesByView({
          ...selectedStagesByView,
          [funnelAnalysisViewTypes.volumeView]: funnelsOptions || [],
        });
      }
    }
  }, [funnelsOptions]);

  useEffect(() => {
    let maxValue = 0;
    for (const stage of selectedStagesByView[selectedViewType]) {
      const currentStage = stage.value;
      const stageVolume = data[currentStage]?.value || 0;
      if (stageVolume > maxValue) {
        maxValue = stageVolume;
      }
    }
    setMaxStageValue(maxValue);
  }, [data, selectedStagesByView[selectedViewType]]);

  function onChangeToggleView({ viewType }) {
    setSelectedViewType(viewType);

    servicesStore.eventTracker.track({
      eventName: Events.funnelAnalysis.toggledVolumeRevenueView,
      properties: {
        viewType: funnelAnalysisViewTypesLabels[viewType],
      },
    });
  }

  function onChangeSelectedStage({ selectedStages }) {
    const originalOptions = selectedViewType === funnelAnalysisViewTypes.volumeView ? funnelsOptions : getRevenueViewStages();
    const orderedSelectedOptions = reorderArrayByArray({ orderByArray: originalOptions, arrayToOrder: selectedStages });

    setSelectedStagesByView((prev) => ({
      ...prev,
      [selectedViewType]: orderedSelectedOptions,
    }));

    servicesStore.eventTracker.track({
      eventName: Events.funnelAnalysis.selectFunnelStages,
      properties: {
        selectedStagesRatio: selectedStages.length / funnels.length,
      },
    });
  }

  function onChangeSelectedSupportedMetrics({ selectedMetrics }) {
    setSupportedMetricsView((prev) => ({
      ...prev,
      [selectedViewType]: selectedMetrics,
    }));

    const selectedMetricsValues = selectedMetrics.map((metric) => metric.value);
    servicesStore.eventTracker.track({
      eventName: Events.funnelAnalysis.selectMetrics,
      properties: {
        isConversionRateChecked: selectedMetricsValues.includes(supportedMetricsKeys.conversionRate),
        isVelocityChecked: selectedMetricsValues.includes(supportedMetricsKeys.velocity),
        isPacingForChecked: selectedMetricsValues.includes(supportedMetricsKeys.pacing),
        isCompareToPrevChecked: selectedMetricsValues.includes(supportedMetricsKeys.comparison),
        isCostPerChecked: selectedMetricsValues.includes(supportedMetricsKeys.costPer),
        isAvgDealSizeChecked: selectedMetricsValues.includes(supportedMetricsKeys.avgDealSize),
        isROIChecked: selectedMetricsValues.includes(supportedMetricsKeys.ROI),
        isAllMetricsChecked: supportedMetricsOptions.length === selectedMetrics.length,
      },
    });
  }

  const widgetHeaderConfigParams = {
    title: 'Funnel Analysis',
    type: widgetTypes.funnelAnalysis,
    selectedViewType,
    // supportedMetricsByView: supportedMetricsByView,  TODO: Add to reports params
    // selectedStagesByView: selectedStagesByView,  TODO: Add to reports params
    ...(widgetHeaderConfig || {}),
  };

  const stagesConfigurationData = useMemo(() => getFunnelStagesConfigurationData({
    salesforceapi,
    hubspotapi,
    funnelStagesToTheirGroupByType: data?.stagesConfigs?.funnelStagesToTheirGroupByType || {},
    predefinedFiltersConfig,
    reportSyncData,
    funnels,
  }), [data?.stagesConfigs?.funnelStagesToTheirGroupByType, salesforceapi, hubspotapi, predefinedFiltersConfig, reportSyncData, funnels]);

  const supportedMetricsOptions = useMemo(() => {
    const options = selectedViewType === funnelAnalysisViewTypes.volumeView ? volumeViewMetricsOptions : revenueViewMetricsOptions;
    return filterPacingForPastTimeframes({ options, fullTimeFrame });
  }, [selectedViewType, timeframe]);

  const selectedMetricsByView = useMemo(() => {
    const options = supportedMetricsByView[selectedViewType];
    return filterPacingForPastTimeframes({ options, fullTimeFrame });
  }, [selectedViewType, timeframe, supportedMetricsByView]);

  return (
    <div className={styles.funnelAnalysisWrapper}>
      <WidgetHeader
        {...widgetHeaderProps}
        widgetHeaderConfig={widgetHeaderConfigParams}
        isHideAddToReport
      >
        <Toggle
          options={[{
            icon: <div data-icon="funnel:hashtag" className={styles.toggleIcon} />,
            value: funnelAnalysisViewTypes.volumeView,
            tooltip: funnelAnalysisViewTypesLabels[funnelAnalysisViewTypes.volumeView],
          }, {
            icon: <div data-icon="funnel:dollar" className={styles.toggleIcon} />,
            value: funnelAnalysisViewTypes.revenueView,
            tooltip: funnelAnalysisViewTypesLabels[funnelAnalysisViewTypes.revenueView],
          }]}
          selectedValue={selectedViewType}
          onClick={(viewType) => onChangeToggleView({ viewType })}
          minWidth="36px"
        />

        <MultiCheckSelect
          options={selectedViewType === funnelAnalysisViewTypes.volumeView ? funnelsOptions : getRevenueViewStages()}
          isDropDownSelect
          selected={selectedStagesByView[selectedViewType]}
          onChange={(selectedStages) => onChangeSelectedStage({ selectedStages })}
          dropdownLabel="Funnel Stages"
          controlWidth={250}
          isSearchable={false}
          isClearable={false}
          isSearchIconPlaceholder={false}
          isDropdownIndicator
          isMultiLabelAsText
          selectAll
          className={styles.dropdownMenu}
          selectAllClickedLabel="All"
        />

        <MultiCheckSelect
          options={supportedMetricsOptions}
          isDropDownSelect
          selected={selectedMetricsByView}
          onChange={(selectedMetrics) => onChangeSelectedSupportedMetrics({ selectedMetrics })}
          dropdownLabel="Metrics"
          controlWidth={250}
          isSearchable={false}
          isClearable={false}
          isSearchIconPlaceholder={false}
          isDropdownIndicator
          isMultiLabelAsText
          selectAll
          className={styles.dropdownMenu}
          selectAllClickedLabel="All"
        />
      </WidgetHeader>

      {selectedViewType === funnelAnalysisViewTypes.volumeView ? (
        <VolumeView
          data={data}
          isLoaded={isLoaded}
          timeframe={timeframe}
          fullTimeFrame={fullTimeFrame}
          selectedStages={selectedStagesByView[selectedViewType]}
          maxStageValue={maxStageValue}
          supportedMetrics={supportedMetricsByView[selectedViewType]?.map((metric) => metric.value)}
          stagesConfigurationData={stagesConfigurationData}
          widgetHeaderConfig={widgetHeaderConfig}
          openCurtain={openCurtain}
          getStageMenuParams={({ onClickOption, currentStage }) => getStageMenuParams({
            onClickOption, currentStage, classNameMenu: styles.dropdownMenu, timeframe,
          })}
          getNavigationMenuParams={({ currentStage }) => getNavigationMenuParams({
            indicator: currentStage, widgetHeaderConfig, timeframe,
          })}
        />
      ) : (
        <RevenueView
          data={data}
          isLoaded={isLoaded}
          timeframe={timeframe}
          fullTimeFrame={fullTimeFrame}
          selectedStages={selectedStagesByView[selectedViewType]}
          maxStageValue={maxStageValue}
          supportedMetrics={supportedMetricsByView[selectedViewType]?.map((metric) => metric.value)}
          widgetHeaderConfig={widgetHeaderConfig}
          openCurtain={openCurtain}
          getStageMenuParams={({ onClickOption, currentStage }) => getStageMenuParams({
            onClickOption, currentStage, classNameMenu: styles.dropdownMenu, timeframe,
          })}
          getNavigationMenuParams={({ currentStage }) => getNavigationMenuParams({
            indicator: currentStage, widgetHeaderConfig, timeframe,
          })}
        />
      )}
    </div>
  );
}

export default inject(
  ({
    userStore: {
      funnels,
      funnelsOptions,
      userMonthPlan: {
        region,
        salesforceapi,
        hubspotapi,
        CRMConfig: {
          predefinedFiltersConfig,
        } = {},
      },
    },
  }) => ({
    region,
    funnels,
    salesforceapi,
    hubspotapi,
    predefinedFiltersConfig,
    funnelsOptions,
  }),
  observer
)(FunnelAnalysis);
