import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';

import useStyles from 'hooks/useStyles';

import Dropdown from 'components/controls/Dropdown';

import { impactChartTypes } from 'components/pages/analyze/SegmentsTab/logic/enums';
import { FREQUENCY_OPTIONS } from 'components/utils/frequency';
import { getSegmentsOptionsFields } from 'components/pages/analyze/OverviewTab/logic';
import { getImpactByFieldsToEnrichAccordingSegment } from 'components/pages/analyze/SegmentsTab/logic/ImpactBySegment';
import { getDrilldownMetricsOptions, groupBySegmentsOptions } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { nonCumulativeMetricTypes } from 'components/common/logic/enums';

import style from 'styles/reports/widget-builder.css';

const styles = style.locals || {};

function ImpactBySegmentProperties({
  selectedWidgetProperties,
  onSelectedWidgetProperties,
  customFieldsIdToLabelMap,
  customUtmsWhitelist,
  widgetProps,
}) {
  useStyles([style]);

  const [selectedMetric, setSelectedMetric] = useState(selectedWidgetProperties.kpiFocus);

  useEffect(() => {
    const defaultProperties = {
      toggleViewType: impactChartTypes.bar,
      impactBySegmentParams: {
        segmentFrequency: FREQUENCY_OPTIONS.DAY?.value,
      },
      checkedSegments: [],
      uncheckedSegments: [],
    };
    onSelectedWidgetProperties({ values: defaultProperties });
  }, []);

  function onChangeBreakdownBy({ value }) {
    const impactBySegmentParams = {
      segmentFrequency: selectedWidgetProperties.impactBySegmentParams?.segmentFrequency,
      firstSegment: value,
      fieldsToEnrich: getImpactByFieldsToEnrichAccordingSegment({ selectedSegmentsParams: { firstSegment: value } }),
    };
    const updatedValues = { impactBySegmentParams };
    if (widgetProps.isCalculateAdvancedMetrics) {
      const trendBySegmentsParams = {
        firstSegment: value, secondSegment: null,
      };
      updatedValues.trendBySegmentsParams = trendBySegmentsParams;
    }
    onSelectedWidgetProperties({ values: updatedValues });
  }

  const segmentsOptionsFields = getSegmentsOptionsFields({ customFieldsIdToLabelMap, customUtmsWhitelist });

  function onChangeMetric({ value, relevantFor }) {
    let valueForServerRequest = value;
    if (valueForServerRequest.startsWith('efficiency')) {
      valueForServerRequest = 'efficiency';
    }

    setSelectedMetric(value);
    const isNonCumulativeMetric = nonCumulativeMetricTypes.some((metric) => valueForServerRequest.toLowerCase().includes(metric.toLowerCase()));
    onSelectedWidgetProperties({
      values: {
        kpiFocus: valueForServerRequest,
        conversionIndicator: relevantFor || valueForServerRequest,
        toggleViewType: isNonCumulativeMetric ? impactChartTypes.line : impactChartTypes.bar,
      },
    });
  }

  const drilldownMetricsOptions = useMemo(() => {
    const allOptions = getDrilldownMetricsOptions({});
    for (const groupData of allOptions) {
      for (const option of groupData.options) {
        if (option.value !== 'efficiency') {
          continue;
        }
        option.value = `efficiency${option.relevantFor}`;
      }
    }
    return allOptions;
  }, []);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.title}>Metric</div>
        <Dropdown
          onChange={({ value, relevantFor }) => onChangeMetric({ value, relevantFor })}
          options={drilldownMetricsOptions}
          selectedKey={selectedMetric}
          isSearchable
        />
      </div>

      <div className={styles.row}>
        <div className={styles.title}>Breakdown by</div>
        <Dropdown
          onChange={({ value }) => onChangeBreakdownBy({ value })}
          options={groupBySegmentsOptions({ options: segmentsOptionsFields })}
          selectedKey={selectedWidgetProperties.impactBySegmentParams?.firstSegment}
          isSearchable
          disabled={!selectedWidgetProperties.kpiFocus}
        />
      </div>

      <div className={styles.row}>
        <div className={styles.title}>Frequency</div>
        <Dropdown
          onChange={({ value }) => onSelectedWidgetProperties({
            values: {
              impactBySegmentParams: {
                segmentFrequency: value,
                firstSegment: selectedWidgetProperties.impactBySegmentParams?.firstSegment,
                fieldsToEnrich: selectedWidgetProperties.impactBySegmentParams?.fieldsToEnrich,
              },
            },
          })}
          options={[
            FREQUENCY_OPTIONS.DAY,
            FREQUENCY_OPTIONS.WEEK,
            FREQUENCY_OPTIONS.MONTH,
            FREQUENCY_OPTIONS.QUARTER,
          ]}
          selectedKey={selectedWidgetProperties.impactBySegmentParams?.segmentFrequency}
          disabled={!selectedWidgetProperties.kpiFocus || !selectedWidgetProperties.impactBySegmentParams?.firstSegment}
        />
      </div>
    </>
  );
}

export default inject(
  ({
    userStore: {
      userAccount: {
        customFieldsIdToLabelMap,
        customUtmsWhitelist,
      } = {},
    } = {},
  }) => ({
    customFieldsIdToLabelMap,
    customUtmsWhitelist,
  }),
  observer
)(ImpactBySegmentProperties);
