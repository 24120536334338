import React from 'react';
import classnames from 'classnames';
import sumBy from 'lodash/sumBy';
import { compose } from 'components/utils/utils';
import { inject, observer } from 'mobx-react';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import Page from 'components/Page';
import Toggle from 'components/controls/Toggle';
import ObjectiveInput from 'components/pages/settings/goals/controls/ObjectiveInput';
import MilestoneBox from 'components/pages/settings/goals/MilestoneBox';

import { frequencyTypes, divideTypes, defaultMilestonesConfig } from 'components/pages/settings/goals/logic/enums';
import { formatIndicatorDisplay } from 'components/utils/indicators';
import { extractNumberFromBudget } from 'components/utils/budget';
import { getMilestones } from 'components/pages/settings/goals/logic/MilestonesTab';

import objectivePopup from 'styles/welcome/objective-popup.css';

const objClasses = objectivePopup.locals;

const enhance = compose(
  inject(({ userStore }) => ({
    timezone: userStore.userMonthPlan.CRMConfig.timezone,
  })),
  observer
);

class MilestonesTab extends Component {
  constructor(props) {
    super(props);

    const { divideType, frequencyType } = props.milestonesConfig || defaultMilestonesConfig;

    this.state = {
      milestones: props.milestones.length > 0
        ? props.milestones
        : getMilestones({
          timeframe: this.props.timeframe,
          timezone: this.props.timezone,
          goalFrequencyType: this.props.goalFrequencyType,
          milestonesFrequencyType: frequencyType,
          amount: props.targetValue,
        }),
      divideType,
      frequencyType,
      targetValue: props.targetValue,
    };
  }

  updateMilestoneTargetValue = (newValue, milestones, milestoneId) => {
    const milestone = milestones.find((m) => m.id === milestoneId);
    milestone.targetValue = newValue;
    this.setState({
      milestones,
    });
  };

  render() {
    const {
      moveToPropertiesTab,
      metricName,
      goalFrequencyType,
    } = this.props;

    const {
      milestones,
      targetValue,
      divideType,
      frequencyType,
    } = this.state;

    const frequencyTypeOptions = [{
      value: frequencyTypes.MONTH,
      label: 'Monthly',
    }];

    if (goalFrequencyType === frequencyTypes.YEAR) {
      frequencyTypeOptions.push({
        value: frequencyTypes.QUARTER,
        label: 'Quarterly',
      });
    }

    const titleText = 'Break target into milestones';
    const descriptionText = 'Choose between quarterly or monthly milestones';
    const allocatedSum = sumBy(milestones, (milestone) => milestone.targetValue);

    return (
      <Page
        popup
        width="875px"
        className={objClasses.externalContainer}
        contentClassName={objClasses.content}
        innerClassName={objClasses.inner}
      >
        <div className={objClasses.contentContainer}>
          <div className={objClasses.backIconContainer} onClick={() => moveToPropertiesTab({})}>
            <div style={{ position: 'relative' }}>
              <div className={objClasses.backIcon} />
            </div>
            <div className={objClasses.backLine} />
          </div>
          <div className={objClasses.centerCol}>
            <div className={objClasses.title}>
              {titleText}
            </div>
            <div className={objClasses.titleSecond}>
              {descriptionText}
            </div>
          </div>
          <div className={objClasses.milestoneContainer}>
            <div className={classnames(objClasses.milestoneWrapCol)}>
              {milestones.map((milestone) => (
                <MilestoneBox
                  key={milestone.id}
                  metricName={metricName}
                  targetValue={milestone.targetValue}
                  timeframe={milestone.timeframe}
                  isQuarter={frequencyType === frequencyTypes.QUARTER}
                  onChange={({ target: { value } }) => {
                    const newValue = extractNumberFromBudget(value);
                    return this.updateMilestoneTargetValue(newValue, milestones, milestone.id);
                  }}
                  disabled={divideType === divideTypes.EQUALLY}
                />
              ))}
            </div>
            <div className={objClasses.milestoneControlCol}>
              <ObjectiveInput
                title="Divide target"
                width={224}
                containerStyle={{ marginBottom: 19 }}
                selectProps={{
                  selectedKey: frequencyType,
                  placeholder: '',
                  options: frequencyTypeOptions,
                  onChange: (selected) => {
                    this.setState({
                      frequencyType: selected.value,
                      milestones: getMilestones({
                        timeframe: this.props.timeframe,
                        timezone: this.props.timezone,
                        goalFrequencyType: this.props.goalFrequencyType,
                        milestonesFrequencyType: selected.value,
                        amount: targetValue,
                      }),
                    });
                  },
                }}
              />
              <ObjectiveInput
                title="Divide"
                width={200}
                containerStyle={{ paddingLeft: 12 }}
              >
                <Toggle
                  options={[{
                    text: 'Equally',
                    value: divideTypes.EQUALLY,
                  }, {
                    text: 'Manually',
                    value: divideTypes.MANUALLY,
                  }]}
                  selectedValue={divideType}
                  onClick={(selectedValue) => {
                    this.setState({
                      divideType: selectedValue,
                      milestones: selectedValue === divideTypes.EQUALLY
                        ? getMilestones({
                          timeframe: this.props.timeframe,
                          timezone: this.props.timezone,
                          goalFrequencyType: this.props.goalFrequencyType,
                          milestonesFrequencyType: frequencyType,
                          amount: targetValue,
                        })
                        : milestones,
                    });
                  }}
                  frameClass={objClasses.milestoneToggleFrame}
                />
              </ObjectiveInput>
              <div className={objClasses.milestoneDivider} />
              <ObjectiveInput
                title="Target"
                defaultWidth
                inputProps={{
                  placeHolder: '',
                  value: metricName ? formatIndicatorDisplay(metricName, targetValue, false, false) : metricName,
                  onChange: ({ target: { value } }) => {
                    const newTargetValue = extractNumberFromBudget(value);
                    this.setState({
                      targetValue: newTargetValue,
                      milestones: divideType === divideTypes.EQUALLY
                        ? this.getMilestones({ targetValue: newTargetValue, milestonesFrequencyType: frequencyType })
                        : milestones,
                    });
                  },
                  icon: 'objective:target',
                  inputClassName: objClasses.inputClassName,
                  className: objClasses.inputBoxClassName,
                  iconClass: objClasses.inputIconClass,
                }}
              />
              <div className={objClasses.allocate}>
                Left to allocate
                <div className={objClasses.allocateBudget}>
                  {metricName ? formatIndicatorDisplay(metricName, targetValue - allocatedSum, false, false) : 0}
                </div>
              </div>
              <div className={objClasses.footer}>
                <Button
                  type="secondaryWhite"
                  style={{ width: 200 }}
                  onClick={() => moveToPropertiesTab({ removeMilestones: true })}
                >
                  Cancel milestones and continue
                </Button>
                <Button
                  type="primaryBlue"
                  style={{ width: 200, marginLeft: '10px' }}
                  disabled={targetValue - allocatedSum !== 0}
                  onClick={
                      () => moveToPropertiesTab({
                        targetValue: this.state.targetValue,
                        milestonesConfig: {
                          frequencyType: this.state.frequencyType,
                          divideType: this.state.divideType,
                        },
                        milestones: this.state.milestones,
                        shouldSaveChanges: true,
                      })
                    }
                >
                  Save milestones and continue
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export default enhance(MilestonesTab);
