import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { orderBy } from 'lodash';

import userStore from 'stores/userStore';
import ChartSkeleton from 'components/common/ChartSkeleton';
import ComparisonValue from 'components/common/ComparisonValue';
import Loading3Dots from 'components/controls/Loading3Dots';

import { getIsGrowthDesired, getGrowthValue } from 'components/pages/analyze/utils/comparisonUtils';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import { getColor } from 'components/utils/colors';
import { formatIndicatorDisplay } from 'components/utils/indicators';
import { getItemLabelWithNoValue } from 'components/utils/logic/utils';
import { othersKey } from 'components/pages/analyze/SegmentsTab/logic/enums';
import { formatNumberWithDecimalPoint } from 'components/utils/logic/budget';

import style from 'styles/analyze/generated-impact.css';

const styles = style.locals;

export default function ImpactBarChart({
  chartData = [],
  dataNames = [],
  indicator,
  currentSegmentLabel,
  firstSegment,
  colorIndexs,
  scrollToPosition = 0,
  barsChartWidth = chartData.length * 84,
  chartHeight = 400,
  chartClassName,
  impactChartTooltipWidth,
  isShowScrollBar = false,
  isShowBarTopLabel = false,
  emptyStateText = 'No values',
  isNonCumulativeMetric = false,
  isLoaded,
  growthImpactData = {},
  growthByFrequency = {},
  isCompareToPreviousEnabled,
  isEnableSkeletonAnimation,
  tooltipDisplayName,
}) {
  const isDataLoaded = chartData.length !== 0 || isLoaded;

  const barChartContainer = useRef();
  const scrollTooltipRef = useRef();

  useEffect(() => {
    style.use();
    return () => {
      style.unuse();
    };
  }, []);

  useEffect(() => {
    if (scrollTooltipRef.current) {
      scrollTooltipRef.current.scrollLeft = scrollToPosition;
    }
  }, [scrollToPosition, scrollTooltipRef.current]);

  if (!isDataLoaded) {
    return (
      <ChartSkeleton isEnableSkeletonAnimation={isEnableSkeletonAnimation} />
    );
  }

  const barChartRefWidth = barChartContainer?.current?.offsetWidth ? (barChartContainer.current.offsetWidth - 100) : 600;
  const chartWidth = barsChartWidth > barChartRefWidth ? barsChartWidth : undefined;

  function ChartTooltip(tooltipProps) {
    const groupDisplayName = tooltipDisplayName || userStore.getMetricNickname({ metric: indicator });
    const { payload, label, active } = tooltipProps;
    if (!active || !payload) {
      return null;
    }

    const orderPayload = orderBy(payload, ['value'], ['desc', 'asc']);
    const orderPayloadMoverOthersToTheEnd = orderBy(orderPayload, [(item) => item.dataKey === othersKey], ['asc']);
    const index = chartData.findIndex((period) => period.name === label);
    const total = chartData[index]?.total || 0;

    return (
      <div className={styles.impactChartTooltip} style={{ left: `-${scrollTooltipRef.current.scrollLeft}px`, maxWidth: `${impactChartTooltipWidth}px` }}>
        <div className={styles.impactChartTooltipLabel}>
          {isNonCumulativeMetric ? (
            <>{`${label} - ${groupDisplayName}`}</>
          ) : (
            <>{`${label} - ${formatIndicatorDisplay(indicator, total, true, true, true, 2)} ${groupDisplayName}`}</>
          )}
        </div>

        <div>
          {orderPayloadMoverOthersToTheEnd.map(({
            name, value, color, dataKey,
          }) => {
            const isOthers = dataKey === othersKey;
            const percent = (value / total) * 100;
            const itemName = getItemLabelWithNoValue({ value: name, segment: firstSegment, segmentLabel: currentSegmentLabel });
            const growthValue = isNonCumulativeMetric ? growthByFrequency[label] : growthImpactData[label]?.[name];

            if (value === 0) {
              return null;
            }

            return (
              <div
                className={styles.impactChartTooltipValue}
                key={name}
              >
                <div className={styles.row}>
                  <div
                    className={styles.circle}
                    style={{ backgroundColor: color }}
                  />
                  <div>{itemName}</div>
                </div>
                <div className={styles.row}>
                  <span style={{ marginRight: 5 }}>
                    {formatIndicatorDisplay(indicator, value, true, true, true, 2)}
                  </span>
                  (
                  {formatNumberWithDecimalPoint(percent, 2)}
                  %)
                  {(!isOthers && isCompareToPreviousEnabled) ? (
                    <>
                      {growthValue === undefined ? (
                        <Loading3Dots />
                      ) : (
                        <ComparisonValue
                          value={getGrowthValue({
                            metricName: indicator,
                            growthPerMetric: { [indicator]: growthValue },
                          })}
                          isGrowthDesired={getIsGrowthDesired({ metricName: indicator })}
                          className={styles.comparisonValue}
                          overrideTextColor="#FFFFFF"
                        />
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  function getBarsChart() {
    if (isNonCumulativeMetric && dataNames[0] === 'total') {
      return (
        <Bar
          key="bar-total"
          yAxisId="left"
          dataKey="total"
          fill="#737EA4"
          isAnimationActive={false}
        />
      );
    }

    return (
      dataNames.map((item, index) => {
        const barKey = `bar-${item}-${index}`;
        return (
          <Bar
            key={barKey}
            yAxisId="left"
            dataKey={item}
            stackId="channels"
            fill={getColor(colorIndexs[item])}
            isAnimationActive={false}
          >
            {index === dataNames.length - 1 && isShowBarTopLabel && (
              <LabelList
                dataKey="total"
                position="top"
                style={{ fontSize: '12px', fontWeight: '600', fill: '#707ea7' }}
                formatter={(value) => formatIndicatorDisplay(indicator, value, true, true, true, 0)}
              />
            )}
          </Bar>
        );
      }));
  }

  if (!dataNames.length) {
    return (
      <div className={styles.chartEmptyState}>
        {emptyStateText}
      </div>
    );
  }
  return (
    <div className={styles.chartBar} ref={barChartContainer}>
      <div
        ref={scrollTooltipRef}
        className={classnames(styles.chartBarItem, styles.chartScroller, chartClassName)}
      >
        <ResponsiveContainer width={isShowScrollBar ? chartWidth : '100%'} height={chartHeight}>
          <BarChart
            data={chartData}
            barSize={32}
            className={styles.chartBarWrapper}
          >
            <CartesianGrid
              vertical={false}
              strokeDasharray="3 3"
              strokeWidth={1}
              stroke="rgba(54, 56, 64, 0.1)"
            />
            <XAxis
              dataKey="name"
              axisLine={false}
              tick={{ fontSize: '12px', color: '#707ea7' }}
              tickLine
              tickMargin={10}
              height={40}
              minTickGap={20}
            />
            <YAxis
              yAxisId="left"
              axisLine={false}
              tick={{ fontSize: '12px', color: '#707ea7' }}
              tickLine={false}
              tickMargin={15}
              tickFormatter={(value) => formatIndicatorDisplay(indicator, value, true)}
            />
            {getBarsChart()}
            <RechartsTooltip
              cursor={false}
              wrapperStyle={{
                zIndex: 5, position: 'fixed', top: 0,
              }}
              offset={0}
              content={<ChartTooltip />}
              animationDuration={50}
              viewBox={{
                x: 0, y: 0, width: 300, height: 300,
              }}
              allowEscapeViewBox={{ x: true, y: true }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
