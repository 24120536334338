import React, { useEffect, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import history from 'history';

import AccountPrioritization from 'components/widgets/signals/AccountPrioritization';
import BreadcrumbsTitle from 'components/common/BreadcrumbsTitle';

import { getAccountPrioritizationMockData, getMySignalsMockData } from 'components/widgets/signals/logic/signals';
import { pageLabels, tabsPaths } from 'enums';

function AccountsPriorityPage({ flags, mySignals = getMySignalsMockData() }) {
  if (!flags.headerSalesEnablementTab) {
    history.push(tabsPaths.home);
    return null;
  }

  const [accountPrioritizationData, setAccountPrioritizationData] = useState([]);

  useEffect(() => {
    if (flags.salesEnablementDemoOnly) {
      setAccountPrioritizationData(getAccountPrioritizationMockData());
    }
  }, []);

  return (
    <>
      <BreadcrumbsTitle breadcrumbs={[pageLabels.salesEnablement, pageLabels.accountPrioritization]} />
      <AccountPrioritization
        data={accountPrioritizationData}
        isLoaded
        mySignals={mySignals}
      />
    </>
  );
}

export default withLDConsumer()(AccountsPriorityPage);
