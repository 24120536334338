import React from 'react';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';

import Component from 'components/Component';
import GoalRecordRow from 'components/pages/settings/goals/GoalRecordRow';

import { compose } from 'components/utils/utils';
import { getMonthTitle, getQuarterTitle } from 'components/utils/goals';
import { frequencyTypes, goalSkeletonId } from 'components/pages/settings/goals/logic/enums';

import style from 'styles/settings/goals/goals.css';

const enhance = compose(
  inject(({ userStore }) => ({
    fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth,
    timezone: userStore.userMonthPlan.CRMConfig.timezone,
  })),
  observer
);

class GoalsList extends Component {
  style = style;

  onSubOptionsChange = (goalId, subSelections) => {
    const { onChange, expanded } = this.props;
    expanded[goalId] = subSelections;
    onChange(expanded);
  };

  onExpandChange = (goalId) => {
    const { onChange, expanded } = this.props;
    if (expanded[goalId]) {
      delete expanded[goalId];
    } else {
      expanded[goalId] = {};
    }
    onChange(expanded);
  };

  getEnrichedGoals() {
    const { goals, fiscalYearFirstMonth, timezone } = this.props;

    const enrichedGoals = [];
    for (const goal of goals) {
      const { metricName, title } = goal;

      const enrichedGoal = {
        ...goal,
        isMainGoal: true,
        containBreakdowns: false,
      };

      if (goal.milestonesConfig) {
        enrichedGoal.containBreakdowns = true;
        const haveQuarterlyAdditionalMilestones = goal.frequencyType === frequencyTypes.YEAR
          && goal.milestonesConfig.frequencyType === frequencyTypes.MONTH;

        if (haveQuarterlyAdditionalMilestones) {
          enrichedGoal.milestones = [];
          for (const quarterlyMilestone of goal.milestones) {
            enrichedGoal.milestones.push(...quarterlyMilestone.milestones);

            if (!enrichedGoal.breakdowns) {
              enrichedGoal.breakdowns = [];
            }

            enrichedGoal.breakdowns.push({
              id: quarterlyMilestone.id,
              metricName,
              title: `${title} in ${
                getQuarterTitle({ startDate: quarterlyMilestone.timeframe.startDate, timezone, fiscalYearFirstMonth })
              }`,
              targetValue: quarterlyMilestone.targetValue,
              actualValue: quarterlyMilestone.actualValue,
              timeframe: quarterlyMilestone.timeframe,
              isMainGoal: false,
              containBreakdowns: true,
              breakdowns: quarterlyMilestone.milestones.map((milestone) => ({
                ...milestone,
                metricName,
                title: `${title} in ${getMonthTitle({ startDate: milestone.timeframe.startDate, timezone })}`,
                isMainGoal: false,
                containBreakdowns: false,
              })),
            });
          }
        } else {
          const isMonthlyBreakdowns = goal.milestonesConfig.frequencyType === frequencyTypes.MONTH;
          enrichedGoal.breakdowns = goal.milestones.map((milestone) => ({
            ...milestone,
            metricName,
            title: `${title} in ${
              isMonthlyBreakdowns
                ? getMonthTitle({ startDate: milestone.timeframe.startDate, timezone })
                : getQuarterTitle({ startDate: milestone.timeframe.startDate, timezone, fiscalYearFirstMonth })
            }`,
            isMainGoal: false,
            containBreakdowns: false,
          }));
        }
      }

      enrichedGoals.push(enrichedGoal);
    }

    return enrichedGoals;
  }

  render() {
    const {
      goals,
      expanded,
      editGoalPopup,
      isLastParent,
      isRoot,
      isMainGoalsList = true,
      isLoaded,
      status,
      goalsAnalysisRequestId,
    } = this.props;

    const enrichedGoals = !isMainGoalsList ? goals : this.getEnrichedGoals();
    if (!isLoaded) {
      enrichedGoals.push({ id: goalSkeletonId });
    }

    return (
      <div>
        {enrichedGoals.map((goal, index) => {
          const isExpanded = expanded[goal.id];
          const isLast = goals.length - 1 === index;
          const key = `goal-id-${goal.id}`;

          return (
            <div
              className={classnames(this.classes.objectiveItemGeneral, {
                [this.classes.objectiveItemRoot]: isRoot,
                [this.classes.objectiveItemWrapper]: goal.isMainGoal,
              })}
              key={key}
            >
              {goal.containBreakdowns && isExpanded && <div className={this.classes.line} />}
              <GoalRecordRow
                key={key}
                goalIndex={index}
                goal={goal}
                setExpanded={() => this.onExpandChange(goal.id)}
                isExpanded={isExpanded}
                editGoalPopup={editGoalPopup}
                isFullyLast={isLast && isLastParent}
                isRoot={isRoot}
                status={status}
                createAlertPopup={this.props.createAlertPopup}
                isLoaded={goal.id !== goalSkeletonId}
                goals={goals}
                goalsAnalysisRequestId={goalsAnalysisRequestId}
              />
              <div style={{ paddingLeft: 30 }}>
                {goal.containBreakdowns && isExpanded && (
                  <GoalsList
                    goals={goal.breakdowns}
                    expanded={expanded[goal.id]}
                    onChange={(subSelections) => this.onSubOptionsChange(goal.id, subSelections)}
                    isLastParent={isLast}
                    editGoalPopup={editGoalPopup}
                    isMainGoalsList={false}
                    status={status}
                    createAlertPopup={this.props.createAlertPopup}
                    goalsAnalysisRequestId={goalsAnalysisRequestId}
                    isLoaded
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default enhance(GoalsList);
