import React from 'react';
import { widgetTypes } from 'components/pages/analyze/enums';

export const suggestionsTypes = {
  overviewMetrics: 'overviewMetrics',
  topChannels: 'topChannels',
  channelsToContent: 'channelsToContent',
  segmentShowOnly: 'segmentShowOnly',
  default: 'default',
};

export const mockQuestions = [
  { type: suggestionsTypes.overviewMetrics, text: 'what was the business impact of marketing last year?' },
  { type: suggestionsTypes.topChannels, text: 'which Channels drove most Opps?' },
  { type: suggestionsTypes.channelsToContent, text: 'which content types work best with which channels?' },
  { type: suggestionsTypes.segmentShowOnly, text: 'which job titles work best with LinkedIn?' },
];

const overviewMetricsResults = [
  {
    questionId: 'overview-1',
    questionText: 'What was marketing\'s performance on {placeHolder1}?',
    questionType: suggestionsTypes.overviewMetrics,
    placeHoldersTypes: {
      placeHolder1: 'timeframes',
    },
    defaultPlaceHoldersValues: {
      placeHolder1: {
        value: 'prev_year',
      },
    },
    widget: {
      type: widgetTypes.statsSquare,
      configuration: {
        timeFrame: {
          value: 'prev_year',
        },
        isCompareToPreviousEnabled: false,
        filters: [],
        attributionModel: {
          value: 'default',
          label: 'Full Journey',
          flag: 'preferencesAttributionModelsFullJourneyDefault',
          description: 'A data-driven model that takes into account the touchpoint’s timing and location, as well as the contact’s role in the journey.',
        },
        attributionCredit: false,
        selectedMetrics: [
          'newFunnel1',
          'newFunnel2',
          'newFunnel3',
          'newFunnel4',
          'marketingAttributedRevenue',
          'marketingAttributedPipeline',
          'newBookings',
          'newPipeline',
          'totalCost',
          'marketingPipelineROI',
          'marketingROI',
        ],
      },
    },
  },
  {
    questionId: 'overview-2',
    questionText: 'how much {placeHolder1} did we drive in {placeHolder2}?',
    placeHoldersTypes: {
      placeHolder1: 'kpiFocus',
      placeHolder2: 'timeframes',
    },
    defaultPlaceHoldersValues: {
      placeHolder1: 'pipeline',
      placeHolder2: {
        value: 'prev_year',
      },
    },
  },
  {
    questionId: 'overview-3',
    questionText: 'What was our funnel on {placeHolder1}?',
    placeHoldersTypes: {
      placeHolder1: 'timeframes',
    },
    defaultPlaceHoldersValues: {
      placeHolder1: {
        value: 'prev_year',
      },
    },
  },
  {
    questionId: 'overview-4',
    questionText: 'Which channels drove revenue on {placeHolder1}?',
    placeHoldersTypes: {
      placeHolder1: 'timeframes',
    },
    defaultPlaceHoldersValues: {
      placeHolder1: {
        value: 'quarter',
      },
    },
  },
];

const topChannelsResults = [
  {
    questionId: 'channels-1',
    questionText: 'How many {placeHolder1} did we drive, by {placeHolder2}, in {placeHolder3}?',
    questionType: suggestionsTypes.topChannels,
    placeHoldersTypes: {
      placeHolder1: 'kpiFocus',
      placeHolder2: 'segments',
      placeHolder3: 'timeframes',
    },
    defaultPlaceHoldersValues: {
      placeHolder1: 'funnel3',
      placeHolder2: 'channel',
      placeHolder3: {
        value: 'prev_year',
      },
    },
    widget: {
      type: widgetTypes.segmentsAnalysis,
      configuration: {
        timeFrame: {
          value: 'prev_year',
        },
        isCompareToPreviousEnabled: false,
        filters: [
          {
            data: {
              comparisonOperator: 'EQUALS',
              fieldIndex: 0,
              position: 'ANYWHERE',
              selectedOptions: [
                'direct',
                'unmapped',
              ],
              variant: 10,
            },
            kind: 'channels',
            isUiOnly: true,
          },
        ],
        attributionModel: {
          value: 'default',
          label: 'Full Journey',
          flag: 'preferencesAttributionModelsFullJourneyDefault',
          description: 'A data-driven model that takes into account the touchpoint’s timing and location, as well as the contact’s role in the journey.',
        },
        attributionCredit: false,
        conversionIndicator: null,
        defaultStageKey: 'funnel3',
        segmentsAnalysisParams: {
          firstSegment: 'channel',
          secondSegment: null,
        },
        trendBySegmentsParams: {
          firstSegment: 'channel',
          secondSegment: null,
        },
        crossFunnelToggle: 0,
        sortByColumn: {
          id: 'attributedFunnel3',
          desc: true,
        },
        filtersByColumn: {},
        isPrimarySegmentDisable: true,
        isShowStageSelector: false,
        reorderColumns: {
          funnel1: [],
        },
        inactiveColumns: {
          funnel1: [
            'touchedFunnel4',
            'attributedFunnel4',
            'funnel3ToFunnel4Velocity',
            'funnel2ToFunnel4Velocity',
            'averageSalesCycle',
            'costPerFunnel4',
            'newBookings',
            'LTV',
            'ARR',
            'MRR',
            'ARPA',
            'touchedFunnel3',
            'attributedFunnel3',
            'funnel2ToFunnel3Velocity',
            'funnel1ToFunnel3Velocity',
            'funnel3ToFunnel4ConversionRate',
            'costPerFunnel3',
            'newPipeline',
            'touchedFunnel2',
            'attributedFunnel2',
            'funnel1ToFunnel2Velocity',
            'funnel2ToFunnel3ConversionRate',
            'funnel2ToFunnel4ConversionRate',
            'costPerFunnel2',
          ],
          funnel3: [
            'touchedFunnel1',
            'touchedFunnel2',
            'touchedFunnel4',
            'attributedFunnel1',
            'attributedFunnel2',
            'attributedFunnel4',
            'funnel1ToFunnel2Velocity',
            'funnel2ToFunnel4Velocity',
            'funnel3ToFunnel4Velocity',
            'funnel1ToFunnel2ConversionRate',
            'funnel1ToFunnel3ConversionRate',
            'funnel1ToFunnel4ConversionRate',
            'funnel2ToFunnel3ConversionRate',
            'funnel2ToFunnel4ConversionRate',
            'ARPA',
            'ARR',
            'LTV',
            'MRR',
            'averageSalesCycle',
            'costPerFunnel1',
            'costPerFunnel2',
            'costPerFunnel4',
            'newBookings',
            'touchedPipeline',
          ],
        },
        tableTypeName: 'channels',
        isCalculateAdvancedMetrics: true,
      },
    },
  },
  {
    questionId: 'channels-2',
    questionText: 'How many {placeHolder1} did we drive, by {placeHolder2}, in {placeHolder3}?',
    placeHoldersTypes: {
      placeHolder1: 'kpiFocus',
      placeHolder2: 'segments',
      placeHolder3: 'timeframes',
    },
    defaultPlaceHoldersValues: {
      placeHolder1: 'pipeline',
      placeHolder2: 'channel',
      placeHolder3: {
        value: 'prev_year',
      },
    },
  },
  {
    questionId: 'channels-3',
    questionText: 'How did channel {placeHolder1} perform in {placeHolder2}?',
    placeHoldersTypes: {
      placeHolder1: 'channels',
      placeHolder2: 'timeframes',
    },
    defaultPlaceHoldersValues: {
      placeHolder1: 'advertising_searchMarketing_SEM_googleAdwords',
      placeHolder2: {
        value: 'quarter',
      },
    },
  },
];

const channelsToContentResults = [
  {
    questionId: 'channelsToContent-1',
    questionText: 'Which activities work best together in driving Opps, over the {placeHolder1}?',
    questionType: suggestionsTypes.channelsToContent,
    placeHoldersTypes: {
      placeHolder1: 'timeframes',
    },
    defaultPlaceHoldersValues: {
      placeHolder1: {
        value: 'prev_year',
      },
    },
    widget: {
      type: widgetTypes.topConversionChannelJourneys,
      configuration: {
        timeFrame: {
          value: 'prev_year',
        },
        isCompareToPreviousEnabled: false,
        filters: [
          {
            data: {
              comparisonOperator: 'EQUALS',
              fieldIndex: 0,
              position: 'ANYWHERE',
              selectedOptions: [
                'direct',
                'unmapped',
              ],
              variant: 10,
            },
            kind: 'channels',
            isUiOnly: true,
          },
        ],
        attributionModel: {
          value: 'default',
          label: 'Full Journey',
          flag: 'preferencesAttributionModelsFullJourneyDefault',
          description: 'A data-driven model that takes into account the touchpoint’s timing and location, as well as the contact’s role in the journey.',
        },
        attributionCredit: false,
        isCategoryView: false,
        conversionIndicator: 'funnel3',
        contentJourneyViewType: 'contentType',
      },
    },
  },
  {
    questionId: 'channelsToContent-2',
    questionText: 'How many {placeHolder1} did we drive, by {placeHolder2}, in {placeHolder3}?',
    placeHoldersTypes: {
      placeHolder1: 'kpiFocus',
      placeHolder2: 'segments',
      placeHolder3: 'timeframes',
    },
    defaultPlaceHoldersValues: {
      placeHolder1: 'funnel3',
      placeHolder2: 'content_channel',
      placeHolder3: {
        value: 'prev_quarter',
      },
    },
  },
  {
    questionId: 'channelsToContent-3',
    questionText: 'What was the impact of each {placeHolder1}, that belongs to the following {placeHolder2}, in {placeHolder3}?',
    placeHoldersTypes: {
      placeHolder1: 'segments',
      placeHolder2: 'segments',
      placeHolder3: 'timeframes',
    },
    defaultPlaceHoldersValues: {
      placeHolder1: 'content_channel',
      placeHolder2: 'channel',
      placeHolder3: {
        value: 'prev_quarter',
      },
    },
  },
];

const segmentShowOnlyResults = [
  {
    questionId: 'segmentShowOnlyResults-1',
    questionText: 'Which {placeHolder1} works best with {placeHolder2}, during {placeHolder3}?',
    questionType: suggestionsTypes.segmentShowOnly,
    placeHoldersTypes: {
      placeHolder1: 'segments',
      placeHolder2: 'channels',
      placeHolder3: 'timeframes',
    },
    defaultPlaceHoldersValues: {
      placeHolder1: '585f3b85-cfb7-4969-9b0e-79f4a369e2b6',
      placeHolder2: 'advertising_socialAds_linkedinAdvertising',
      placeHolder3: {
        value: 'prev_year',
      },
    },
    widget: {
      type: widgetTypes.segmentsAnalysis,
      configuration: {
        timeFrame: {
          value: 'prev_year',
        },
        isCompareToPreviousEnabled: false,
        filters: [{
          data: {
            variant: 9,
            selectedOptions: [
              'advertising_socialAds_linkedinAdvertising',
            ],
            fieldIndex: 0,
            comparisonOperator: 'EQUALS',
          },
          kind: 'channels',
          isUiOnly: true,
        }],
        attributionModel: {
          value: 'default',
          label: 'Full Journey',
          flag: 'preferencesAttributionModelsFullJourneyDefault',
          description: 'A data-driven model that takes into account the touchpoint’s timing and location, as well as the contact’s role in the journey.',
        },
        attributionCredit: false,
        conversionIndicator: null,
        defaultStageKey: 'funnel3',
        segmentsAnalysisParams: {
          firstSegment: 'channel',
          secondSegment: '585f3b85-cfb7-4969-9b0e-79f4a369e2b6',
        },
        trendBySegmentsParams: {
          firstSegment: 'channel',
          secondSegment: '585f3b85-cfb7-4969-9b0e-79f4a369e2b6',
        },
        crossFunnelToggle: 0,
        sortByColumn: {
          id: 'attributedFunnel3',
          desc: true,
        },
        filtersByColumn: {},
        isPrimarySegmentDisable: true,
        isShowStageSelector: false,
        reorderColumns: {
          funnel1: [],
        },
        inactiveColumns: {
          funnel1: [
            'touchedFunnel4',
            'attributedFunnel4',
            'funnel3ToFunnel4Velocity',
            'funnel2ToFunnel4Velocity',
            'averageSalesCycle',
            'costPerFunnel4',
            'newBookings',
            'LTV',
            'ARR',
            'MRR',
            'ARPA',
            'touchedFunnel3',
            'attributedFunnel3',
            'funnel2ToFunnel3Velocity',
            'funnel1ToFunnel3Velocity',
            'funnel3ToFunnel4ConversionRate',
            'costPerFunnel3',
            'newPipeline',
            'touchedFunnel2',
            'attributedFunnel2',
            'funnel1ToFunnel2Velocity',
            'funnel2ToFunnel3ConversionRate',
            'funnel2ToFunnel4ConversionRate',
            'costPerFunnel2',
          ],
          funnel3: [
            'touchedFunnel1',
            'touchedFunnel2',
            'touchedFunnel4',
            'attributedFunnel1',
            'attributedFunnel2',
            'attributedFunnel4',
            'funnel1ToFunnel2Velocity',
            'funnel2ToFunnel4Velocity',
            'funnel3ToFunnel4Velocity',
            'funnel1ToFunnel2ConversionRate',
            'funnel1ToFunnel3ConversionRate',
            'funnel1ToFunnel4ConversionRate',
            'funnel2ToFunnel3ConversionRate',
            'funnel2ToFunnel4ConversionRate',
            'ARPA',
            'ARR',
            'LTV',
            'MRR',
            'averageSalesCycle',
            'costPerFunnel1',
            'costPerFunnel2',
            'costPerFunnel4',
            'newBookings',
            'touchedPipeline',
          ],
        },
        tableTypeName: 'channels',
        isCalculateAdvancedMetrics: true,
      },
    },
  },
  {
    questionId: 'segmentShowOnlyResults-2',
    questionText: 'Which channels impact Opps whose {placeHolder1} in {placeHolder2}?',
    placeHoldersTypes: {
      placeHolder1: 'customFields',
      placeHolder2: 'timeframes',
    },
    defaultPlaceHoldersValues: {
      placeHolder1: {
        id: '585f3b85-cfb7-4969-9b0e-79f4a369e2b6',
        value: 'Top Finance Leadership',
      },
      placeHolder2: {
        value: 'quarter',
      },
    },
  },
  {
    questionId: 'segmentShowOnlyResults-3',
    questionText: 'How many {placeHolder2} whose {placeHolder1} did we generate in {placeHolder3}?',
    placeHoldersTypes: {
      placeHolder1: 'customFields',
      placeHolder2: 'funnels',
      placeHolder3: 'timeframes',
    },
    defaultPlaceHoldersValues: {
      placeHolder3: {
        value: 'quarter',
      },
      placeHolder1: {
        id: '44117631-15f4-4261-a3b6-6d824df315bc',
        value: 'Enterprise',
      },
      placeHolder2: 'funnel3',
    },
  },
];

export const suggestionsResultsByType = {
  [suggestionsTypes.overviewMetrics]: overviewMetricsResults,
  [suggestionsTypes.topChannels]: topChannelsResults,
  [suggestionsTypes.channelsToContent]: channelsToContentResults,
  [suggestionsTypes.segmentShowOnly]: segmentShowOnlyResults,
  [suggestionsTypes.default]: [
    overviewMetricsResults[0],
    topChannelsResults[0],
    segmentShowOnlyResults[0],
  ],
};

export const defaultSuggestionsBox = [
  {
    id: 1,
    content: (
      <>
        {'How did channel '}
        <b>PPC - Google Ads</b>
        {' perform in '}
        <b>This quarter</b>
        ?
      </>
    ),
  },
  {
    id: 2,
    content: (
      <>
        {'What was our performance on '}
        <b>Previous year</b>
        ?
      </>
    ),
  },
  {
    id: 3,
    content: (
      <>
        {'What was our funnel on '}
        <b>Previous year</b>
        ?
      </>
    ),
  },
];

const overviewMetricsSuggestionsFloatBox = [
  {
    id: 1,
    content: (
      <>
        {'Which marketing '}
        <b>Channels</b>
        {' are driving '}
        <b>Marketing Attributed Revenue</b>
        ?
      </>
    ),
  },
  {
    id: 2,
    content: (
      <>
        {'Which marketing '}
        <b>Channels</b>
        {' are driving '}
        <b>Opps</b>
        ?
      </>
    ),
    question: topChannelsResults[0],
  },
  {
    id: 3,
    content: (
      <>
        {'How does '}
        <b>Cost per Opp</b>
        {' trend over time?'}
      </>
    ),
  },
];

const topChannelsSuggestionsFloatBox = [
  {
    id: 1,
    content: (
      <>
        {'What was the impact of each '}
        <b>Job Title</b>
        {', with '}
        <b>LinkedIn Paid</b>
        {', during '}
        <b>Previous quarter</b>
        ?
      </>
    ),
    question: segmentShowOnlyResults[0],
  },
  {
    id: 2,
    content: (
      <>
        {'Which activities work best together in driving '}
        <b>Opps</b>
        ?
      </>
    ),
    question: channelsToContentResults[0],
  },
  {
    id: 3,
    content: (
      <>
        {'Which '}
        <b>Channels</b>
        {' are under performing in driving '}
        <b>Opps</b>
        ?
      </>
    ),
  },
];

const channelsToContentSuggestionsFloatBox = [
  {
    id: 1,
    content: (
      <>
        {'Which '}
        <b>Blog post</b>
        {' performs best with which '}
        <b>Channel</b>
        {', in '}
        <b>Previous year</b>
        ?
      </>
    ),
  },
  {
    id: 2,
    content: (
      <>
        {'What was the impact of each '}
        <b>Job Title</b>
        {', with '}
        <b>LinkedIn - Paid</b>
        {', during '}
        <b>Previous quarter</b>
        ?
      </>
    ),
    question: segmentShowOnlyResults[0],
  },
  {
    id: 3,
    content: (
      <>
        {'What was the impact of each '}
        <b>Content Type</b>
        {', with '}
        <b>LinkedIn - Paid</b>
        {', during '}
        <b>Previous quarter</b>
        ?
      </>
    ),
  },
];

const segmentShowOnlySuggestionsFloatBox = [
  {
    id: 1,
    content: (
      <>
        {'Which '}
        <b>Account Executive</b>
        {' works best with '}
        <b>LinkedIn - Paid</b>
        {', during '}
        <b>Previous quarter</b>
        ?
      </>
    ),
  },
  {
    id: 2,
    content: (
      <>
        {'Which activities work best together in driving '}
        <b>Opps</b>
        {', over the '}
        <b>Previous year</b>
        ?
      </>
    ),
    question: channelsToContentResults[0],
  },
  {
    id: 3,
    content: (
      <>
        {'Which '}
        <b>Job Title</b>
        {' works best with '}
        <b>PPC - Google Ads</b>
        {', during '}
        <b>Previous quarter</b>
        ?
      </>
    ),
  },
];

export const suggestionsFloatBoxByType = {
  [suggestionsTypes.overviewMetrics]: overviewMetricsSuggestionsFloatBox,
  [suggestionsTypes.topChannels]: topChannelsSuggestionsFloatBox,
  [suggestionsTypes.channelsToContent]: channelsToContentSuggestionsFloatBox,
  [suggestionsTypes.segmentShowOnly]: segmentShowOnlySuggestionsFloatBox,
  [suggestionsTypes.default]: defaultSuggestionsBox,
};

export function getMockSuggestionsTypeByText({ text }) {
  let suggestionsType = mockQuestions.find((question) => question.text.toLowerCase().includes(text.toLowerCase()))?.type;
  if (!suggestionsType) {
    suggestionsType = suggestionsTypes.default;
  }
  return suggestionsType;
}
