import { metricsTypes } from 'components/common/logic/enums';

export const mappedGroupBy = {
  contacts: 'people',
  companies: 'accounts',
  deals: 'deals',
};

export const mappedCRMRules = {
  ANY: 'Any Value',
};

export const funnelReportSyncMessages = {
  error: 'The SFDC report for this funnel stage is out of sync',
  success: 'This report is synced to the following SFDC report',
};

export const funnelAnalysisViewTypes = {
  volumeView: 'volumeView',
  revenueView: 'revenueView',
};

export const funnelAnalysisViewTypesLabels = {
  [funnelAnalysisViewTypes.volumeView]: 'Volume view',
  [funnelAnalysisViewTypes.revenueView]: 'Revenue view',
};

export const supportedMetricsKeys = {
  comparison: 'comparison',
  pacing: 'pacing',
  costPer: metricsTypes.costPer,
  velocity: metricsTypes.velocity,
  conversionRate: metricsTypes.conversionRate,
  avgDealSize: metricsTypes.avgDealSize,
  ROI: metricsTypes.ROI,
};

export const volumeViewMetricsOptions = [
  {
    value: supportedMetricsKeys.conversionRate,
    label: 'Conversion rate',
  }, {
    value: supportedMetricsKeys.velocity,
    label: 'Velocity',
  }, {
    value: supportedMetricsKeys.pacing,
    label: 'Pacing for',
  }, {
    value: supportedMetricsKeys.comparison,
    label: 'Compare to previous',
  }, {
    value: supportedMetricsKeys.costPer,
    label: 'Cost per',
  }];

export const revenueViewMetricsOptions = [
  {
    value: supportedMetricsKeys.comparison,
    label: 'Compare to previous',
  }, {
    value: supportedMetricsKeys.pacing,
    label: 'Pacing for',
  }, {
    value: supportedMetricsKeys.avgDealSize,
    label: 'Average deal size',
  }, {
    value: supportedMetricsKeys.ROI,
    label: 'ROI',
  }];

export const revenueViewColors = {
  topColor: '#4D91FC',
  bottomColor: '#3674d8',
};

export const funnelNextActions = {
  breakdown: 'Breakdown by',
  spotlight: 'Analyze with Spotlight',
  journeys: 'View Journeys',
};
