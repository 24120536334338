import Tooltip from 'components/controls/Tooltip';
import uniqueId from 'lodash/uniqueId';
import style from 'styles/controls/info-marker.css';
import React from 'react';
import Component from 'components/Component';
import classnames from 'classnames';

class InfoMarker extends Component {
  style = style;

  render() {
    const {
      tooltipText, containerClass, onClick, TooltipProps, place, dataTestId, overridePosition, iconClassName, content, withPortal,
    } = this.props;
    return (
      <div className={containerClass} onClick={() => onClick && onClick()} style={{ cursor: 'pointer' }} data-testid={dataTestId}>
        <Tooltip
          tip={tooltipText}
          TooltipProps={TooltipProps}
          id={`card-tooltip-${uniqueId()}`}
          place={place}
          overridePosition={overridePosition}
          content={content}
          withPortal={withPortal}
        >
          <div className={classnames(this.classes.infoMarker, iconClassName)} />
        </Tooltip>
      </div>
    );
  }
}

export default InfoMarker;
