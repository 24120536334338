import React from 'react';
import { camelCase } from 'lodash';

import StageBar from 'components/widgets/funnelAnalysis/StageBar';
import FunnelStageTooltipContent from 'components/widgets/funnelAnalysis/FunnelStageTooltipContent';
import { funnelReportSyncMessages, volumeViewMetricsOptions } from 'components/widgets/funnelAnalysis/enums';

export default function VolumeView({
  data,
  isLoaded,
  fullTimeFrame,
  selectedStages,
  maxStageValue,
  supportedMetrics,
  stagesConfigurationData,
  openCurtain,
  getStageMenuParams,
  getNavigationMenuParams,
}) {
  return (
    <div data-testid="volume-view">
      {selectedStages.map((stage, index) => {
        const currentStage = stage.value;
        const nextStage = selectedStages[index + 1]?.value;
        const skeletonWidth = 100 - (index / (selectedStages.length - 1)) * 80;

        const volumeStage = data[currentStage];
        const volumeNextStage = data[nextStage];
        const conversionRateKey = camelCase(`${currentStage} to ${nextStage} conversionRate`);
        const conversionRateValues = data[conversionRateKey];
        const velocityKey = camelCase(`${currentStage} to ${nextStage} velocity`);
        const velocityValues = data[velocityKey];
        const costPerKey = camelCase(`cost per ${currentStage}`);
        const costPerValue = data[costPerKey]?.value;

        return (
          <StageBar
            key={currentStage}
            currentStage={currentStage}
            volume={volumeStage}
            nextStage={nextStage}
            nextStageVolume={nextStage ? volumeNextStage : undefined}
            conversionRate={conversionRateValues}
            velocity={velocityValues}
            costPer={costPerValue}
            fullTimeFrame={fullTimeFrame}
            stagesConfigs={data.stagesConfigs}
            isLoaded={isLoaded}
            maxStageValue={maxStageValue}
            skeletonWidth={skeletonWidth}
            supportedMetrics={supportedMetrics}
            getStageMenuParams={() => getStageMenuParams({
              onClickOption: (selectedSegment) => openCurtain({
                selectedStage: currentStage,
                selectedSegment,
                supportedMetrics: volumeViewMetricsOptions.map((metric) => metric.value),
              }),
              currentStage,
            })}
            tooltipContent={(
              <FunnelStageTooltipContent
                funnelStageConfiguration={stagesConfigurationData[currentStage]}
                funnelStage={currentStage}
              />
            )}
            stageWarning={{
              isShow: stagesConfigurationData[currentStage]?.reportSyncData?.isSynced === false,
              message: funnelReportSyncMessages.error,
            }}
            getNavigationMenuParams={getNavigationMenuParams}
          />
        );
      })}
    </div>
  );
}
