import React, { useEffect, useState } from 'react';
import { Button } from '@infinigrow/libs';

import servicesStore from 'stores/servicesStore';
import Page from 'components/Page';
import MultiSelect from 'components/controls/MultiSelect';
import Spinner from 'components/pages/journeys/Spinner';

import integrationsStyle from 'styles/integrations/integration.css';

const styles = integrationsStyle.locals;

export default function PardotManageDataSourcePopup({
  onClickClose,
  onClickDone,
  doneButtonText = 'Done',
  cancelButtonText = 'Cancel',
}) {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const shouldDisableDoneButton = selectedOptions.length === 0;

  useEffect(() => {
    getActivityTypeOptions();
  }, []);

  async function getActivityTypeOptions() {
    setIsLoading(true);
    const { allFields, savedFields } = await servicesStore.serverCommunication.serverRequest({
      method: 'GET',
      route: 'platformFields',
      queryParams: {
        platform: 'pardot',
      },
    });
    setIsLoading(false);
    const activitiesOptions = allFields?.map((activityType) => ({ label: activityType.name, value: activityType.id }));
    setOptions(activitiesOptions);
    const savedSelectedOptions = savedFields.map((savedField) => {
      const activity = activitiesOptions.find((item) => item.value === savedField);
      return (activity);
    });
    setSelectedOptions(savedSelectedOptions);
  }

  async function onSave() {
    const selectedOptionsValues = selectedOptions.map((item) => item.value);
    let message = 'Activity Types Updated Successfully';
    let type = 'success';
    try {
      setIsLoading(true);
      const response = await servicesStore.serverCommunication.serverRequest({
        method: 'PUT',
        route: 'platformFields',
        queryParams: {
          platform: 'pardot',
        },
        body: {
          platformFieldNames: selectedOptionsValues,
        },
      });

      if (response.error) {
        servicesStore.logger.error('Failed to save activity types', response.error);
        message = 'Failed to update activity types';
        type = 'error';
      }
    } catch (error) {
      servicesStore.logger.error('Failed to save activity types', error);
      message = 'Failed to update activity types';
      type = 'error';
    }
    setIsLoading(false);
    onClickDone({ toastObj: { type, message } });
  }

  return (
    <>
      <Page popup width={650}>
        <div className={styles.headTitle}>Pardot Email Settings</div>
        <div className={styles.headSubTitle}>
          Select the types of Visitor Activity you want to pull into InfiniGrow, e.g.
          {' '}
          <b>Sent</b>
          ,
          {' '}
          <b>Open</b>
          {' '}
          or
          {' '}
          <b>Click</b>
          .
          <br />
          These values will be used to map channels, campaigns and content.
          <br />
          Note that the new fields you add will be available after the next CRM update.
        </div>
        <MultiSelect
          select={{ name: 'Visitor Activity Types', options }}
          selected={selectedOptions}
          onChange={(selections) => setSelectedOptions(selections)}
          label="Visitor Activity Types"
          style={{ width: '100%', paddingBottom: '20px' }}
          disabled={isLoading}
        />
        <div className={styles.popupFooter}>
          <div className={styles.footerLeft}>
            <Button
              type="secondaryBlue"
              style={{ width: '100px' }}
              onClick={onClickClose}
            >
              {cancelButtonText}
            </Button>
          </div>
          <div className={styles.footerRight}>
            {isLoading
              ? <Spinner />
              : (
                <Button
                  type="primaryBlue"
                  style={{ width: '100px' }}
                  onClick={() => onSave()}
                  disabled={isLoading || shouldDisableDoneButton}
                >
                  {doneButtonText}
                </Button>
              )}
          </div>
        </div>
      </Page>
    </>
  );
}
