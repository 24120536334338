import React, { useState } from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';

import useStyles from 'hooks/useStyles';

import MenuButton from 'components/common/MenuButton';
import Popup from 'components/Popup';
import CreateNewBusinessUnitPopup from 'components/CreateNewBusinessUnitPopup';
import EditBusinessUnitNickname from 'components/EditBusinessUnitNickname';

import userStore from 'stores/userStore';
import attributionStore from 'stores/attributionStore';
import analysisStore from 'stores/analysisStore';
import forecastStore from 'stores/forecastStore';
import planStore from 'stores/plan/planStore';
import reportsStore from 'stores/reports/reportsStore';
import filterStore from 'stores/filterStore';

import { filterKinds } from 'components/utils/filters/logic';
import { nonActiveFunctionalitiesKeysAccordingUserPermissions } from 'enums';

import style from 'styles/navigationMenu/regionsMenu.css';
import headerBarStyle from 'styles/navigationMenu/headerBar.css';

const styles = style.locals || {};
const headerBarStyles = headerBarStyle.locals || {};

function RegionsMenu({
  createUserMonthPlan,
  updateParentState,
  getUserMonthPlan,
  userRegionsNicknames,
  userMonthPlan,
  getRegionNickname,
  updateUserAccount,
  userPermissions,
}) {
  useStyles([style, headerBarStyle]);

  const [isShowPopup, setIsShowPopup] = useState(false);
  const [editRegionNickname, setEditRegionNickname] = useState(null);
  const [isCreateNewVisible, setIsCreateNewVisible] = useState(false);

  async function changeRegion(regionNickname) {
    const regionValue = userStore.getRegionValue({ regionNickname });
    localStorage.setItem('region', regionValue);
    analysisStore.restIsLoadedWidgetIndication();
    updateParentState({ regionLoad: true, unsaved: false });

    await Promise.all([getUserMonthPlan(regionValue), planStore.getCampaignsAndChannelsCostsBetweenDates({ region: regionValue })]);
    await Promise.all([userStore.getUserMetrics(), planStore.getAllCampaignTags(), userStore.getCommonSegments()]);

    if (reportsStore.selectedReport.value) {
      reportsStore.getWidgetsDataForSelectedReport({ reportId: reportsStore.selectedReport.value });
    }

    attributionStore.setMetricOptions();
    filterStore.getUserFiltersData();
    updateParentState({ regionLoad: false, unsaved: false });
    attributionStore.resetAppliedQuickFiltersAccordingFilterKind({ filterKindsToReset: [filterKinds.FUNNEL_STAGES] });

    await forecastStore.getExplainableForecastData();

    setIsShowPopup(false);
  }

  function getIsShowRegionTitle() {
    return !isEmpty(userRegionsNicknames) && userRegionsNicknames.length > 1;
  }

  const isShowRegionTitle = getIsShowRegionTitle();
  const shouldShowCreateNewRegion = !userPermissions?.nonActiveFunctionalities?.includes(nonActiveFunctionalitiesKeysAccordingUserPermissions.createNewRegion);

  return (
    <div className={headerBarStyles.relative}>
      <MenuButton
        onClick={() => setIsShowPopup(!isShowPopup)}
        rightIconRerender={(
          <div className={headerBarStyles.buttonIcon} data-icon="headerBar:regions" />
              )}
        className={headerBarStyles.menuButton}
        withArrowIndication={false}
        title={isShowRegionTitle ? getRegionNickname({ region: userMonthPlan?.region }) : null}
        dataTestId="regions-menu-button"
      />
      <Popup
        className={styles.dropdownPopup}
        hidden={!isShowPopup}
        onClose={() => setIsShowPopup(false)}
      >
        {shouldShowCreateNewRegion ? (
          <div
            className={classnames(styles.optionTagRow, styles.createNewRow)}
            onClick={() => setIsCreateNewVisible(true)}
          >
            + Create new
          </div>
        ) : null}

        {userRegionsNicknames.map((regionNickname) => (
          <div
            className={styles.optionTagRow}
            key={regionNickname}
          >
            <div
              className={styles.optionTagName}
              onClick={() => changeRegion(regionNickname)}
            >
              {regionNickname}
            </div>
            {shouldShowCreateNewRegion ? (
              <div
                className={styles.editButton}
                onClick={() => setEditRegionNickname(regionNickname)}
              />
            ) : null}
          </div>
        ))}
      </Popup>

      <CreateNewBusinessUnitPopup
        hidden={!isCreateNewVisible}
        onClose={(region) => {
          setIsCreateNewVisible(false);
          if (region) {
            changeRegion(region);
          }
        }}
        createUserMonthPlan={createUserMonthPlan}
        updateUserAccount={updateUserAccount}
      />
      {editRegionNickname ? (
        <EditBusinessUnitNickname
          regionNickname={editRegionNickname}
          onClose={() => setEditRegionNickname(null)}
        />
      ) : null}
    </div>
  );
}

export default inject(
  ({
    userStore: {
      userMonthPlan,
      userRegionsNicknames,
      getRegionNickname,
      userPermissions,
    },
  }) => ({
    userMonthPlan,
    userRegionsNicknames,
    getRegionNickname,
    userPermissions,
  }),
  observer
)(RegionsMenu);
