export const widgetHeaderConfigKeys = {
  filters: 'filters',
  timeframe: 'timeFrame',
  attributionModel: 'attributionModel',
  conversionIndicator: 'conversionIndicator',
  isCompareToPreviousEnabled: 'isCompareToPreviousEnabled',
};

export const upliftRangeLimit = { min: -10000, max: 10000 };
export const upliftLabel = 'Uplift';
export const upliftNoValueTooltip = 'Uplift isn’t statistically significant, since the sample size didn’t pass our thresholds';

export const metricsTypes = {
  attributed: 'attributed',
  touched: 'touched',
  conversionRate: 'conversionRate',
  velocity: 'velocity',
  costPer: 'costPer',
  revenue: 'revenue',
  avgDealSize: 'avgDealSize',
  ROI: 'ROI',
  ARPA: 'ARPA',
  cost: 'cost',
  funnelStage: 'funnelStage',
  averageSalesCycle: 'averageSalesCycle',
  efficiency: 'efficiency',
  LTV: 'LTV',
  costPerFunnelStage: 'costPerFunnelStage',
};

export const costMetrics = ['LTV', 'pipeline', 'newPipeline', 'revenue', 'newBookings', 'MRR', 'ARR', 'touchedRevenue', 'touchedPipeline', 'cost', 'estimatedCost'];

export const mappingMetricsKeys = {
  newBookings: 'revenue',
  newPipeline: 'pipeline',
};

export const columnsBlackList = [
  'marketingPipelineROI',
  'marketingROI',
  'marketingAttributedRevenue',
  'marketingAttributedPipeline',
  'CAC',
  'churnRate',
  'webVisitsToFunnel1Velocity',
  'pageViewsToFunnel1Velocity',
  'ARR',
  'MRR',
  'avgDealSize',
  'pipelineAvgDealSize',
];

export const nonCumulativeMetricTypes = [
  metricsTypes.conversionRate,
  metricsTypes.velocity,
  metricsTypes.ARPA,
  metricsTypes.LTV,
  metricsTypes.ROI,
  metricsTypes.efficiency,
];

export const nonUpDirectionMetricTypes = [
  metricsTypes.velocity,
  metricsTypes.averageSalesCycle,
  metricsTypes.efficiency,
  metricsTypes.costPerFunnelStage,
];

export const segmentsKeys = {
  firstSegment: 'firstSegment',
  secondSegment: 'secondSegment',
};

export const metricTypeToLabelMapping = {
  conversionRate: 'CR',
  velocity: 'Velocity',
  avgDealSize: 'Avg. Deal Size',
};
