import React from 'react';
import classNames from 'classnames';
import { Button } from '@infinigrow/libs';

import useWindowDimensions from 'components/utils/getWindowDimensions';
import useStyles from 'hooks/useStyles';

import style from 'styles/analyze/emptyStateWithImage.css';

const styles = style.locals || {};

export default function EmptyStateWithImage({
  title,
  subTitle,
  buttonName,
  onClick,
  imageClassName,
  className,
  isContentCentered,
  height,
}) {
  useStyles([style]);

  const { height: screenHeight } = useWindowDimensions();

  const popupHeight = height || `${screenHeight - 600}px`;

  return (
    <div className={classNames(styles.wrapper, isContentCentered && styles.contentCentered, className)} style={{ height: popupHeight }}>
      <div className={classNames(styles.half, styles.leftGroup)}>
        <h1>{title}</h1>
        {subTitle ? (
          <div className={styles.subTitle}>{subTitle}</div>
        ) : null}
        {onClick && buttonName ? (
          <Button
            type="primaryBlue"
            onClick={() => onClick()}
          >
            {buttonName}
          </Button>
        ) : null}
      </div>
      <div className={classNames(styles.half, styles.emptyStateImage, imageClassName)} />
    </div>
  );
}
