import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classnames from 'classnames';
import { Checkbox } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import Popup from 'components/pages/plan/Popup';
import MenuButton from 'components/common/MenuButton';
import Dropdown from 'components/controls/Dropdown';
import SearchInput from 'components/controls/SearchInput';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';

import useOnClickOutside from 'components/pages/users/useOnClickOutside';

import {
  handleSelectAll,
  getSelectedPreset,
  getFilteredOptions,
  handleSaveSelected,
  handleSelectOption,
  getIsAllOptionsSelected,
  getPresetDropdownOptions,
} from 'components/controls/logic/CheckListWithPresets';

import tablePopup from 'styles/plan/table-popup.css';
import attributionStyle from 'styles/analyze/analyze-attribution-table.css';
import checkListWithPresetsStyles from 'styles/controls/checkListWithPresets.css';

const styles = checkListWithPresetsStyles.locals || {};
const attributionStyles = attributionStyle.locals || {};
const tablePopupStyles = tablePopup.locals || {};

export default function CheckListWithPresets({
  options,
  presets,
  saveText = 'Save',
  cancelText = 'Cancel',
  isDisabled,
  presetLabel = '',
  isCanReorder = false,
  noResultsText = 'Nothing Found',
  checkListTitle,
  selectedOptions = [],
  searchPlaceholder = 'Search...',
  setSelectedOptions = () => {},
  istSelectOptionsAsLabel,
  checkListToggleLabel = 'Select...',
  checkListToggleLabelPrefix,
  classNamePopupToggle,
  onDragEnd,
  dropdownContainerClassName,
  isShowError,
  popupOptionsClassName,
}) {
  useStyles([
    checkListWithPresetsStyles,
    attributionStyle,
    tablePopup,
  ]);

  const popupRef = useRef();
  const searchRef = useRef();
  const containerRef = useRef();
  const [searchValue, setSearchValue] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedOptionsState, setSelectedOptionsState] = useState(selectedOptions);

  useEffect(() => {
    setSelectedOptionsState(selectedOptions);
  }, [selectedOptions]);

  const selectedPreset = useMemo(() => getSelectedPreset({ presets, selectedOptions }), [
    presets,
    selectedOptions,
  ]);

  const presetsDropdownOptions = useMemo(() => getPresetDropdownOptions({
    presets,
    selectedPreset,
    selectedOptionsState,
  }), [
    presets,
    selectedPreset,
    selectedOptionsState,
  ]);

  const filteredOptions = useMemo(() => getFilteredOptions({ options, searchValue }), [
    options,
    searchValue,
  ]);

  const isAllOptionsSelected = useMemo(() => getIsAllOptionsSelected({ filteredOptions, selectedOptionsState }), [
    filteredOptions,
    selectedOptionsState,
  ]);

  function closePopup() {
    if (popupRef.current) {
      popupRef.current.close();
      setIsPopupOpen(false);
    }
  }

  function togglePopup() {
    if (popupRef.current) {
      popupRef.current.toggle();
      if (isPopupOpen) {
        resetState();
      }
      setIsPopupOpen((prevIsOpen) => !prevIsOpen);
    }
  }

  function resetSearchQuery() {
    setSearchValue('');
    if (searchRef.current) {
      searchRef.current.handleSearchQueryClear();
    }
  }

  function resetState() {
    resetSearchQuery();
    setSelectedOptionsState(selectedOptions);
  }

  useOnClickOutside(
    containerRef,
    () => {
      resetState();
      closePopup();
    }
  );

  function getSelectOptionsAsLabel() {
    if (selectedOptionsState.length === 0) {
      return checkListToggleLabel;
    }
    const selectedLabel = selectedOptionsState
      .map((value) => options.find((option) => option.value === value)?.label)
      .filter(Boolean)
      .join(', ');

    const labelContent = (
      <>
        {checkListToggleLabelPrefix}
        {checkListToggleLabelPrefix ? (<b>{selectedLabel}</b>) : selectedLabel}
      </>
    );

    return (
      <EllipsisTooltip
        content={labelContent}
        text={labelContent}
        place="bottom"
      />
    );
  }

  const checkListDropdownTitle = istSelectOptionsAsLabel ? getSelectOptionsAsLabel() : `${checkListToggleLabelPrefix}${checkListToggleLabel}`;

  return (
    <div
      ref={containerRef}
      className={styles.checkListWithPresets}
    >
      {presetsDropdownOptions.length > 0 ? (
        <Dropdown
          options={presetsDropdownOptions}
          selectedKey={selectedPreset}
          onChange={({ presetOptions }) => {
            setSelectedOptions(presetOptions);
            setSelectedOptionsState(presetOptions);
          }}
          disabled={isDisabled}
          dropdownLabel={presetLabel}
          placeholder="Select..."
        />
      ) : null}

      <div className={classnames(styles.checkListDropdownContainer, dropdownContainerClassName)}>
        <MenuButton
          isOpen={isPopupOpen}
          onClick={() => togglePopup()}
          disabled={isDisabled}
          title={checkListDropdownTitle}
          className={classnames(styles.checkListPopupToggle, classNamePopupToggle)}
          dataTestId="popupButton"
          isShowError={isShowError}
        />

        <Popup
          ref={popupRef}
          title={checkListTitle}
          onClose={() => {
            resetState();
          }}
          primaryButton={{
            text: saveText,
            onClick: () => handleSaveSelected({
              options,
              presets,
              closePopup,
              resetSearchQuery,
              setSelectedOptions,
              selectedOptionsState,
            }),
            dataTestId: 'saveButton',
          }}
          secondaryButton={{
            text: cancelText,
            onClick: () => {
              resetState();
              closePopup();
            },
            dataTestId: 'cancelButton',
          }}
          className={classnames(styles.checkListPopup, tablePopupStyles.popup)}
        >
          <SearchInput
            ref={searchRef}
            debounce={0}
            onSearch={(newValue) => setSearchValue(newValue)}
            placeholder={searchPlaceholder}
            classes={{
              label: attributionStyles.optionsSearchLabel,
            }}
            dataTestId="searchInput"
          />

          {filteredOptions.length === 0 ? (
            <div className={attributionStyles.notingFound}>
              {noResultsText}
            </div>
          ) : (
            <div className={attributionStyles.selectAllOptions}>
              <Checkbox
                checked={isAllOptionsSelected}
                onChange={() => {
                  setSelectedOptionsState((prevSelected) => handleSelectAll({
                    prevSelected,
                    filteredOptions,
                    isAllOptionsSelected,
                  }));
                }}
                className={tablePopupStyles?.checkboxContainer}
              >
                Select all
              </Checkbox>
            </div>
          )}

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-item" isDropDisabled={!isCanReorder || !onDragEnd}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={classnames(attributionStyles.popupOptionsWrapper, popupOptionsClassName)}
                  data-testid="optionsWrapper"
                >
                  {filteredOptions.map(({
                    value, label, options: groupOptions, isDisabled: isOptionDisabled,
                  }, optionIndex) => {
                    const isGroup = !!groupOptions;

                    if (isGroup) {
                      return (
                        <div
                          key={`optionsGroup_${label}`}
                          className={styles.optionsGroup}
                        >
                          <div className={styles.groupLabel}>
                            {label}
                          </div>
                          {groupOptions.map(({ value: groupOptionValue, label: groupOptionLabel }) => (
                            <Checkbox
                              key={`group_${value}_checkbox_${groupOptionValue}`}
                              checked={selectedOptionsState.includes(groupOptionValue)}
                              onChange={() => {
                                setSelectedOptionsState((prevSelected) => handleSelectOption({
                                  prevSelected,
                                  optionValue: groupOptionValue,
                                }));
                              }}
                              className={tablePopupStyles.checkboxContainer}
                            >
                              {groupOptionLabel}
                            </Checkbox>
                          ))}
                        </div>
                      );
                    }

                    return (
                      <Draggable key={`checkbox_${value}`} draggableId={`checkbox_${value}`} index={optionIndex}>
                        {(itemProvided) => (
                          <div
                            className={classnames(
                              styles.draggableContainer,
                              tablePopupStyles.checkListItem
                            )}
                            ref={itemProvided.innerRef}
                            {...itemProvided.draggableProps}
                            {...itemProvided.dragHandleProps}
                          >
                            <div className={tablePopupStyles.dragAndDropIcon} />

                            <Checkbox
                              checked={selectedOptionsState.includes(value)}
                              onChange={() => {
                                setSelectedOptionsState((prevSelected) => handleSelectOption({
                                  optionValue: value,
                                  prevSelected,
                                }));
                              }}
                              className={tablePopupStyles.checkboxContainer}
                              disabled={isOptionDisabled}
                            >
                              {label}
                            </Checkbox>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Popup>
      </div>
    </div>
  );
}
