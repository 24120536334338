import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Button, PopupMenu } from '@infinigrow/libs';

import Component from 'components/Component';
import Loading from 'components/pages/indicators/Loading';
import Tooltip from 'components/controls/Tooltip';
import { compose } from 'components/utils/utils';

import style from 'styles/indicators/platform.css';

const enhance = compose(
  inject((stores) => {
    const {
      userStore,
    } = stores;
    const {
      userMonthPlan,
      getMetricNickname,
    } = userStore;
    return {
      userMonthPlan,
      getMetricNickname,
    };
  }),
  observer
);

class Platform extends Component {
  style = style;

  static propTypes = {
    loading: PropTypes.bool,
    connectButtonText: PropTypes.string,
    indicators: PropTypes.arrayOf(PropTypes.string),
    icon: PropTypes.string,
    hidden: PropTypes.bool,
    connected: PropTypes.any.isRequired,
    loadingData: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    open: PropTypes.func.isRequired,
    menuOptions: PropTypes.arrayOf(PropTypes.any),
    menuStyle: PropTypes.string,
  };

  static defaultProps = {
    loading: false,
    connectButtonText: 'Connect',
    indicators: [],
    icon: null,
    hidden: false,
    menuOptions: null,
    menuStyle: null,
  };

  getTooltipHtml = () => (this.props.indicators
    ? `Relevant metrics:<br/>${this.props.indicators.map((indicator) => this.props.getMetricNickname({ metric: indicator })).join('<br/>')}`
    : null);

  render() {
    const {
      connected, loadingData, expired, expiring,
    } = this.props;
    let squareText = 'Loading';
    if (expired) {
      squareText = 'Expired';
    } else if (expiring) {
      squareText = 'Expiring soon';
    } else if (connected) {
      squareText = 'Connected';
    }
    const {
      hideAction,
    } = this.props;

    return (
      <div>
        {this.props.loading ? (
          <Loading
            icon={this.props.icon}
            className={this.classes.defaultDimensions}
          />
        )
          : (
            <div
              className={this.classes.square}
              hidden={this.props.hidden}
              data-loading={loadingData && !connected ? true : null}
              data-connected={connected ? true : null}
              data-expiring={expiring && !expired ? true : null}
              data-expired={expired ? true : null}
            >
              <Tooltip
                className={this.classes.platformIcon}
                tip={this.getTooltipHtml()}
                id="platform-tooltip"
                data-icon={this.props.icon}
              />
              <div className={this.classes.platformText}>
                {this.props.title}
              </div>
              <Button
                type="primaryBlue"
                className={this.classes.connectButton}
                onClick={() => this.props.open()}
              >
                {this.props.connectButtonText}
              </Button>
              <div
                className={this.classes.footer}
                data-loading={loadingData && !connected ? true : null}
                data-connected={connected ? true : null}
                data-expiring={expiring && !expired ? true : null}
                data-expired={expired ? true : null}
              >
                <div hidden={!connected || expired} className={this.classes.checkIcon} />
                <div>
                  {squareText}
                </div>
                {
                  this.props.menuOptions ? (
                    <PopupMenu
                      options={this.props.menuOptions}
                      menuPositionClassName={this.props.menuStyle}
                    >
                      <div
                        hidden={!connected || hideAction}
                        className={this.classes.footerButton}
                      />
                    </PopupMenu>
                  )
                    : (
                      <div
                        hidden={!connected || hideAction}
                        className={this.classes.footerButton}
                        onClick={this.props.open}
                      />
                    )
                }
              </div>
            </div>
          )}
      </div>
    );
  }
}

export default enhance(Platform);
