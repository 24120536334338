import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import ReactTable, { ReactTableDefaults } from 'react-table';
import { withFixedColumnsStickyPosition } from 'react-table-hoc-fixed-columns';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import virtualizedTableHOC from 'components/controls/table/virtualizedTableHOC';
import Spinner from 'components/pages/journeys/Spinner';
import InlineColumnFilters from 'components/pages/analyze/AttribuitonTable/InlineColumnFilters';
import InfoMarker from 'components/pages/InfoMarker';
import InlineColumnController from 'components/pages/analyze/AttribuitonTable/InlineColumnController';

import { DoubleScroll } from 'components/utils/utils';

import style from 'styles/controls/table.css';
import inlineColumnFiltersStyle from 'styles/analyze/attributionTable/inlineColumnFilters.css';
import reactTableStyle from 'react-table/react-table.css';
import fixedColumnsStyle from 'react-table-hoc-fixed-columns/lib/styles.css';

export const DEFAULT_PAGE_SIZE = 20;

const ReactTableFixedColumns = withFixedColumnsStickyPosition(ReactTable);

const tableStyles = style.locals || {};
// https://reactjs.org/docs/context.html
// this particular context is used in TheadWithFooterRowComponent to determine whether this row is header or footer
const IsFooterRowContext = createContext(false);

function TheadComponent({
  children, className, headerClassName, ...props
}) {
  return (
    <ReactTableDefaults.TheadComponent {...props} className={classnames(className, headerClassName)}>
      {children}
    </ReactTableDefaults.TheadComponent>
  );
}

function TheadWithFooterRowComponent({
  children,
  className,
  headerClassName,
  headRowClassName,
  footRowClassName,
  ...props
}) {
  return (
    <ReactTableDefaults.TheadComponent {...props} className={classnames(className, headerClassName)}>
      <div className={classnames(tableStyles.headRow, headRowClassName)}>
        <IsFooterRowContext.Provider value={false}>
          {children}
        </IsFooterRowContext.Provider>
      </div>
      <div className={classnames(tableStyles.footRow, footRowClassName)}>
        <IsFooterRowContext.Provider value>
          {children}
        </IsFooterRowContext.Provider>
      </div>
    </ReactTableDefaults.TheadComponent>
  );
}

function ThComponent({ children, cellClassName, ...props }) {
  return (
    <ReactTableDefaults.ThComponent {...props}>
      <div className={classnames(tableStyles.cellContent, cellClassName)}>{children}</div>
    </ReactTableDefaults.ThComponent>
  );
}

function ThWithFooterCheckComponent({
  children, cellClassName, footer, ...props
}) {
  return (
    <ReactTableDefaults.ThComponent {...props}>
      <IsFooterRowContext.Consumer>
        {(isFooter) => (isFooter
          ? (
            <div className={classnames(tableStyles.cellContent, cellClassName)}>{footer}</div>
          )
          : (
            <div className={classnames(tableStyles.cellContent, cellClassName)}>{children}</div>
          ))}
      </IsFooterRowContext.Consumer>
    </ReactTableDefaults.ThComponent>
  );
}

function TdComponent({ children, cellClassName, ...props }) {
  const isNumber = (value) => typeof value === 'number';
  const isString = (value) => typeof value === 'string';
  const decimalNumRegex = /^\d+(\.\d+)?$/;

  const checkAndFormatChild = (child) => {
    if (isNumber(child)) { return child.toLocaleString(); }
    if (isString(child)) {
      if (decimalNumRegex.test(child)) { return Number(child).toLocaleString(); }
    }
    return child;
  };

  let formattedChild;
  if (Array.isArray(children?.props?.children?.props?.children)) {
    formattedChild = checkAndFormatChild(children?.props?.children?.props?.children[0]);
  } else if (Array.isArray(children?.props?.children) && (typeof children?.props?.children[1] === 'string')) {
    formattedChild = children;
  } else {
    formattedChild = checkAndFormatChild(children?.props?.children || children);
  }

  return (
    <ReactTableDefaults.TdComponent {...props}>
      <div className={classnames(tableStyles.cellContent, cellClassName, children?.props?.['data-styleOverride'])}>{formattedChild}</div>
    </ReactTableDefaults.TdComponent>
  );
}

export function PaginationButton({ onClick, isAllLoaded }) {
  return !isAllLoaded && (
  <Button
    type="primaryBlue"
    onClick={onClick}
    className={tableStyles.paginationButton}
  >
    Show more
  </Button>
  );
}

function CustomPaginationComponent({
  data, onClick, pageSize, dataLength, infiniteScroll, alwaysShowPagination,
}) {
  const length = dataLength || data.length;
  const isAllLoaded = length <= pageSize || infiniteScroll;

  if (isAllLoaded && !alwaysShowPagination) {
    return null;
  }
  return <PaginationButton onClick={onClick} isAllLoaded={isAllLoaded} />;
}

class Table extends Component {
  style = style;

  styles = [style, reactTableStyle, fixedColumnsStyle, inlineColumnFiltersStyle];

  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      header: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.func]),
      // (value, rowData) => PropTypes.node,
      cell: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.string]),
      footer: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      // adds right border to the column ('divider')
      divider: PropTypes.bool,
      fixed: PropTypes.oneOf(['left', 'right']),
      sortable: PropTypes.bool,
      minWidth: PropTypes.number,
      className: PropTypes.string,
      headerClassName: PropTypes.string,
      footerClassName: PropTypes.string,
    })).isRequired,
    data: PropTypes.arrayOf(PropTypes.object),
    defaultMinWidth: PropTypes.number, // default min width of column
    showFootRowOnHeader: PropTypes.bool,
    striped: PropTypes.bool,
    showPagination: PropTypes.bool,
    doubleScroll: PropTypes.bool,
    defaultPageSize: PropTypes.number,
    showMoreStep: PropTypes.number,
    infiniteScrollSelector: PropTypes.string,
    withFixedColumns: PropTypes.bool,
  };

  static defaultProps = {
    defaultMinWidth: 140,
    data: [],
    defaultPageSize: DEFAULT_PAGE_SIZE,
    showMoreStep: 15,
    showPagination: false,
    doubleScroll: false,
    showFootRowOnHeader: false,
    striped: false,
    infiniteScrollSelector: 'div > div > div.rt-table',
    withFixedColumns: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      pageSize: this.props.pageSize || this.pageSize(),
    };
    this.reactTable = React.createRef();
  }

  componentDidMount() {
    const { doubleScroll, infiniteScroll, infiniteScrollSelector } = this.props;
    // eslint-disable-next-line react/no-find-dom-node
    const node = ReactDOM.findDOMNode(this);

    this.element = document.querySelector('div > div > div.rt-table');
    if (this.element && doubleScroll) {
      this.doubleScroll = DoubleScroll(this.element);
    }
    if (infiniteScroll) {
      this.scrollNode = node.querySelector(infiniteScrollSelector);
      if (this.scrollNode) {
        this.scrollNode.addEventListener('scroll', this.handleTableScroll);
      }
    }
  }

  componentWillUnmount() {
    const { infiniteScroll } = this.props;
    if (infiniteScroll && this.scrollNode) {
      this.scrollNode.removeEventListener('scroll', this.handleTableScroll);
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.pageSize && this.props.data.length && prevProps.data.length !== this.props.data.length) {
      const pageSize = this.pageSize();
      const { infiniteScroll, infiniteScrollSelector } = this.props;
      // eslint-disable-next-line react/no-find-dom-node
      const node = ReactDOM.findDOMNode(this);

      if (infiniteScroll) {
        this.scrollNode = node.querySelector(infiniteScrollSelector);
        if (this.scrollNode) {
          this.scrollNode.removeEventListener('scroll', this.handleTableScroll);
          this.scrollNode.addEventListener('scroll', this.handleTableScroll);
        }
      }
      this.setState({ pageSize });
    }
    const { activeColsLen, doubleScroll } = this.props;
    if ((prevProps.activeColsLen !== activeColsLen && doubleScroll) && this.doubleScroll) {
      this.doubleScroll.refresh();
    }
  }

  makeColumns() {
    const { columns, defaultMinWidth } = this.props;
    return columns.map(({
      id,
      header,
      cell,
      footer,
      tooltip,
      // https://github.com/react-tools/react-table#accessors
      // the default behavior for the accessor is to return the whole data item
      accessor = (item) => item,
      divider,
      // by default columns aren't sortable, but you can change it passing `sortable: true`
      sortable = false,
      minWidth = defaultMinWidth,
      className,
      headerClassName,
      footerClassName,
      inlineFiltersParams,
      inlineColumnControllerParams,
      ...other
    }, index) => {
      const isLastColumn = columns && columns.length - 1 === index && columns?.length > 1;
      return ({
        id,
        accessor: typeof cell === 'string' ? cell : accessor,
        sortable,
        minWidth,
        Cell: typeof cell === 'function' ? (row) => cell(row.value, row) : cell,
        Header: typeof header === 'function' ? header : (
          <div className={tableStyles.headerWrapper}>
            {header}
            {tooltip ? (
              <InfoMarker tooltipText={tooltip} />
            ) : null}
            {inlineFiltersParams ? (
              <InlineColumnFilters {...inlineFiltersParams} isLastColumn={isLastColumn} />
            ) : null}
            {inlineColumnControllerParams ? (
              <InlineColumnController {...inlineColumnControllerParams} isLastColumn={isLastColumn} />
            ) : null}
          </div>
        ),
        Footer: footer && <>{footer}</>,
        getHeaderProps: () => ({ footer }),
        className: classnames(className, divider && tableStyles.divider),
        headerClassName: classnames(headerClassName, divider && tableStyles.divider),
        footerClassName: classnames(footerClassName, divider && tableStyles.divider),
        ...other,
      });
    });
  }

  getTheadSortingProp(state, rowInfo, column, instance) {
    if (this.props.setSortByColumn) {
      return {
        onClick: async () => {
          if (column.sortable !== false) {
            await instance.sortColumn(column);
            this.props.setSortByColumn({
              sortBy: column.id,
            });
          }
        },
      };
    }

    if (this.props.handleSortChange) {
      return {
        onClick: (e, handleOriginal) => {
          if (e.target) {
            this.props.handleSortChange(e.target);
          }
          if (handleOriginal) {
            handleOriginal();
          }
        },
      };
    } else { return {}; }
  }

  pageSize = () => (this.props.showPagination && (this.props.data.length > this.props.defaultPageSize)
    ? this.props.defaultPageSize
    : this.props.data.length);

  loadMoreContent = () => {
    const { showMoreStep, data, handleShowMoreClick } = this.props;

    if (handleShowMoreClick) {
      handleShowMoreClick(showMoreStep, data);
    } else {
      this.setState((prevState) => {
        const isLastPage = (data.length - prevState.pageSize) < showMoreStep;
        return {
          pageSize: isLastPage ? data.length : prevState.pageSize + showMoreStep,
        };
      });
    }
  };

  handleTableScroll = (e) => {
    const { data, hasMoreData, scrollTriggerOffset } = this.props;
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    const scrollTriggerLoadOffset = scrollTriggerOffset || 50;
    const scrolledToTheBottom = (scrollHeight - scrollTop) <= (clientHeight + scrollTriggerLoadOffset);

    const hasMoreDataToLoad = this.state.pageSize < data.length || hasMoreData;
    if (scrolledToTheBottom && hasMoreDataToLoad) {
      this.loadMoreContent();
    }
  };

  render() {
    const {
      cellClassName,
      className,
      columns,
      data,
      minRows,
      defaultMinWidth,
      rowGroupClassName,
      footRowClassName,
      headerClassName,
      footerClassName,
      headRowClassName,
      headGroupClassName,
      noPadding,
      onRowClick,
      rowClassName,
      showFootRowOnHeader,
      tableClassName,
      exportMode,
      striped,
      handleShowMoreClick,
      isVirtual,
      innerRef,
      withFixedColumns,
      ...other
    } = this.props;
    const { pageSize: controlledPageSize } = this.state;

    const refProp = {};
    let TableComponent;

    if (isVirtual) {
      TableComponent = virtualizedTableHOC(ReactTable);
      refProp.ref = innerRef;
    } else if (withFixedColumns) {
      TableComponent = ReactTableFixedColumns;
      refProp.innerRef = innerRef;
    } else {
      TableComponent = ReactTable;
      refProp.ref = innerRef;
    }

    if (this.props.isLoadingIntialData) {
      return (<div className={classnames(tableStyles.loadingWrapper)}><Spinner /></div>);
    }

    return (
      <div className={classnames(
        tableStyles.wrap,
        noPadding && tableStyles.noPadding,
        className
      )}
      >
        <TableComponent
          {...refProp}
          minRows={minRows}
          ThComponent={showFootRowOnHeader ? ThWithFooterCheckComponent : ThComponent}
          TdComponent={TdComponent}
          TheadComponent={showFootRowOnHeader ? TheadWithFooterRowComponent : TheadComponent}
          PaginationComponent={CustomPaginationComponent}
          NoDataComponent={() => null} // turn it off for now
          pageSize={controlledPageSize}
          resizable
          className={classnames(tableStyles.table)}
          data={data}
          columns={this.makeColumns()}
          getResizerProps={() => ({
            className: tableStyles.resizer,
          })}
          getTableProps={() => ({
            className: tableClassName,
          })}
          getTheadGroupProps={() => ({ className: classnames(tableStyles.headGroup, headGroupClassName) })}
          getTheadGroupTrProps={() => ({ className: classnames(tableStyles.headRow, headRowClassName) })}
          getTheadGroupThProps={() => ({
            className: tableStyles.cell,
            cellClassName,
          })}
          getTheadProps={() => (showFootRowOnHeader ? {
            headerClassName,
            headRowClassName,
            footRowClassName,
          } : {
            headerClassName,
          })}
          getTheadTrProps={() => ({
            className: classnames(tableStyles.headRow, headRowClassName),
          })}
          getTheadThProps={(state, rowInfo, column, instance) => ({
            className: tableStyles.cell,
            cellClassName,
            ...this.getTheadSortingProp(state, rowInfo, column, instance),
          })}
          getTrProps={(state, rowInfo = {}) => ({
            className: classnames(
              tableStyles.tableRow,
              rowClassName,
              onRowClick && tableStyles.clickable,
              striped && rowInfo.index % 2 === 0 && tableStyles.striped
            ),
            onClick: (event, handleOriginal) => {
              event.persist();
              if (onRowClick) {
                onRowClick(rowInfo.original, rowInfo.index, event);
              }

              if (handleOriginal) {
                handleOriginal();
              }
            },
          })}
          getTrGroupProps={() => ({ className: classnames(tableStyles.tableRowGroup, rowGroupClassName) })}
          getTdProps={() => ({
            className: tableStyles.cell,
            cellClassName,
          })}
          getTfootProps={() => ({ className: classnames(tableStyles.foot, footerClassName) })}
          getTfootTrProps={() => ({ className: classnames(tableStyles.footRow, footRowClassName) })}
          getTfootTdProps={() => ({
            className: tableStyles.cell,
            cellClassName,
          })}
          getTbodyProps={() => ({
            className: classnames(tableStyles.body, this.props.bodyCustomClass),
          })}
          getPaginationProps={() => ({
            onClick: this.loadMoreContent,
          })}
          {...other}
        />
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <Table
    innerRef={ref}
    {...props}
  />
));

export function SmallTable({
  headRowClassName, headerClassName, rowClassName, cellClassName, ...props
}) {
  return (
    <Table
      headerClassName={classnames(tableStyles.smallHeader, headerClassName)}
      headRowClassName={classnames(tableStyles.smallHeadRow, headRowClassName)}
      rowClassName={classnames(tableStyles.smallRow, rowClassName)}
      cellClassName={classnames(tableStyles.smallCell, cellClassName)}
      defaultMinWidth={60}
      {...props}
    />
  );
}
