import { cloneDeep } from 'lodash';

export const getFilteredOptionsForRequest = (journeyFilterOptions) => {
  const updatedFilterOptions = [];
  for (const categoryOptions of journeyFilterOptions) {
    const values = [];
    for (const value of categoryOptions.values) {
      if (value.isSelected) {
        values.push(value.value);
      }
    }
    if (values.length) {
      updatedFilterOptions.push({ ...categoryOptions, values });
    }
  }
  return updatedFilterOptions;
};

export function splitEventsByDate({
  events,
  pendingEvents,
  dateToSplit,
  hasMoreToLoad,
  keyToCompare = 'changeDate',
}) {
  if (events.length === 0 && pendingEvents.length === 0) {
    return { eventsAfterSplittingDate: [], eventsBeforeSplittingDate: [] };
  }

  const updatedPendingTransitions = cloneDeep(pendingEvents);
  const allEvents = [...updatedPendingTransitions, ...events];

  if (!dateToSplit || !hasMoreToLoad) {
    return {
      eventsAfterSplittingDate: allEvents,
      eventsBeforeSplittingDate: [],
    };
  }

  const eventsAfterSplittingDate = allEvents.filter(
    (transition) => new Date(transition[keyToCompare]) >= new Date(dateToSplit)
  );

  const eventsBeforeSplittingDate = allEvents.filter(
    (transition) => new Date(transition[keyToCompare]) < new Date(dateToSplit)
  );

  return { eventsAfterSplittingDate, eventsBeforeSplittingDate };
}
