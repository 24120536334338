import React from 'react';
import { inject, observer } from 'mobx-react';
import { ToastContainer, Slide } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';

import Component from 'components/Component';
import Dropdown from 'components/controls/Dropdown';
import MenuButton from 'components/common/MenuButton';
import Popup from 'components/Popup';
import TimeframeDropdown from 'components/common/TimeframeDropdown';
import Toggle from 'components/controls/Toggle';
import FiltersContainerBar from 'components/pages/users/Filters/FiltersContainerBar';
import FiltersIconWithIndication from 'components/pages/users/Filters/FiltersIconWithIndication';
import Spinner from 'components/pages/journeys/Spinner';

import { compose } from 'components/utils/utils';
import { onboardingTagsIds } from 'components/pages/analyze/enums';
import { tabsPaths } from 'enums';

import filterContainerStyle from 'styles/users/filters.css';
import checkboxStyle from 'styles/controls/multi-check-select.css';

const enhance = compose(
  inject(({
    analyzeStore,
    attributionStore,
    filterStore,
  }) => {
    const {
      isFilterDataLoaded,
      isLoadingQuickFiltersServerRequest,
      isLoadingAIRecommendationServerRequest,
    } = filterStore;
    const {
      updateTabConfig,
      configPerTab: {
        channels: {
          isCategoryView = false,
        },
        content: {
          isContentPages = false,
        },
      },
    } = analyzeStore;
    const {
      previousTimeframe,
      setPreviousCheckbox,
      numberOfFilters,
      timeFrame,
      metricsOptions,
      conversionIndicator,
      setConversionIndicator,
      setTimeFrame,
      timeFrameParams,
    } = attributionStore;

    return {
      timeFrame,
      isCategoryView,
      updateTabConfig,
      isContentPages,
      previousTimeframe,
      setPreviousCheckbox,
      isFilterDataLoaded,
      isLoadingQuickFiltersServerRequest,
      isLoadingAIRecommendationServerRequest,
      numberOfFilters,
      metricsOptions,
      conversionIndicator,
      setConversionIndicator,
      setTimeFrame,
      timeFrameParams,
    };
  }),
  observer
);

const styles = filterContainerStyle.locals;

class FiltersContainer extends Component {
  styles = [checkboxStyle];

  style = filterContainerStyle;

  constructor(props) {
    super(props);

    this.state = {
      isOpenFilterSubMenu: false,
    };
  }

  componentDidMount() {
    injectStyle();
  }

  render() {
    const {
      isCategoryView,
      updateTabConfig,
      isContentPages,
      numberOfFilters,
      isFilterDataLoaded,
      isLoadingQuickFiltersServerRequest,
      isLoadingAIRecommendationServerRequest,
      timeFrame,
      metricsOptions,
      conversionIndicator,
      setConversionIndicator,
      previousTimeframe,
      setPreviousCheckbox,
      setTimeFrame,
      timeFrameParams,
    } = this.props;

    const {
      isOpenFilterSubMenu,
    } = this.state;

    const isUsersPage = this.props.path === tabsPaths.analyzeJourneys;
    const isOverviewPage = this.props.path === tabsPaths.analyzeOverview;
    const isHomePage = this.props.path === tabsPaths.home;
    const isWhatIfPage = this.props.path === tabsPaths.whatIf;
    const isOptimalJourneyPage = this.props.path === tabsPaths.optimalJourney;

    if (isWhatIfPage || isOptimalJourneyPage) {
      return null;
    }

    const gettoggleOptions = () => {
      switch (this.props.path) {
        case '/analyze/channels':
          return (
            <Toggle
              options={[
                { text: 'Channel', value: false },
                { text: 'Category', value: true },
              ]}
              selectedValue={isCategoryView}
              onClick={(value) => updateTabConfig({ tabName: 'channels', configKey: 'isCategoryView', configValue: value })}
            />
          );
        case '/analyze/content':
          return (
            <Toggle
              options={[
                { text: 'Content', value: true },
                { text: 'Content Type', value: false },
              ]}
              selectedValue={isContentPages}
              onClick={(value) => updateTabConfig({ tabName: 'content', configKey: 'isContentPages', configValue: value })}
            />
          );
        default:
          return null;
      }
    };

    return (
      <div className={styles.filtersContainerRap}>
        {!isFilterDataLoaded || isLoadingQuickFiltersServerRequest || isLoadingAIRecommendationServerRequest ? (
          <Spinner />
        ) : (
          <MenuButton
            onClick={() => this.setState({ isOpenFilterSubMenu: !isOpenFilterSubMenu })}
            rightIconRerender={<FiltersIconWithIndication activeNumber={numberOfFilters} />}
            className={styles.filtersMenuButton}
            disabledClassName={styles.disabledFiltersMenuButton}
            withArrowIndication={false}
            disabled={!isFilterDataLoaded || isLoadingQuickFiltersServerRequest || isLoadingAIRecommendationServerRequest}
          />
        )}

        <Popup
          className={styles.filterSubMenu}
          hidden={!isOpenFilterSubMenu}
          onClose={() => this.setState({ isOpenFilterSubMenu: false })}
        >
          {isOpenFilterSubMenu && (
          <FiltersContainerBar />
          )}
        </Popup>

        <TimeframeDropdown
          onChange={(newTimeFrame) => {
            this.setState({ isOpenFilterSubMenu: false });
            setTimeFrame(newTimeFrame.value, newTimeFrame);
          }}
          timeframe={{
            ...timeFrame,
            ...timeFrameParams,
          }}
          isShowCompareToPrev
          setPreviousTimeframe={setPreviousCheckbox}
          isPreviousTimeframeEnable={previousTimeframe}
          tagId={onboardingTagsIds.headerTimeframeDropdown}
          classNamePopup={styles.timeframeDropdownPopup}
        />

        {!(isOverviewPage || isUsersPage || isHomePage) && (
        <Dropdown
          onChange={(e) => setConversionIndicator(e.value)}
          options={metricsOptions}
          selectedKey={conversionIndicator}
          controlWidth={160}
          tagId={onboardingTagsIds.headerTimeframeDropdown}
          toolTip="Choose the KPI you want to analyze. The selected metric will show up as a main metric in each widget on the Analyze tab."
        />
        )}

        {gettoggleOptions()}

        <ToastContainer
          style={{ width: '400px', fontSize: '14px' }}
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          transition={Slide}
          pauseOnHover={false}
          progress={undefined}
          theme="light"
        />
      </div>
    );
  }
}
export default enhance(FiltersContainer);
