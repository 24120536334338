exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".metaDataBox__metaDataBoxContainer-2fd{display:flex;border-radius:8px;border:1px solid var(--colorsInputsGrayLine);background-color:var(--colorsBackgroundsBackgroundWhite)}.metaDataBox__metaDataBoxContainer-2fd :last-child{border-right:revert}.metaDataBox__metaDataBoxContainer-2fd.metaDataBox__column-3nI{flex-direction:column}.metaDataBox__metaDataBoxContainer-2fd.metaDataBox__column-3nI :last-child{border-bottom:revert}.metaDataBox__metricDataBoxOption-1fM{border-right:1px solid var(--colorsInputsGrayLine)}.metaDataBox__metricDataBoxOption-1fM.metaDataBox__column-3nI{border-bottom:1px solid var(--colorsInputsGrayLine);border-right:revert}", ""]);

// exports
exports.locals = {
	"metaDataBoxContainer": "metaDataBox__metaDataBoxContainer-2fd",
	"metaDataBoxContainer": "metaDataBox__metaDataBoxContainer-2fd",
	"column": "metaDataBox__column-3nI",
	"column": "metaDataBox__column-3nI",
	"metricDataBoxOption": "metaDataBox__metricDataBoxOption-1fM",
	"metricDataBoxOption": "metaDataBox__metricDataBoxOption-1fM"
};