export const inviteMemberSteps = {
  basicDetails: 'basicDetails',
  customizeOnboarding: 'customizeOnboarding',
};

export const presetDuplicateMessages = 'A preset with this name already exists. Use a unique name.';

export const customizeOnboardingTitle = 'Customize Onboarding';

export const customizeOnboardingTooltip = 'Customize the Getting Started page as the user’s landing page in InfiniGrow.';
