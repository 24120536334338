import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import { Checkbox } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';
import useOnClickOutside from 'components/pages/users/useOnClickOutside';

import style from 'styles/analyze/attributionTable/inlineColumnController.css';
import tableStyle from 'styles/controls/table.css';

const styles = style.locals || {};
const tableStyles = tableStyle.locals || {};

export default function InlineColumnController({
  options = [],
  onChange,
  selectedOptions,
}) {
  useStyles([style, tableStyle]);

  const [isShowPopup, setIsShowPopup] = useState(false);

  const popupRef = useRef(null);
  useOnClickOutside(popupRef, () => {
    setIsShowPopup(false);
  });

  const hasSelectedOption = selectedOptions.length > 0;

  return (
    <div
      ref={popupRef}
      className={classnames(tableStyles.inlineColumnController, (hasSelectedOption || isShowPopup) && tableStyles.inlineColumnControllerActive)}
    >
      <div
        className={classnames(styles.hashTagButton, hasSelectedOption && styles.active)}
        onClick={() => setIsShowPopup((prev) => !prev)}
        data-testid="inlineColumnControllerButton"
      />

      {isShowPopup ? (
        <div className={styles.controllerPopup}>
          {options.map((option) => (
            <div key={option.value} className={styles.optionItem}>
              <Checkbox
                checked={selectedOptions.includes(option.value)}
                onChange={() => onChange(option.value)}
                className={styles.checkbox}
              >
                {option.label}
              </Checkbox>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
