import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Component from 'components/Component';
import {
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
  Bar,
} from 'recharts';
import style from 'styles/analyze/performance-chart.css';
import { isEmpty } from 'lodash';
import { formatIndicatorDisplay } from 'components/utils/indicators';
import Loading3Dots from 'components/controls/Loading3Dots';
import ComparisonValue from 'components/common/ComparisonValue';
import { getIsGrowthDesired, getGrowthValue } from 'components/pages/analyze/utils/comparisonUtils';

class PerformanceChart extends Component {
  style = style;

  tooltip = (data) => {
    const { indicator, isCompareToPreviousEnabled } = this.props;
    const {
      active, label, payload,
    } = data;

    if (active && !isEmpty(payload) && payload[0]) {
      const growth = payload[0].payload.growth;

      return (
        <div className={classnames(
          this.classes.chartTooltip,
          isCompareToPreviousEnabled && this.classes.isCompareTo
        )}
        >
          <div className={this.classes.tooltipContent}>
            <div>
              <div className={this.classes.tooltipLabel}>{label}</div>
              <div className={this.classes.tooltipValue}>
                {formatIndicatorDisplay(indicator, payload[0].value)}
              </div>
            </div>

            {isCompareToPreviousEnabled ? (
              growth === undefined ? (
                <Loading3Dots />
              ) : (
                <ComparisonValue
                  value={getGrowthValue({
                    metricName: indicator,
                    growthPerMetric: { [indicator]: growth },
                  })}
                  isGrowthDesired={getIsGrowthDesired({ metricName: indicator })}
                  className={this.classes.comparisonValue}
                />
              )
            ) : null}
          </div>
        </div>
      );
    }

    return null;
  };

  render() {
    const { performanceData, indicator } = this.props;

    const maxValue = Math.max(60, 15 * performanceData
      .reduce((maxValueAccumulator, item) => Math.max(maxValueAccumulator, Math.abs(item.value)), 0)
      .toString()
      .length);

    return (
      <div className={this.classes.chartHolder}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={performanceData}>
            <CartesianGrid strokeDasharray="2 4" vertical={false} />
            <XAxis
              dataKey="name"
              tickMargin={20}
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fill: '#707ea7' }}
            />
            <YAxis
              tickMargin={20}
              tickFormatter={(value) => parseInt(formatIndicatorDisplay(indicator, Math.abs(value)).replace(/^\D+|,/g, ''), 10)}
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fill: '#707ea7' }}
              width={maxValue}
            />
            <RechartsTooltip
              cursor={false}
              offset={-60}
              content={this.tooltip}
              animationDuration={50}
              allowEscapeViewBox={{ x: true, y: true }}
            />
            <Bar dataKey="value" fill="#a6c8fe" barSize={32} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
PerformanceChart.defaultProps = {
  performanceData: [],
};

PerformanceChart.propTypes = {
  performanceData: PropTypes.arrayOf(PropTypes.shape),
};

export default PerformanceChart;
