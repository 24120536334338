import timeFrameModule from 'modules/timeframe';
import userStore from 'stores/userStore';
import base from './base';

function getWidgetConfig({ widgetConfig }) {
  if (!widgetConfig) {
    return widgetConfig;
  }
  const baseConfig = base.getWidgetConfig({ widgetConfig });

  if (baseConfig.whatIfParams?.timeframe?.value) {
    const { startDate, endDate } = timeFrameModule.getTimeframeParams({
      value: baseConfig.whatIfParams.timeframe.value,
      fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth,
      shouldUseEndOfPeriod: true,
    });
    baseConfig.whatIfParams.timeframe = {
      value: baseConfig.whatIfParams.timeframe.value,
      startDate,
      endDate,
    };
  }

  return baseConfig;
}

export default {
  ...base,
  getWidgetConfig,
};
