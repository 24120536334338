import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { get } from 'lodash';

import userStore from 'stores/userStore';
import Tooltip from 'components/controls/Tooltip';
import NavigationMenuPopup from 'components/pages/analyze/NavigationMenuPopup';
import Skeleton from 'components/common/Skeleton';
import { getMixOfColors } from 'components/utils/colors';
import ComparisonValue from 'components/common/ComparisonValue';
import Loading3Dots from 'components/controls/Loading3Dots';

import { getIsGrowthDesired, getGrowthValue } from 'components/pages/analyze/utils/comparisonUtils';

import style from 'styles/dashboard/dashboard.css';

const styles = style.locals;

export default function CohortChartBar({
  indicator, indicators, index, conversionRate, withVelocity, isLoaded, isLoadedConversionRate,
  velocity, getNavigationMenuParams, currentTimeFrame, textClass, conversionRateData, shouldUseAccountConversionRate, currentGrowths, isCompareToPreviousEnabled,
}) {
  useEffect(() => {
    style.use();

    return () => {
      style.unuse();
    };
  }, []);

  const [showNavigationMenuPopup, setShowNavigationMenuPopup] = useState(false);
  const [isBarHover, setIsBarHover] = useState(false);
  const width = 100;
  const color = getMixOfColors({ length: indicators.length });

  const getCohortChartBarStyles = () => {
    const cohortChartBarStyles = { width: `${(width / indicators.length) * (indicators.length - index)}%`, backgroundColor: color[index] };
    if (isBarHover) {
      cohortChartBarStyles.backgroundColor = color[index - 1];
      return cohortChartBarStyles;
    }
    return cohortChartBarStyles;
  };

  let sourceAccountText = 'account';
  const totalSourceRelatedAccountIds = get(conversionRateData, 'total_source_related_account_ids', 0);
  if (totalSourceRelatedAccountIds > 1) {
    sourceAccountText = 'accounts';
  }

  let destAccountText = 'account';
  if ((totalSourceRelatedAccountIds * conversionRate) / 100 > 1) {
    destAccountText = 'accounts';
  }

  const growthValue = currentGrowths[indicator];

  if (!isLoaded) {
    return (
      <div
        key={`indicators-${indicator}-${index}`}
        className={classnames(styles.miniFunnelRowCell, styles.miniFunnelRowCellValue)}
      >
        <div
          className={styles.miniFunnelStage}
          style={{ ...getCohortChartBarStyles(), backgroundColor: 'transparent' }}
        >
          <Skeleton height={22} borderRadius={8} />
        </div>
      </div>
    );
  }

  return (
    <div
      key={`indicators-${indicator}-${index}`}
      className={classnames(styles.miniFunnelRowCell, styles.miniFunnelRowCellValue)}
      onClick={() => setShowNavigationMenuPopup(true)}
    >
      <div
        className={styles.miniFunnelStage}
        style={getCohortChartBarStyles()}
        onMouseEnter={() => setIsBarHover(true)}
        onMouseLeave={() => setIsBarHover(false)}
      >
        <Tooltip
          content={(
            <>
              {growthValue === undefined ? (
                <Loading3Dots />
              ) : (
                <ComparisonValue
                  value={getGrowthValue({
                    metricName: indicator,
                    growthPerMetric: { [indicator]: growthValue },
                  })}
                  isGrowthDesired={getIsGrowthDesired({ metricName: indicator })}
                  className={styles.comparisonValue}
                  overrideTextColor="#FFFFFF"
                />
              )}
            </>
          )}
          id={`${indicator}-funnelCompare`}
          hidden={!isCompareToPreviousEnabled}
        >
          {currentTimeFrame[indicator]?.toLocaleString() || 0}
        </Tooltip>
        {index !== indicators.length - 1 && (
          <Tooltip
            className={classnames(styles.miniFunnelConv, textClass)}
            tip={` ${shouldUseAccountConversionRate
              ? `${Math.round(currentTimeFrame[indicator]) || 0} ${userStore.getMetricNickname({ metric: indicator, isSingular: true })}
              from ${Math.round(conversionRateData.total_source_related_account_ids) || 0} ${sourceAccountText} have converted into
              ${Math.round(conversionRateData.total_dest_entity_ids?.[indicators[index + 1]]) || 0} ${userStore.getMetricNickname({ metric: indicators[index + 1], isSingular: true })}
              from ${Math.round((conversionRateData.total_source_related_account_ids * conversionRate) / 100) || 0} ${destAccountText} (${Math.round(conversionRate)}%)`
              : `${Math.round(currentTimeFrame[indicator]) || 0} ${userStore.getMetricNickname({ metric: indicator, isSingular: true })}
              converted to  ${Math.round((currentTimeFrame[indicator] * conversionRate) / 100) || 0}
              ${userStore.getMetricNickname({ metric: indicators[index + 1], isSingular: true })}`}
            `}
            id={`${indicator}-conversion`}
          >
            <div className={styles.curvedArrow} />
            {isLoadedConversionRate ? (
              <>
                {`${Math.round(conversionRate)} %`}
                {withVelocity && Number.isInteger(velocity) && `, ${velocity}d`}
              </>
            ) : (
              <Skeleton height={16} width={24} isLightTheme />
            )}
          </Tooltip>
        )}
      </div>
      {isLoaded && showNavigationMenuPopup && (
        <div className={styles.navigationMenu}>
          <NavigationMenuPopup
            navigationOptions={getNavigationMenuParams(indicator)}
            onClosePopup={(showNavigationPopup) => setShowNavigationMenuPopup(showNavigationPopup)}
          />
        </div>
      )}
    </div>
  );
}
