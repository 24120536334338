import { metricsTypes, upliftLabel } from 'components/common/logic/enums';

export const columnControllersKeys = {
  uplift: 'uplift',
  pacing: 'pacing',
  compare: 'compare',
  shareOfTotal: 'shareOfTotal',
};

export const columnControllersLabels = {
  [columnControllersKeys.uplift]: {
    label: upliftLabel,
    shortLabel: upliftLabel,
  },
  [columnControllersKeys.pacing]: {
    label: 'Pacing for',
    shortLabel: 'Pacing for',
  },
  [columnControllersKeys.compare]: {
    label: 'Compare to avg.',
    shortLabel: 'Vs avg.',
  },
  [columnControllersKeys.shareOfTotal]: {
    label: 'Share of Total',
    shortLabel: '% of Total',
  },
};

export const defaultSubColumnsOptionsByType = {
  [metricsTypes.attributed]: [
    columnControllersKeys.shareOfTotal,
    columnControllersKeys.pacing,
    columnControllersKeys.compare,
  ],
  [metricsTypes.touched]: [
    columnControllersKeys.shareOfTotal,
    columnControllersKeys.pacing,
    columnControllersKeys.compare,
  ],
  [metricsTypes.conversionRate]: [
    columnControllersKeys.compare,
    columnControllersKeys.uplift,
  ],
  [metricsTypes.velocity]: [
    columnControllersKeys.compare,
  ],
  [metricsTypes.costPer]: [
    columnControllersKeys.compare,
  ],
  [metricsTypes.revenue]: [
    columnControllersKeys.shareOfTotal,
    columnControllersKeys.pacing,
    columnControllersKeys.compare,
  ],
  [metricsTypes.avgDealSize]: [
    columnControllersKeys.compare,
  ],
  [metricsTypes.ROI]: [
    columnControllersKeys.compare,
  ],
};

export const defaultSelectedSubColumnsByType = {
  [metricsTypes.attributed]: [
    columnControllersKeys.shareOfTotal,
  ],
  [metricsTypes.conversionRate]: [
    columnControllersKeys.uplift,
  ],
};
