import React, { useEffect, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import history from 'history';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { Button } from '@infinigrow/libs';

import serverCommunication from 'data/serverCommunication';

import useStyles from 'hooks/useStyles';

import Loader from 'components/controls/Loader';
import PresetLinksBox from 'components/pages/onboarding/PresetLinksBox';
import GuideProgressBox from 'components/pages/onboarding/GuideProgressBox';

import { parsedReportsData, parsedQuestionsData } from 'components/pages/onboarding/logic/onboarding';
import { initialGuidesCompletionStatus } from 'components/pages/onboarding/enums';
import { queryParamsNames } from 'components/pages/analyze/enums';
import { getQueryParams, removeQueryParams } from 'components/utils/UrlParamsProvider';
import { Events } from 'trackers/analytics/enums';
import { tabsPaths } from 'enums';

import servicesStore from 'stores/servicesStore';

import style from 'styles/getting-started/getting-started.css';
import pageStyle from 'styles/page.css';

const styles = style.locals || {};
const pageStyles = pageStyle.locals || {};

function GettingStarted({
  flags,
  getUserOnboardingPreset,
  onboardingConfig,
  teamMembers,
}) {
  useStyles([style, pageStyle]);

  if (!flags.onboardingGettingStartedPage) {
    history.push(tabsPaths.home);
  }

  const [userOnboardingData, setUserOnboardingData] = useState(null);

  useEffect(() => {
    getUserOnboardingData();
  }, [onboardingConfig, teamMembers]);

  if (!userOnboardingData) {
    return <Loader />;
  }

  async function getUserOnboardingData() {
    const userPreset = await getUserOnboardingPreset();
    const profile = servicesStore.authService.getProfileSync();
    const currentTeamMember = teamMembers.find((member) => member.email === profile.email);

    const onboardingData = {};
    onboardingData.reports = parsedReportsData({ data: userPreset?.reports });
    onboardingData.questions = parsedQuestionsData({ data: userPreset?.questions });
    onboardingData.firstName = currentTeamMember?.firstName;
    onboardingData.onboardingConfig = onboardingConfig;

    const userGuidesStatus = currentTeamMember?.guidesCompletionStatus || initialGuidesCompletionStatus;
    const guideCompletedParam = getQueryParams({ queryParamKey: queryParamsNames.guideCompleted });
    if (guideCompletedParam) {
      userGuidesStatus[guideCompletedParam] = true;
      const updatedTeamMember = {
        ...currentTeamMember,
        guidesCompletionStatus: userGuidesStatus,
      };
      await updateTeamMemberRequest({ member: updatedTeamMember });
      trackGuideCompleted({ guideId: guideCompletedParam });
    }
    onboardingData.guidesCompletionStatus = userGuidesStatus;
    setUserOnboardingData(onboardingData);
  }

  async function updateTeamMemberRequest({ member }) {
    try {
      const response = await serverCommunication.serverRequest('PUT', 'members', JSON.stringify({
        memberData: member,
        isNewMember: false,
      }));
      if (response.ok) {
        removeQueryParams({ queryParamKey: queryParamsNames.guideCompleted });
      }
    } catch (error) {
      servicesStore.logger.error('failed to update a team member', { error });
    }
  }

  function trackWatchDemo() {
    servicesStore.eventTracker.track({ eventName: Events.onboardingDemoClicked });
  }

  function trackGuideClicked({ guideId }) {
    servicesStore.eventTracker.track({
      eventName: Events.onboardingGuideClicked,
      properties: {
        guide: guideId,
      },
    });
  }

  function trackGuideCompleted({ guideId }) {
    servicesStore.eventTracker.track({
      eventName: Events.onboardingGuideCompleted,
      properties: {
        guide: guideId,
      },
    });
  }

  return (
    <div className={classNames(pageStyles.container, pageStyles.analyzeContainer, styles.wrapper)}>
      <div>
        <div className={styles.title}>
          {`Hey ${userOnboardingData?.firstName}, Welcome to InfiniGrow!`}
        </div>
        <div className={styles.subtitle}>
          This page will help you get up and running quickly.
        </div>
        <Button type="primaryBlue" onClick={() => trackWatchDemo()}>
          Watch Demo ▶
        </Button>
      </div>
      <div className={styles.content}>
        <div className={styles.guides}>
          <div>
            <div className={styles.title}>
              Explore use cases
            </div>
            <div className={styles.subtitle}>
              See InfiniGrow in action with step by step explainers, on your own data.
            </div>
          </div>
          {Object.keys(userOnboardingData.guidesCompletionStatus).map((guideId) => (
            <GuideProgressBox
              key={guideId}
              guideId={guideId}
              isCompleted={userOnboardingData?.guidesCompletionStatus?.[guideId]}
              onClick={() => trackGuideClicked({ guideId })}
            />
          ))}
        </div>
        <div className={styles.presetLinksBox}>
          <div>
            <div className={styles.title}>
              Your reports and questions
            </div>
            <div className={styles.subtitle}>
              Access selected reports and business questions quickly.
            </div>
          </div>
          <PresetLinksBox
            title="Your reports"
            links={userOnboardingData?.reports}
            icon="links:reports"
            tooltip="These reports have been selected specifically for you, to get you started quickly."
            bottomButton={{
              name: 'Create a new report',
              linkTo: '/reports/new',
            }}
          />
          <PresetLinksBox
            title="Your business questions"
            links={userOnboardingData?.questions}
            icon="links:questions"
            tooltip="These questions have been selected specifically for you, to get you started quickly."
            bottomButton={{
              name: 'Ask any business question',
              linkTo: '/questions',
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default withLDConsumer()(inject(
  ({
    userStore: {
      getUserOnboardingPreset,
      userAccount: {
        onboardingConfig,
        teamMembers,
      } = {},
    },
  }) => ({
    getUserOnboardingPreset,
    onboardingConfig,
    teamMembers,
  }),
  observer
)(GettingStarted));
