exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".breadcrumbsBudges__breadcrumbsBudgesContainer-3FQ{display:flex;gap:6px;align-items:center;max-width:calc(100vw - 120px);flex-wrap:wrap}.breadcrumbsBudges__breadcrumbsBudge-D4-{display:flex;border-radius:20px;padding:6px 10px;font-weight:600;color:var(--colorsGeneralBlue);background-color:var(--colorsBackgroundsBackgroundWhite);box-shadow:0 2px 4px 0 rgba(0,0,0,.16);cursor:pointer;border:1px solid transparent;font-size:14px}.breadcrumbsBudges__breadcrumbsBudge-D4-:not(.breadcrumbsBudges__disabled-1am):hover{border:1px solid var(--colorsGeneralBlue)}.breadcrumbsBudges__disabled-1am{background-color:var(--colorsBackgroundsBackgroundLightGray);color:var(--colorsGeneralIgBlack);font-weight:400;box-shadow:unset;cursor:default}.breadcrumbsBudges__iconContainer-3Wu{height:20px;width:20px;display:flex;align-items:center;justify-content:center}.breadcrumbsBudges__selectedOption-3fV{padding-left:6px;font-weight:400;color:var(--colorsGeneralIgBlack)}.breadcrumbsBudges__breadcrumbsBudgeTitleContainer-WYw{display:flex}.breadcrumbsBudges__selectedOptionTooltip-2Vm{max-width:150px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}", ""]);

// exports
exports.locals = {
	"breadcrumbsBudgesContainer": "breadcrumbsBudges__breadcrumbsBudgesContainer-3FQ",
	"breadcrumbsBudgesContainer": "breadcrumbsBudges__breadcrumbsBudgesContainer-3FQ",
	"breadcrumbsBudge": "breadcrumbsBudges__breadcrumbsBudge-D4-",
	"breadcrumbsBudge": "breadcrumbsBudges__breadcrumbsBudge-D4-",
	"disabled": "breadcrumbsBudges__disabled-1am",
	"disabled": "breadcrumbsBudges__disabled-1am",
	"iconContainer": "breadcrumbsBudges__iconContainer-3Wu",
	"iconContainer": "breadcrumbsBudges__iconContainer-3Wu",
	"selectedOption": "breadcrumbsBudges__selectedOption-3fV",
	"selectedOption": "breadcrumbsBudges__selectedOption-3fV",
	"breadcrumbsBudgeTitleContainer": "breadcrumbsBudges__breadcrumbsBudgeTitleContainer-WYw",
	"breadcrumbsBudgeTitleContainer": "breadcrumbsBudges__breadcrumbsBudgeTitleContainer-WYw",
	"selectedOptionTooltip": "breadcrumbsBudges__selectedOptionTooltip-2Vm",
	"selectedOptionTooltip": "breadcrumbsBudges__selectedOptionTooltip-2Vm"
};