import { tabsToFeatureFlags } from 'enums';

export function isActiveInChildRoutes({ childRoutes, activeTier }) {
  if (!childRoutes) { return false; }

  return childRoutes.some((route) => {
    if (route.tabName === activeTier) {
      return true;
    }
    if (route.childRoutes) {
      return isActiveInChildRoutes({ childRoutes: route, activeTier });
    }
    return false;
  });
}

function findNavigationTiers({ routes, targetPath, currentMainTier = null }) {
  for (const route of routes) {
    const activeMainTier = route.id ? route : currentMainTier;

    if (route.path === targetPath) {
      return {
        mainTier: activeMainTier?.id,
        subTier: route?.tabName,
      };
    }

    if (route.childRoutes?.length) {
      const childResult = findNavigationTiers({
        routes: route.childRoutes,
        targetPath,
        currentMainTier: activeMainTier,
      });
      if (childResult.mainTier || childResult.subTier) {
        return childResult;
      }
    }
  }
  return {
    mainTier: null,
    subTier: null,
  };
}

export const getCurrentTierByPath = ({ globalRoutes, path }) => {
  const normalizedTargetPath = path?.replace(/\/$/, '');

  return findNavigationTiers({
    routes: globalRoutes,
    targetPath: normalizedTargetPath,
  });
};

export function getFilterRoutesByFlagsAndPermissions({ routes, flags, routesToRemove = [] }) {
  const routesToShow = [];

  for (const route of routes) {
    const routeFlag = tabsToFeatureFlags[route?.path];
    const shouldShowRouteByFlag = routeFlag ? flags[routeFlag] : true;
    const shouldShowRouteByPermissions = route.path && !routesToRemove.includes(route.path);

    if (shouldShowRouteByFlag && shouldShowRouteByPermissions) {
      const newRoute = {
        ...route,
        childRoutes: route.childRoutes ? getFilterRoutesByFlagsAndPermissions({ routes: route.childRoutes, flags, routesToRemove }) : undefined,
      };
      routesToShow.push(newRoute);
      continue;
    }

    if (route.childRoutes) {
      const filteredChildrenRoutes = getFilterRoutesByFlagsAndPermissions({ routes: route.childRoutes, flags, routesToRemove });
      if (filteredChildrenRoutes.length > 0) {
        const newRoute = {
          ...route,
          childRoutes: filteredChildrenRoutes,
        };
        routesToShow.push(newRoute);
      }
    }
  }

  return routesToShow;
}
