import { useEffect, useRef, useState } from 'react';

import useStyles from 'hooks/useStyles';
import useWindowDimensions from 'components/utils/getWindowDimensions';

import CurtainOverlay from 'components/common/CurtainOverlay';
import BreadcrumbsBudges from 'components/common/BreadcrumbsBudges';
import SegmentsDrilldown from 'components/widgets/segmentsDrilldown';
import StageOverview from 'components/widgets/segmentsDrilldown/StageOverview';
import { getBreadcrumbs, getDrilldownMetricsData } from 'components/widgets/segmentsDrilldown/logic/drilldownCurtain';
import { mergeDataWithUpliftData } from 'components/pages/analyze/AttribuitonTable/logic/AttributionSegmentsTableParseData';

import style from 'styles/segmentsDrilldown/segmentsDrilldown.css';

const styles = style.locals || {};

export default function DrilldownCurtain({
  isLoaded,
  isLoadedUplift,
  isLoadedFunnelAnalysis,
  data,
  onClose,
  timeframe,
  fullTimeFrame,
  selectedStage,
  originalStage,
  breakdownSegment,
  updateBreakdownSegment,
  supportedMetrics,
  upliftColumnsKeys,
  updateUpliftColumnsKeys,
  drilldownMetricsOptions,
  defaultDrilldownSelectedMetric,
  isCompareToPrevious,
  onChangeCompareToPrevious,
}) {
  useStyles([style]);

  const [breadcrumbs, setBreadcrumbs] = useState(getBreadcrumbs({ selectedStage, breakdownSegment, onClickBreadcrumb }));
  const [tableTotals, setTableTotals] = useState({ [breakdownSegment.value]: data.totals || {} });

  const breadcrumbsBudgesContainerRef = useRef(null);

  useEffect(() => {
    setTableTotals({ [breakdownSegment.value]: data.totals || {} });
  }, [data.totals]);

  const { height: screenHeight } = useWindowDimensions();
  const breadCrumbsContainerHeight = breadcrumbsBudgesContainerRef?.current?.offsetHeight || 65;
  const segmentsDrilldownTableHeight = `${screenHeight - 163 - breadCrumbsContainerHeight}px`;

  function onClickBreadcrumb({ currentStepIndex }) {
    onUpdateBreakdownSegment({ segment: breadcrumbs[currentStepIndex] });
  }

  function onUpdateBreakdownSegment({
    segment, selectedRow, shouldAddNewBreadcrumb = true,
  }) {
    const shouldUpdateSessionFilters = shouldAddNewBreadcrumb;
    updateBreakdownSegment({ segment, selectedRow, shouldUpdateSessionFilters });
    const selectedOption = selectedRow ? selectedRow.firstSegment : null;

    const updatedBreadcrumbs = getBreadcrumbs({
      currentBreadcrumbs: breadcrumbs,
      selectedStage,
      breakdownSegment: segment,
      selectedOption,
      onClickBreadcrumb,
      shouldAddNewBreadcrumb,
    });

    setBreadcrumbs(updatedBreadcrumbs);
    setTableTotals({ ...tableTotals, [segment.value]: selectedRow });
  }

  const tableDataWithUplift = mergeDataWithUpliftData({ tableData: data.tableData, upliftData: data.upliftData });
  const previousBreakdownSegments = breadcrumbs.map((breadcrumb) => breadcrumb.value);

  return (
    <CurtainOverlay onClose={onClose}>
      <div className={styles.headerContainer} ref={breadcrumbsBudgesContainerRef}>
        <BreadcrumbsBudges breadcrumbs={breadcrumbs} />
      </div>
      <div className={styles.curtainContent}>
        <div className={styles.curtainLeftSideContainer}>
          <StageOverview
            data={data.funnelsData}
            supportedMetrics={supportedMetrics}
            selectedStage={selectedStage}
            fullTimeFrame={fullTimeFrame}
            originalStage={originalStage}
            drilldownMetricsOptions={drilldownMetricsOptions}
            drilldownMetricsData={getDrilldownMetricsData({ drilldownMetricsOptions, data: data.funnelsData, isLoaded: isLoadedFunnelAnalysis })}
            isLoaded={isLoadedFunnelAnalysis}
          />
        </div>
        <div className={styles.curtainRightSideContainer}>
          <SegmentsDrilldown
            timeframe={timeframe}
            tableHeight={segmentsDrilldownTableHeight}
            data={tableDataWithUplift}
            isAttribution={data.isAttribution}
            selectedStage={originalStage || selectedStage}
            totals={tableTotals?.[breakdownSegment.value] || {}}
            upliftColumnsKeys={upliftColumnsKeys}
            updateUpliftColumnsKeys={updateUpliftColumnsKeys}
            isLoaded={isLoaded}
            isLoadedUplift={isLoadedUplift}
            breakdownSegment={breakdownSegment.value}
            previousBreakdownSegments={previousBreakdownSegments}
            updateBreakdownSegment={({ segment, selectedRow, shouldAddNewBreadcrumb }) => onUpdateBreakdownSegment({ segment, selectedRow, shouldAddNewBreadcrumb })}
            defaultDrilldownSelectedMetric={defaultDrilldownSelectedMetric}
            isCompareToPrevious={isCompareToPrevious}
            onChangeCompareToPrevious={onChangeCompareToPrevious}
          />
        </div>
      </div>
    </CurtainOverlay>
  );
}
