import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import { directions } from 'components/common/enums';

import style from 'styles/common/metaDataBox.css';

const styles = style.locals || {};

export default function MetaDataBox({
  options, renderChild, childClass, direction,
}) {
  useStyles([style]);

  if (options?.length === 0) {
    return null;
  }

  return (
    <div className={classnames(styles.metaDataBoxContainer, direction === directions.vertical && styles.column)}>
      {options?.map((option, index) => {
        const optionKey = option.label ? `meta-data-box-${option.label}-${index}` : `meta-data-box-${index}`;
        return (
          <div key={optionKey} className={classnames(styles.metricDataBoxOption, direction === directions.vertical && styles.column, childClass)}>
            {renderChild(option)}
          </div>
        );
      })}
    </div>
  );
}
