import { Link } from 'react-router';
import classnames from 'classnames';
import React, { useMemo } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { inject, observer } from 'mobx-react';

import useStyles from 'hooks/useStyles';

import NavigationTierItems from 'components/navigationMenu/NavigationTierItems';
import BetaTag from 'components/common/BetaTag';

import { getCurrentTierByPath, getFilterRoutesByFlagsAndPermissions } from 'components/navigationMenu/logic/navigationTierItems';

import style from 'styles/navigationMenu/sideBarNavigationMenu.css';

const styles = style.locals || {};

function NavigationTierTabs({
  mainRoutes,
  globalRoutes,
  activeTier,
  setActiveTier,
  isSideMenuOpen,
  subMenuTabsNamesWithBetaTag,
  dividerId,
  userPermissions,
  flags,
}) {
  useStyles([style]);

  const { mainTier: currentMainTierByPath } = useMemo(() => getCurrentTierByPath({ globalRoutes, path: window.location.pathname }), [globalRoutes, window.location.pathname]);

  const filterTiersByFlagsAndPermissions = useMemo(() => getFilterRoutesByFlagsAndPermissions({ routes: globalRoutes, flags, routesToRemove: userPermissions?.pagesToHideFromUser }), [globalRoutes, userPermissions, flags]);

  return (
    <>
      {mainRoutes.map((tab) => {
        const tabRoute = filterTiersByFlagsAndPermissions.find((route) => route.id === tab.id);
        const hasChildRoutes = tabRoute?.childRoutes?.length > 0;
        const isShowSubTier = activeTier.mainTier === tab.id && hasChildRoutes && isSideMenuOpen;
        const isShowDivider = tab.id === dividerId;
        const isActive = tab.id === currentMainTierByPath;

        return (
          <div key={tab.id}>
            {isShowDivider ? (
              <div className={styles.divider} data-testid={`divider-${tab.id}`} />
            ) : null}
            <Link
              to={tab.path}
              activeClassName={styles.menuSelectedItem}
              className={classnames(styles.menuItem, isActive && styles.menuSelectedItem)}
              key={tab.id}
              onlyActiveOnIndex
              onClick={() => setActiveTier(activeTier?.mainTier === tab.id ? {} : { mainTier: tab.id, subTier: null })}
            >
              <div data-icon={`navigationMenu:${tab.icon}`} />
              <div className={styles.showWhenMenuIsOpen}>
                <div className={styles.tierName}>
                  <div>{tab.tabName}</div>
                  <BetaTag
                    dataTestId={`beta-tag-${tab.tabName}`}
                    hidden={!subMenuTabsNamesWithBetaTag.includes(tab.tabName)}
                  />
                </div>
              </div>
              {hasChildRoutes && isSideMenuOpen ? (
                <div className={classnames(styles.arrowDown, isShowSubTier && styles.arrowUp)} />
              ) : null}
            </Link>
            {hasChildRoutes ? (
              <div className={classnames(styles.subMenuHide, isShowSubTier && styles.subMenuShow)}>
                <NavigationTierItems
                  tierRoutes={tabRoute.childRoutes}
                  activeTier={activeTier.subTier}
                  setActiveTier={(tierName) => setActiveTier({ mainTier: tab.id, subTier: tierName })}
                />
              </div>
            ) : null}
          </div>
        );
      })}
    </>
  );
}

export default withLDConsumer()(inject(
  ({
    userStore: {
      userPermissions,
    } = {},
  }) => ({
    userPermissions,
  }),
  observer
)(NavigationTierTabs));
