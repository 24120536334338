import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { camelCase } from 'lodash';

import useStyles from 'hooks/useStyles';

import userStore from 'stores/userStore';
import servicesStore from 'stores/servicesStore';

import AIAnswersAskAnythingBox from 'components/widgets/answers';
import FunnelAnalysis from 'components/widgets/funnelAnalysis';
import DrilldownCurtain from 'components/widgets/segmentsDrilldown/DrilldownCurtain';
import timeFrameModule from 'modules/timeframe';

import { Events } from 'trackers/analytics/enums';
import { widgetTypes } from 'components/pages/analyze/enums';
import { getWidgetsDataV2, getWidgetFullConfig, getWidgetsData } from 'components/pages/analyze/widgetsRequest';
import { FREQUENCY_VALUES } from 'components/utils/frequency';
import { getParsedFunnelAnalysisData } from 'components/widgets/funnelAnalysis/logic/funnelStage';
import { historicalPerformanceMockData } from 'components/pages/home/demoMockData';
import { getStageOverviewMetricsOptions } from 'components/widgets/segmentsDrilldown/logic/stageOverview';
import { metricsTypes, upliftLabel } from 'components/common/logic/enums';
import { getSessionFilters } from 'components/pages/home/logic/home';

import style from 'styles/home/home.css';

const styles = style.locals || {};

function Home({
  filters,
  funnels,
  attributionModel,
  autoFilter,
  timeFrame,
  getWidgetRequestId,
  historicalPerformance,
  accountOptimizationMetrics,
  segmentsDrilldown,
  upliftBySegments,
  flags,
}) {
  useStyles([style]);

  const [isLoaded, setIsLoaded] = useState({
    historicalPerformance: false,
    upliftBySegments: false,
    segmentsDrilldown: false,
  });

  const [isFailedToLoad, setIsFailedToLoad] = useState({
    historicalPerformance: false,
    upliftBySegments: false,
    segmentsDrilldown: false,
  });
  const [upliftColumnsKeys, setUpliftColumnsKeys] = useState([]);
  const [currentStage, setCurrentStage] = useState(null);
  const [breakdownSegment, setBreakdownSegment] = useState(null);
  const [currentSessionFilters, setCurrentSessionFilters] = useState([]);
  const [isCompareToPrevious, setIsCompareToPrevious] = useState(false);

  const accountOptimizationMetricsKey = 'accountOptimizationMetricsSegmentsDrilldown';

  useEffect(() => {
    requestFunnelAnalysisData();
  }, [timeFrame, filters, attributionModel, autoFilter]);

  useEffect(() => {
    if (currentStage) {
      const originalFunnel = currentStage.originalStage || currentStage.selectedStage;
      requestAccountOptimizationData({ originalFunnel });
      requestSegmentDrilldownData({});
      requestUpliftData({});
    }
  }, [timeFrame, filters, attributionModel, autoFilter, currentStage]);

  function requestFunnelAnalysisData() {
    setIsLoaded((prev) => ({ ...prev, historicalPerformance: false }));
    getWidgetsDataV2({
      widget: widgetTypes.historicalPerformance,
      widgetConfig: {
        historicalPerformanceFrequency: FREQUENCY_VALUES.MONTH,
      },
      isPreviousTimeFrame: true,
    });
  }

  function requestSegmentDrilldownData({
    newBreakdownSegment = breakdownSegment,
    newSessionFilters = currentSessionFilters,
    newIsCompareToPrevious = isCompareToPrevious,
  }) {
    setIsLoaded((prev) => ({ ...prev, segmentsDrilldown: false }));

    const originalFunnel = currentStage.originalStage || currentStage.selectedStage;
    getWidgetsDataV2({
      widget: widgetTypes.segmentsDrilldown,
      widgetConfig: {
        segmentsDrilldownParams: {
          funnel: originalFunnel,
          breakdown: newBreakdownSegment.value,
        },
        sessionFilters: newSessionFilters,
      },
      isPreviousTimeFrame: newIsCompareToPrevious,
    });
  }

  function requestUpliftData({
    newBreakdownSegment = breakdownSegment,
    newSessionFilters = currentSessionFilters,
    newUpliftColumnsKeys = upliftColumnsKeys,
  }) {
    if (newUpliftColumnsKeys.length > 0) {
      setIsLoaded((prev) => ({ ...prev, upliftBySegments: false }));

      const config = {
        upliftBySegmentsParams: {
          selectedMetrics: newUpliftColumnsKeys,
          firstSegment: newBreakdownSegment?.value,
          secondSegment: null,
        },
        sessionFilters: newSessionFilters,
      };

      const fullWidgetConfig = getWidgetFullConfig({ widgetConfig: config, isPreviousTimeFrame: isCompareToPrevious });
      const requestId = getWidgetRequestId({ widget: widgetTypes.upliftBySegments, widgetConfig: fullWidgetConfig });

      getWidgetsDataV2({
        widget: widgetTypes.upliftBySegments,
        widgetConfig: config,
        parentRequestId: requestId,
      });
    }
  }

  function requestAccountOptimizationData({ originalFunnel }) {
    setIsLoaded((prev) => ({ ...prev, accountOptimizationMetrics: false }));

    getWidgetsData({
      widgets: [widgetTypes.accountOptimizationMetrics],
      resultKeyName: accountOptimizationMetricsKey,
      configPerWidget: {
        [widgetTypes.accountOptimizationMetrics]: [{
          conversionIndicator: originalFunnel,
        }],
      },
    });
  }

  function getRequestResponseData({
    widgetConfig, isPreviousTimeFrame, widget, dataPerWidgetFromStore,
  }) {
    const fullWidgetConfig = getWidgetFullConfig({
      widgetConfig,
      isPreviousTimeFrame,
    });
    const requestId = getWidgetRequestId({ widget, widgetConfig: fullWidgetConfig });
    const isRequestLoaded = dataPerWidgetFromStore?.[requestId]?.status === 'finished';
    setIsLoaded((prev) => ({ ...prev, [widget]: isRequestLoaded }));

    if (dataPerWidgetFromStore?.[requestId]?.status === 'failed') {
      setIsFailedToLoad((prev) => ({ ...prev, [widget]: true }));
    }

    return dataPerWidgetFromStore?.[requestId]?.result || {};
  }

  function onUpdateUpliftColumnsKeys({ columnKey, isSkipRequest }) {
    let updatedUpliftColumnsKeys = [...upliftColumnsKeys];
    if (upliftColumnsKeys.includes(columnKey)) {
      updatedUpliftColumnsKeys = upliftColumnsKeys.filter((key) => key !== columnKey);
    } else {
      updatedUpliftColumnsKeys.push(columnKey);
    }
    setUpliftColumnsKeys(updatedUpliftColumnsKeys);

    if (isSkipRequest) {
      return;
    }
    requestUpliftData({ newUpliftColumnsKeys: updatedUpliftColumnsKeys });
  }

  function updateBreakdownSegment({ segment, selectedRow, shouldUpdateSessionFilters }) {
    let newSessionFilters = currentSessionFilters;
    if (breakdownSegment && shouldUpdateSessionFilters) {
      newSessionFilters = getSessionFilters({
        currentSessionFilters, selectedSegmentValue: selectedRow, currentBreakdownSegment: breakdownSegment, newBreakdownSegment: segment,
      });
      setCurrentSessionFilters(newSessionFilters);
    }

    requestSegmentDrilldownData({ newBreakdownSegment: segment, newSessionFilters });
    requestUpliftData({ newBreakdownSegment: segment, newSessionFilters });
    setBreakdownSegment(segment);
  }

  const funnelAnalysisData = useMemo(() => {
    if (flags.funnelAnalysisDemoOnly) {
      setIsLoaded((prev) => ({ ...prev, historicalPerformance: true }));
      return historicalPerformanceMockData;
    }

    const funnelAnalysisResponseData = getRequestResponseData({
      widgetConfig: {
        historicalPerformanceFrequency: FREQUENCY_VALUES.MONTH,
      },
      isPreviousTimeFrame: true,
      widget: widgetTypes.historicalPerformance,
      dataPerWidgetFromStore: historicalPerformance,
    });
    return getParsedFunnelAnalysisData({ data: funnelAnalysisResponseData, funnels });
  }, [timeFrame, filters, attributionModel, autoFilter, historicalPerformance]);

  const segmentsDrilldownData = useMemo(() => {
    let segmentsDrilldownResponseData = {};
    if (currentStage) {
      segmentsDrilldownResponseData = getRequestResponseData({
        widgetConfig: {
          segmentsDrilldownParams: {
            funnel: currentStage.originalStage || currentStage.selectedStage,
            breakdown: breakdownSegment.value,
          },
          sessionFilters: currentSessionFilters,
        },
        isPreviousTimeFrame: isCompareToPrevious,
        widget: widgetTypes.segmentsDrilldown,
        dataPerWidgetFromStore: segmentsDrilldown,
      });
    }
    return segmentsDrilldownResponseData;
  }, [timeFrame, filters, attributionModel, autoFilter, segmentsDrilldown, isCompareToPrevious, currentStage, breakdownSegment, currentSessionFilters, isLoaded.segmentsDrilldown]);

  const upliftData = useMemo(() => {
    let upliftResponseData = {};
    if (breakdownSegment) {
      upliftResponseData = getRequestResponseData({
        widgetConfig: {
          upliftBySegmentsParams: {
            selectedMetrics: upliftColumnsKeys,
            firstSegment: breakdownSegment?.value,
            secondSegment: null,
          },
          sessionFilters: currentSessionFilters,
        },
        isPreviousTimeFrame: isCompareToPrevious,
        widget: widgetTypes.upliftBySegments,
        dataPerWidgetFromStore: upliftBySegments,
      });
    }
    return upliftResponseData?.data;
  }, [timeFrame, filters, attributionModel, autoFilter, upliftBySegments, breakdownSegment, isCompareToPrevious, upliftColumnsKeys, isLoaded.upliftBySegments]);

  const fullTimeFrame = timeFrameModule.getTimeframeParams({
    ...timeFrame,
    fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth,
    shouldUseEndOfPeriod: true,
  });

  function onOpenCurtain({
    selectedStage, selectedSegment, supportedMetrics, originalStage,
  }) {
    const selectedStageValue = originalStage || selectedStage;
    const metricsOptions = getStageOverviewMetricsOptions({ currentStage: selectedStageValue, stages: funnels });

    const selectedFirstOption = Object.values(metricsOptions)[0];
    const defaultSelectedMetric = camelCase(`${selectedFirstOption.value} ${metricsTypes.conversionRate}`);
    if (upliftColumnsKeys.length === 0 && supportedMetrics.includes(metricsTypes.conversionRate)) {
      onUpdateUpliftColumnsKeys({ columnKey: `${defaultSelectedMetric}${upliftLabel}`, isSkipRequest: true });
    }

    setCurrentStage({
      selectedStage,
      originalStage,
      supportedMetrics,
      metricsOptions,
      defaultSelectedMetric,
    });
    setBreakdownSegment(selectedSegment);
  }

  function onCloseDrilldownCurtain() {
    setCurrentStage(null);
    setIsCompareToPrevious(false);
    setCurrentSessionFilters([]);
    setBreakdownSegment(null);
    setUpliftColumnsKeys([]);

    servicesStore.eventTracker.track({
      eventName: Events.funnelDrilldown.closeDrilldownPopup,
    });
  }

  function onChangeCompareToPrevious({ isCompare }) {
    requestSegmentDrilldownData({ newIsCompareToPrevious: isCompare });
    setIsCompareToPrevious(isCompare);
  }

  return (
    <div className={styles.wrapper}>
      <AIAnswersAskAnythingBox />

      <FunnelAnalysis
        timeframe={timeFrame}
        fullTimeFrame={fullTimeFrame}
        data={funnelAnalysisData}
        isFailedToLoad={isFailedToLoad.historicalPerformance}
        isLoaded={isLoaded.historicalPerformance}
        openCurtain={({
          selectedStage, selectedSegment, supportedMetrics, originalStage,
        }) => onOpenCurtain({
          selectedStage, selectedSegment, supportedMetrics, originalStage,
        })}
      />

      {currentStage ? (
        <DrilldownCurtain
          data={{
            tableData: segmentsDrilldownData?.[currentStage.originalStage || currentStage.selectedStage],
            totals: accountOptimizationMetrics?.[accountOptimizationMetricsKey]?.data,
            isAttribution: segmentsDrilldownData?.isAttribution,
            upliftData,
            funnelsData: funnelAnalysisData,
          }}
          isLoadedUplift={isLoaded.upliftBySegments}
          isLoadedFunnelAnalysis={isLoaded.historicalPerformance || isFailedToLoad.historicalPerformance}
          isLoaded={isLoaded.segmentsDrilldown && accountOptimizationMetrics?.[accountOptimizationMetricsKey]?.isLoaded}
          upliftColumnsKeys={upliftColumnsKeys}
          updateUpliftColumnsKeys={({ columnKey }) => onUpdateUpliftColumnsKeys({ columnKey })}
          timeframe={timeFrame}
          fullTimeFrame={fullTimeFrame}
          selectedStage={currentStage.selectedStage}
          drilldownMetricsOptions={currentStage.metricsOptions}
          originalStage={currentStage.originalStage || currentStage.selectedStage}
          defaultDrilldownSelectedMetric={currentStage.defaultSelectedMetric}
          breakdownSegment={breakdownSegment}
          updateBreakdownSegment={({ segment, selectedRow, shouldUpdateSessionFilters }) => updateBreakdownSegment({ segment, selectedRow, shouldUpdateSessionFilters })}
          supportedMetrics={currentStage.supportedMetrics}
          onClose={() => onCloseDrilldownCurtain()}
          isCompareToPrevious={isCompareToPrevious}
          onChangeCompareToPrevious={({ isCompare }) => onChangeCompareToPrevious({ isCompare })}
        />
      ) : null}
    </div>
  );
}

export default withLDConsumer()(inject(
  ({
    userStore: {
      funnels,
    },
    analysisStore: {
      dataPerWidget: {
        [widgetTypes.accountOptimizationMetrics]: accountOptimizationMetrics,
      },
    },
    widgetsAnalysisStore: {
      getWidgetRequestId,
      dataPerWidget: {
        [widgetTypes.historicalPerformance]: historicalPerformance,
        [widgetTypes.segmentsDrilldown]: segmentsDrilldown,
        [widgetTypes.upliftBySegments]: upliftBySegments,
      },
    },
    attributionStore: {
      timeFrame,
      filters,
      attributionModel,
      autoFilter,
    },
  }) => ({
    historicalPerformance,
    accountOptimizationMetrics,
    segmentsDrilldown,
    upliftBySegments,
    getWidgetRequestId,
    timeFrame,
    funnels,
    filters,
    attributionModel,
    autoFilter,
  }),
  observer
)(Home));
