import { nonCumulativeMetricTypes } from 'components/common/logic/enums';
import {
  getConversionRatesMetrics,
  getMoneyMetrics,
  getFunnelStagesMetrics,
  getVelocityMetrics,
} from 'components/utils/goals';

import userStore from 'stores/userStore';

export function getMetricsOptions({ funnels }) {
  const supportedMetrics = [
    ...getFunnelStagesMetrics({ funnels }),
    ...getVelocityMetrics({ funnels }),
    ...getConversionRatesMetrics({ funnels }),
    ...getMoneyMetrics({}),
  ];

  return supportedMetrics.map((metricKey) => {
    const metricNickname = userStore.getMetricNickname({ metric: metricKey.value });
    return {
      value: metricKey.value,
      label: metricNickname,
      type: metricKey.type,
      cumulative: !nonCumulativeMetricTypes.includes(metricKey.type),
    };
  });
}
