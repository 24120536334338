import { sumBy, camelCase, cloneDeep } from 'lodash';
import { CROSS_FUNNEL_TOGGLE } from 'components/utils/indicators';
import {
  crossFunnel, webVisits, pageViews, inlineFiltersKind,
} from 'components/pages/analyze/AttribuitonTable/enums';
import { numberWithZeroDefault, getItemLabelWithNoValue } from 'components/utils/logic/utils';
import { numberOrZero, formatNumberWithDecimalPoint } from 'components/utils/logic/budget';
import { getChannelNicknameWithDirect } from 'components/utils/channels';
import { contentURLSegmentValue, contentTypeSegmentValue, defaultSupportedUtms } from 'components/pages/analyze/SegmentsTab/logic/enums';
import { searchInAttributionTable, segmentsTimeValues } from 'components/pages/analyze/AttribuitonTable/logic/AttributionTable';
import {
  upliftRangeLimit, columnsBlackList, segmentsKeys, upliftLabel,
} from 'components/common/logic/enums';

export const getFooterTotalsForKey = ({
  columnKey, indicator, data, prevData, hideNoValuesRows,
}) => {
  let dataForIndicator = data[indicator] || [];
  if (hideNoValuesRows) {
    dataForIndicator = tableDataWithoutNoValue({ tableData: dataForIndicator });
  }
  const prevDataForIndicator = prevData[indicator] || [];
  let updateColumnKey = columnKey;
  if (columnKey === 'cost' && dataForIndicator.length > 0) {
    const dataColumnsKeys = Object.keys(dataForIndicator[0]);
    if (!dataColumnsKeys.includes(columnKey) && dataColumnsKeys.includes('estimatedCost')) {
      updateColumnKey = 'estimatedCost';
    }
  }
  if (columnKey.includes('attributed') && !columnKey.includes('Revenue') && !columnKey.includes('Pipeline')) {
    updateColumnKey = 'attributed';
  }
  if (columnKey.includes('touched') && !columnKey.includes('Revenue') && !columnKey.includes('Pipeline')) {
    updateColumnKey = 'touched';
  }
  if (columnKey === 'newBookings') {
    updateColumnKey = 'revenue';
  }
  if (columnKey === 'newPipeline') {
    updateColumnKey = 'pipeline';
  }
  const total = sumBy(dataForIndicator, numberWithZeroDefault((item) => item[updateColumnKey]));
  const prevTotal = sumBy(prevDataForIndicator, numberWithZeroDefault((item) => item[updateColumnKey]));
  return { total, prevTotal };
};

export function tableDataWithoutNoValue({ tableData }) {
  return tableData.filter((data) => data.firstSegment !== 'No Value');
}

export function tableDataWithoutOtherCampaigns({ tableData }) {
  return tableData.filter((data) => !data.firstSegment?.includes(' - other'));
}

// eslint-disable-next-line default-param-last
export const calculateAverage = (dataItems = [], columnKey, numberOfDigitsAfterPoint) => {
  let totalRowLength = dataItems.length;
  const sumAllItems = dataItems.reduce((sum, item) => {
    const itemData = item[columnKey] || 0;
    if (itemData === 0) {
      totalRowLength -= 1;
    }
    return sum + itemData;
  }, 0);
  const calculateAverageForItems = formatNumberWithDecimalPoint(sumAllItems / totalRowLength, numberOfDigitsAfterPoint);
  return numberOrZero(calculateAverageForItems);
};

export const getColumnTitleFromKey = (columnKey) => {
  const titlesColumnsObject = {
    MRR: 'Account MRR',
    ARR: 'Account ARR',
    averageSalesCycle: 'Average Sales Cycle',
    pipelineROI: 'Pipeline ROI',
    touchedRevenue: 'Touched Revenue',
    touchedPipeline: 'Touched Pipeline',
    estimatedCost: 'Estimated Cost',
    pipeline: 'Pipeline',
  };
  return titlesColumnsObject[columnKey] || columnKey;
};

export const moveObjectFromArrayToStart = (data, dataKey, itemToMove) => {
  const index = data.findIndex((item) => item[dataKey] === itemToMove);
  if (index !== -1) {
    const updatedData = [...data];
    updatedData.unshift(...updatedData.splice(index, 1));
    return updatedData;
  }
  return data;
};

export const getTrendValuesForCell = ({
  trendData, columnKey, firstSegment, secondSegment,
}) => {
  if (!trendData) {
    return null;
  }
  const currentTrendDataRow = trendData.find((item) => {
    if (secondSegment) {
      return item.firstSegment?.toLowerCase() === firstSegment?.toLowerCase() && item.secondSegment?.toLowerCase() === secondSegment?.toLowerCase();
    }
    return item.firstSegment?.toLowerCase() === firstSegment?.toLowerCase();
  });

  if (currentTrendDataRow) {
    const trendValue = currentTrendDataRow[`${columnKey}Trend`];
    const trendAvgRatioValue = currentTrendDataRow[`${columnKey}TrendAvgRatio`];
    if (trendValue == null && trendAvgRatioValue == null) {
      return null;
    }
    return { trendValue, trendAvgRatioValue };
  }
  return null;
};

const isValueInvalid = (value) => (!value && value !== 0) || value === '';

export function sortColumnMethod(a, b) {
  if (isValueInvalid(a) && isValueInvalid(b)) {
    return 0;
  }
  if (isValueInvalid(a)) {
    return -1;
  }
  if (isValueInvalid(b)) {
    return 1;
  }

  if (isNaN(a) || isNaN(b)) {
    const aStringLabel = getChannelNicknameWithDirect(a) || a;
    const bStringLabel = getChannelNicknameWithDirect(b) || b;
    return aStringLabel.toString().localeCompare(bStringLabel, undefined, { numeric: true, sensitivity: 'base' });
  } else {
    return a - b;
  }
}

export function getCrossFunnelData({ data = {}, funnels = [], type }) {
  const tableColumns = [...funnels];
  const allSegmentPairs = new Set();

  for (const columnName of tableColumns) {
    const dataByFunnel = data[columnName] || [];
    for (const entry of dataByFunnel) {
      allSegmentPairs.add(`${entry.firstSegment}|${entry.secondSegment}`);
    }
  }

  const crossFunnelData = {};
  for (const pair of allSegmentPairs) {
    const [firstSegment, secondSegment] = pair.split('|');
    crossFunnelData[pair] = { firstSegment, secondSegment };
    for (const columnName of tableColumns) {
      if (type === CROSS_FUNNEL_TOGGLE.EFFICIENCY) {
        crossFunnelData[pair][camelCase(`costPer ${columnName}`)] = 0;
      } else {
        crossFunnelData[pair][camelCase(`attributed ${columnName}`)] = 0;
      }
    }
  }

  for (const columnName of tableColumns) {
    const dataByFunnel = data[columnName] || [];
    for (const dataItem of dataByFunnel) {
      const pair = `${dataItem.firstSegment}|${dataItem.secondSegment}`;
      if (type === CROSS_FUNNEL_TOGGLE.EFFICIENCY) {
        crossFunnelData[pair][camelCase(`costPer ${columnName}`)] = dataItem.attributed;
        crossFunnelData[pair].cost = dataItem.cost || 0;
        crossFunnelData[pair].ROI = dataItem.ROI || 0;
        crossFunnelData[pair].pipelineROI = dataItem.pipelineROI || 0;
      } else {
        crossFunnelData[pair][camelCase(`attributed ${columnName}`)] = dataItem.attributed;
        crossFunnelData[pair].cost = dataItem.cost || 0;
        crossFunnelData[pair].pipeline = dataItem.pipeline || 0;
        crossFunnelData[pair].revenue = dataItem.revenue || 0;
      }
    }
  }
  return Object.values(crossFunnelData);
}

export function getTableColumnKeys({
  customColumnsKeys = [],
  selectedStageKey,
  firstSegmentValue,
  secondSegmentValue,
  userDynamicMetricsPerFunnel,
  userFunnels,
  pipelineFunnel,
  revenueFunnel,
  isEfficiency,
  isCrossFunnelView,
}) {
  userDynamicMetricsPerFunnel[pipelineFunnel] = userDynamicMetricsPerFunnel[pipelineFunnel]?.filter((item) => item !== 'newPipeline').concat('touchedPipeline', 'pipelineROI');
  userDynamicMetricsPerFunnel[revenueFunnel] = userDynamicMetricsPerFunnel[revenueFunnel]?.filter((item) => !['newBookings', 'CAC', 'churnRate', 'newPipeline'].includes(item)).concat('touchedRevenue');

  let columnsOfSelectedMetric = [];
  if (selectedStageKey) {
    columnsOfSelectedMetric = userDynamicMetricsPerFunnel[selectedStageKey] || [];
  }

  columnsOfSelectedMetric = columnsOfSelectedMetric.filter((column) => !columnsBlackList.includes(column) && !column.includes('costPerFunnel'));
  if (selectedStageKey === crossFunnel) {
    columnsOfSelectedMetric = userFunnels.map((userFunnel) => camelCase(`attributed ${userFunnel}`));
    columnsOfSelectedMetric.push('pipeline', 'revenue');
    if (isEfficiency) {
      columnsOfSelectedMetric = userFunnels.map((userFunnel) => camelCase(`cost per ${userFunnel}`));
      columnsOfSelectedMetric.push('pipelineROI', 'ROI');
    }
  }

  if (selectedStageKey === webVisits || selectedStageKey === pageViews) {
    columnsOfSelectedMetric = ['pageViewsToFunnel1ConversionRate', 'pageViewsToFunnel1Velocity'];
  }

  if (isCrossFunnelView) {
    if (isEfficiency) {
      columnsOfSelectedMetric.push(...userFunnels.map((userFunnel) => camelCase(`cost per ${userFunnel}`)));
    } else {
      columnsOfSelectedMetric.push(...userFunnels.map((userFunnel) => camelCase(`attributed ${userFunnel}`)));
    }
  }

  const showCostMetric = isShowCostMetric({ firstSegmentValue, secondSegmentValue });
  if (showCostMetric) {
    columnsOfSelectedMetric.push('cost');
  }

  const globalColumns = getDefaultColumns({ firstSegmentValue, secondSegmentValue, selectedStageKey });

  return Array.from(new Set([...columnsOfSelectedMetric, ...globalColumns, ...customColumnsKeys]));
}

export function isShowCostMetric({ firstSegmentValue, secondSegmentValue }) {
  return [firstSegmentValue, secondSegmentValue].some((segment) => ['department', 'category', 'channel', 'campaign', ...defaultSupportedUtms, ...segmentsTimeValues].includes(segment));
}

export function getDefaultColumns({ firstSegmentValue, secondSegmentValue, selectedStageKey }) {
  let globalColumns = ['firstSegment', 'secondSegment'];

  const showCostMetric = isShowCostMetric({ firstSegmentValue, secondSegmentValue });
  if (showCostMetric) {
    globalColumns.push('cost');
  }

  if (selectedStageKey === crossFunnel) {
    globalColumns = globalColumns.filter((column) => !['attributed', 'touched', 'efficiency'].includes(column));
  }

  if (selectedStageKey === webVisits || selectedStageKey === pageViews) {
    globalColumns = globalColumns.filter((column) => column !== 'touched');
  }

  return globalColumns;
}

export function removeFunnelKeyFromColumnKey({ columnKey }) {
  if (columnKey.includes('attributed')) {
    return 'attributed';
  }
  if (columnKey.includes('touched')) {
    return 'touched';
  }
  if (columnKey.includes('costPer')) {
    return 'efficiency';
  }
  return columnKey;
}

export function tableDataWithFiltersByColumns({ tableData, filtersByColumn, isRemoveFunnelKey = true }) {
  let updateTableData = [...tableData];

  for (const [columnKey, filter] of Object.entries(filtersByColumn)) {
    const updatedColumnKey = isRemoveFunnelKey ? removeFunnelKeyFromColumnKey({ columnKey }) : columnKey;

    const { kind, values: filters } = filter;

    if (kind === inlineFiltersKind.multiCheck) {
      const filterValues = new Set(filters.map((filterItem) => filterItem.value));
      updateTableData = updateTableData.filter((item) => {
        if (Array.isArray(item[updatedColumnKey])) {
          return item[updatedColumnKey].some((channel) => filterValues.has(channel));
        }
        return filterValues.has(item[updatedColumnKey]);
      });
      continue;
    }

    if (kind === inlineFiltersKind.numeric) {
      const { operator, value } = filters;
      if (value === '' || isNaN(value)) {
        continue;
      }

      const filterNumericValue = parseFloat(value);

      updateTableData = updateTableData.filter((item) => {
        const numericValue = parseFloat(item[updatedColumnKey]);
        switch (operator.value) {
          case 'LOWER':
            return numericValue < filterNumericValue;
          case 'HIGHER':
            return numericValue > filterNumericValue;
          case 'EQUALS':
            return numericValue === filterNumericValue;
          default:
            return false;
        }
      });
    }
  }
  return updateTableData;
}

export function getColumnOptionsByData({
  selectedSegments, tableData = [], columnKey,
}) {
  if (!selectedSegments || tableData?.length === 0) {
    return [];
  }

  const {
    label: segmentLabel,
    value: segmentValue,
  } = selectedSegments[columnKey] || {};
  const uniqueColumnData = [];
  const uniqueValues = new Set();

  let overrideColumnKey = null;
  const isFirstSegment = columnKey === segmentsKeys.firstSegment;
  const isContentSegmentsFirstURLSecondType = selectedSegments.firstSegment?.value === contentURLSegmentValue && selectedSegments.secondSegment?.value === contentTypeSegmentValue;
  if (isFirstSegment && isContentSegmentsFirstURLSecondType) {
    overrideColumnKey = 'title';
  }

  for (const tableRow of tableData) {
    if (!tableRow[columnKey]) {
      continue;
    }

    const optionItem = { label: tableRow[columnKey], value: tableRow[columnKey] };
    if (overrideColumnKey && tableRow[overrideColumnKey]) {
      optionItem.label = tableRow[overrideColumnKey];
    } else if (segmentValue && segmentLabel) {
      optionItem.label = getItemLabelWithNoValue({ value: optionItem.label, segment: segmentValue, segmentLabel }) || tableRow[columnKey];
    }

    if (uniqueValues.has(optionItem.value)) {
      continue;
    }
    uniqueValues.add(optionItem.value);
    uniqueColumnData.push(optionItem);
  }

  return uniqueColumnData.sort((a, b) => a.label?.localeCompare(b.label, undefined, { numeric: true, sensitivity: 'base' }));
}

export function getChannelsColumnOptionsByData({
  tableData = [], columnKey,
}) {
  if (tableData?.length === 0) {
    return [];
  }

  const uniqueChannelsValues = new Set();
  for (const tableRow of tableData) {
    const channelsArray = tableRow[columnKey];
    if (!channelsArray || channelsArray.length === 0) {
      continue;
    }
    for (const channel of channelsArray) {
      if (uniqueChannelsValues.has(channel)) {
        continue;
      }
      uniqueChannelsValues.add(channel);
    }
  }

  const uniqueChannelsColumnData = Array.from(uniqueChannelsValues).map((channel) => ({
    label: getChannelNicknameWithDirect(channel) || channel,
    value: channel,
  }));

  return uniqueChannelsColumnData.sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true, sensitivity: 'base' }));
}

export function getUpdateFilteredTableData({
  data,
  segment,
  searchValue,
  searchColumn,
  filtersByColumn,
  stageAmountKeys,
  isOtherCampaignsHidden,
  upliftData,
}) {
  const updateDataTable = { ...data };

  if (filtersByColumn || searchValue !== '') {
    for (const stageKey of stageAmountKeys) {
      if (!updateDataTable[stageKey]) {
        continue;
      }

      let updatedStageData = updateDataTable[stageKey];
      if (upliftData?.length > 0) {
        updatedStageData = mergeDataWithUpliftData({ tableData: updatedStageData, upliftData });
      }

      const filteredBySearchItems = searchInAttributionTable({
        segment,
        searchValue,
        searchColumn,
        segmentLabel: stageKey,
        tableData: updatedStageData,
      });

      const filteredByColumnsItems = tableDataWithFiltersByColumns({ tableData: filteredBySearchItems, filtersByColumn });

      const filteredByOtherCampaignsItems = isOtherCampaignsHidden ? tableDataWithoutOtherCampaigns({ tableData: filteredByColumnsItems }) : filteredByColumnsItems;

      updateDataTable[stageKey] = filteredByOtherCampaignsItems;
    }
  }
  return updateDataTable;
}

export function getUpliftMinMaxByMetric({ data, upliftColumnsKeys }) {
  const minMaxByMetric = {};

  for (const columnKey of upliftColumnsKeys) {
    if (data.length === 0) {
      minMaxByMetric[columnKey] = { minValue: 0, maxValue: 0 };
      continue;
    }

    const allValues = data.map((item) => item?.[columnKey] || 0);

    const minValue = Math.min(...allValues);
    const maxValue = Math.max(...allValues);
    minMaxByMetric[columnKey] = { minValue, maxValue };
  }

  return minMaxByMetric;
}

export function isTimeSegmentSelected({ segments }) {
  return segmentsTimeValues.includes(segments?.firstSegment?.value) || segmentsTimeValues.includes(segments?.secondSegment?.value);
}

export function mergeDataWithUpliftData({ tableData = [], upliftData = [] }) {
  const updatedTableData = cloneDeep(tableData);

  for (const tableItem of updatedTableData) {
    const upliftItem = upliftData.find((item) => {
      if (item.secondSegment) {
        return item.firstSegment === tableItem.firstSegment && item.secondSegment === tableItem.secondSegment;
      }
      return item.firstSegment === tableItem.firstSegment;
    });
    if (upliftItem) {
      for (const [upliftKey, upliftValue] of Object.entries(upliftItem)) {
        if (upliftKey === 'firstSegment' || upliftKey === 'secondSegment') {
          continue;
        }

        let updatedUpliftValue = upliftValue;
        if (upliftValue < upliftRangeLimit.min) {
          updatedUpliftValue = upliftRangeLimit.min;
        }

        if (upliftValue > upliftRangeLimit.max) {
          updatedUpliftValue = upliftRangeLimit.max;
        }
        tableItem[`${upliftKey}${upliftLabel}`] = updatedUpliftValue;
      }
    }
  }
  return updatedTableData;
}
