import React from 'react';
import { inject, observer } from 'mobx-react';

import Textfield from 'components/controls/Textfield';

import { formatIndicatorDisplay } from 'components/utils/indicators';
import { compose } from 'components/utils/utils';
import { getMonthTitle, getQuarterTitle } from 'components/utils/goals';

import objectivePopup from 'styles/welcome/objective-popup.css';

const objClasses = objectivePopup.locals;

const enhance = compose(
  inject(({ userStore }) => ({
    fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth,
    timezone: userStore.userMonthPlan.CRMConfig.timezone,
  })),
  observer
);

function MilestoneBox({
  metricName,
  targetValue,
  timeframe,
  isQuarter,
  onChange,
  disabled,
  fiscalYearFirstMonth,
  timezone,
}) {
  return (
    <div className={objClasses.month}>
      <div className={objClasses.monthText}>
        {
          isQuarter
            ? getQuarterTitle({ startDate: timeframe.startDate, timezone, fiscalYearFirstMonth })
            : getMonthTitle({ startDate: timeframe.startDate, timezone })
        }
      </div>
      <div className={objClasses.monthInputWrapper}>
        <Textfield
          value={metricName ? formatIndicatorDisplay(metricName, targetValue, false, false) : targetValue}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default enhance(MilestoneBox);
