import React from 'react';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { formatChannels } from 'components/utils/channels';
import { DEFAULT_TIME_ZONE } from 'components/utils/timeframe';
import { compose, FORECASTING_METHODS, PACING_METHODS } from 'components/utils/utils';
import {
  budgetTypeMapping,
  getCurrentFiscalYear,
  getMonthRange,
  getTwoYearDates,
  NUMBER_OF_FUTURE_MONTHS,
} from 'components/utils/date';
import {
  exchangeToUSD,
  extractNumberFromBudget,
  formatBudget,
  getAnnualBudget,
  getCurrencySymbol,
} from 'components/utils/budget';
import { getAttributionModelsWithFlags } from 'attribution/models';
import attributionCredits from 'attribution/credits';

import Component from 'components/Component';
import Page from 'components/Page';
import Textfield from 'components/controls/Textfield';
import Toggle from 'components/controls/Toggle';
import Select from 'components/controls/Select';
import Label from 'components/ControlsLabel';
import Notice from 'components/Notice';
import MultiRow from 'components/MultiRow';
import Title from 'components/onboarding/Title';
import SaveButton from 'components/pages/profile/SaveButton';
import MultiSelect from 'components/controls/MultiSelect';
import Range from 'components/controls/Range';
import ChannelsSelect from 'components/common/ChannelsSelect';
import Dropdown from 'components/controls/Dropdown';

import style from 'styles/onboarding/onboarding.css';
import preferencesStyle from 'styles/preferences/preferences.css';
import analyzeStyle from 'styles/analyze/analyze.css';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

function getReportsPopulatedFolders({
  foldersList = [],
  reportsList = [],
}) {
  const reportsByFolders = {};
  for (const reportItem of reportsList) {
    const {
      label,
      value,
    } = reportItem;

    const folderId = reportItem.folderId || 'Unassigned';

    if (!reportsByFolders[folderId]) {
      reportsByFolders[folderId] = [];
    }

    reportsByFolders[folderId].push({
      label,
      value,
    });
  }

  const sortedFolders = foldersList.toSorted((a, b) => a.label.localeCompare(b.label));

  const reportsPopulatedFolders = [];
  for (const folderItem of sortedFolders) {
    const {
      value,
      label,
    } = folderItem;

    const folderReports = reportsByFolders[value];

    if (!folderReports) {
      continue;
    }

    reportsPopulatedFolders.push({
      label,
      options: (reportsByFolders[value]).toSorted((a, b) => (a.label).localeCompare(b.label)),
    });
  }

  return [
    {
      value: '',
      label: 'Home',
    },
    ...reportsPopulatedFolders,
    {
      label: 'Unassigned',
      options: reportsByFolders.Unassigned || [],
    },
  ];
}

const enhance = compose(
  inject(({
    reportsStore: {
      foldersList,
      reportsList,
    },
  }) => ({
    foldersList,
    reportsList,
  })),
  observer
);

class Preferences extends Component {
  style = style;

  styles = [preferencesStyle, analyzeStyle];

  budgetWeights = [0.05, 0.1, 0.19, 0.09, 0.09, 0.09, 0.04, 0.08, 0.1, 0.06, 0.07, 0.04];

  static defaultProps = {
    isCheckAnnual: true,
    maxChannels: -1,
    blockedChannels: [],
    inHouseChannels: [],
    annualBudgetArray: [],
    userAccount: {},
    actualIndicators: {},
    isDivideEqually: false,
    CRMConfig: {},
  };

  constructor(props) {
    super(props);

    let annualBudgetArray = [...props.annualBudgetArray];
    const expandLength = 2 * NUMBER_OF_FUTURE_MONTHS - annualBudgetArray.length;

    if (expandLength > 0) {
      annualBudgetArray = annualBudgetArray.concat(new Array(expandLength).fill(0));
      props.updateState({ annualBudgetArray, unsaved: false });
    }
    const defaultCurrency = get(props.currencies, 'base', 'USD');

    this.state = {
      fiscalYearFirstMonth: props.fiscalYearFirstMonth || 0,
      currency: props.currency || defaultCurrency,
      timezone: get(props, 'CRMConfig.timezone', DEFAULT_TIME_ZONE),
      budgetType: props.budgetType,
      isCheckAnnual: props.isCheckAnnual,
      isDivideEqually: props.isDivideEqually,
      forecastMethod: props.forecastMethod,
      pacingMethod: props.pacingMethod,
      showAdvancedFields: false,
      homePage: props.homePage || '',
    };
    this.blockedChannelRemove = this.blockedChannelRemove.bind(this);
    this.inHouseChannelRemove = this.inHouseChannelRemove.bind(this);
    this.budgetConstraintRemove = this.budgetConstraintRemove.bind(this);
    this.toggleBudgetsCheck = this.toggleBudgetsCheck.bind(this);
    this.calculateBudgets = this.calculateBudgets.bind(this);
  }

  componentDidMount() {
    // Advanced toggle open?
    if (this.props.maxChannels !== -1
      || Object.keys(this.props.budgetConstraints).length > 0
      || this.props.inHouseChannels.length > 0
      || this.props.blockedChannels.length > 0) {
      this.setState({ showAdvancedFields: true });
    }
  }

  getDataToUpdate = () => ({
    annualBudget: this.props.annualBudget,
    annualBudgetFiscalCurrent: this.props.annualBudgetFiscalCurrent,
    annualBudgetFiscalNext: this.props.annualBudgetFiscalNext,
    annualBudgetArray: this.props.annualBudgetArray,
    blockedChannels: this.props.blockedChannels,
    inHouseChannels: this.props.inHouseChannels,
    maxChannels: this.props.maxChannels,
    budgetConstraints: this.props.budgetConstraints,
    planNeedsUpdate: true,
    fiscalYearFirstMonth: this.state.fiscalYearFirstMonth,
    currency: this.state.currency,
    'CRMConfig.timezone': this.state.timezone,
    preferences: {
      ...this.props.preferences,
      ...{
        budgetType: this.state.budgetType,
        isCheckAnnual: this.state.isCheckAnnual,
        isDivideEqually: this.state.isDivideEqually,
        forecastMethod: this.state.forecastMethod,
        pacingMethod: this.state.pacingMethod,
        homePage: this.state.homePage,
      },
    },
  });

  getCurrentBudget() {
    const { budgetType } = this.state;
    const { annualBudgetArray, annualBudgetFiscalCurrent, annualBudgetFiscalNext } = this.props;
    return getAnnualBudget(annualBudgetArray, budgetType, annualBudgetFiscalCurrent, annualBudgetFiscalNext);
  }

  validate() {
    const { annualBudgetArray, fiscalYearFirstMonth } = this.props;
    const { budgetType, isCheckAnnual } = this.state;
    const currentBudget = this.getCurrentBudget();

    const { startMonthIndex, endMonthIndex } = getMonthRange(budgetType, fiscalYearFirstMonth);

    const filterNanArray = annualBudgetArray.slice(startMonthIndex, endMonthIndex + 1).filter((value) => !!value);

    if (!isCheckAnnual && filterNanArray.length !== (endMonthIndex - startMonthIndex + 1)) {
      this.refs.annualBudget.validationError();
      return 'Please fill all the required fields';
    }
    if (isCheckAnnual && currentBudget < 50000) {
      this.refs.annualBudget.validationError();
      return 'Please insert an annual budget higher than $50K';
    }

    return null;
  }

  handleChangeBudget(parameter, event) {
    const update = {};
    update[parameter] = exchangeToUSD(extractNumberFromBudget(event.target.value));
    this.refs.annualBudget.noValidationError();
    this.props.updateState(update, this.calculateBudgets);
  }

  handleChangeBudgetArray(index, event) {
    const update = this.props.annualBudgetArray || [];
    update.splice(index, 1, exchangeToUSD(extractNumberFromBudget(event.target.value)));
    this.refs.annualBudget.noValidationError();
    this.props.updateState({ annualBudgetArray: update }, this.calculateBudgets);
  }

  handleChangeBlockedChannels(event) {
    const update = event.map((obj) => obj.value);
    this.props.updateState({ blockedChannels: update });
  }

  handleChangeInHouseChannels(event) {
    const update = event.map((obj) => obj.value);
    this.props.updateState({ inHouseChannels: update });
  }

  handleChangeMax(parameter, event) {
    const number = parseInt(event.target.value, 10);
    if (number && number > 0) {
      this.props.updateState({ maxChannels: number });
    } else {
      this.props.updateState({ maxChannels: -1 });
    }
  }

  inHouseChannelRemove(index) {
    const update = this.props.inHouseChannels || [];
    update.splice(index, 1);
    this.props.updateState({ inHouseChannels: update });
  }

  blockedChannelRemove(index) {
    const update = this.props.blockedChannels || [];
    update.splice(index, 1);
    this.props.updateState({ blockedChannels: update });
  }

  budgetConstraintRemove(index) {
    const budgetConstraints = { ...this.props.budgetConstraints };
    const channel = Object.keys(budgetConstraints)[index];
    delete budgetConstraints[channel];
    this.props.updateState({ budgetConstraints });
  }

  addBudgetConstraintChannel(index, event) {
    const budgetConstraints = { ...this.props.budgetConstraints };
    const channel = event.value;
    const existingChannels = Object.keys(budgetConstraints);
    const numOfConstrains = existingChannels.length;
    // New line
    if (index === numOfConstrains) {
      if (!budgetConstraints[channel]) {
        budgetConstraints[channel] = {
          range: {
            min: 0,
            max: -1,
          },
        };
      }
    } else {
      // Existing line
      const oldChannel = existingChannels[index];
      budgetConstraints[channel] = budgetConstraints[oldChannel];
      delete budgetConstraints[oldChannel];
    }
    this.props.updateState({ budgetConstraints });
  }

  handleRangeChange(index, event) {
    const budgetConstraints = { ...this.props.budgetConstraints };
    const channel = Object.keys(budgetConstraints)[index];
    budgetConstraints[channel].range = event;
    this.props.updateState({ budgetConstraints });
  }

  monthBudgets() {
    const { fiscalYearFirstMonth } = this.props;
    const { budgetType } = this.state;

    const { startMonthIndex, endMonthIndex } = getMonthRange(budgetType, fiscalYearFirstMonth);
    const { startMonthIndex: currentMonthIndex } = getMonthRange(budgetTypeMapping.fiscalCurrent, fiscalYearFirstMonth);

    const datesArray = getTwoYearDates(currentMonthIndex);

    return datesArray.map((month, index) => {
      if (index < startMonthIndex || index > endMonthIndex) {
        return null;
      }
      const itemKey = `dates_${index}`;

      return (
        <div className={this.classes.cell} key={itemKey}>
          <Label style={{ width: '70px', marginTop: '12px' }}>{month}</Label>
          <Textfield
            value={this.props.annualBudgetArray[index] ? formatBudget(this.props.annualBudgetArray[index]) : getCurrencySymbol()}
            onChange={(event) => this.handleChangeBudgetArray(index, event)}
            style={{
              width: '166px',
            }}
          />
        </div>
      );
    });
  }

  toggleBudgetsCheck() {
    this.setState((prevState) => ({ isCheckAnnual: !prevState.isCheckAnnual }), this.calculateBudgets);
  }

  handleBudgetDivideChange() {
    this.setState((prevState) => ({ isDivideEqually: !prevState.isDivideEqually }), this.calculateBudgets);
  }

  handlePreferencesChange(value, preferenceType) {
    const { preferences, updateState } = this.props;
    preferences[preferenceType] = value;
    updateState({ preferences });
  }

  updateBudgetArray(startMonthIndex, endMonthIndex, prevBudget, annualBudgetArray) {
    const budget = [];
    if (this.state.isDivideEqually) {
      let numOfMonth = endMonthIndex - startMonthIndex + 1;
      const value = Math.round(prevBudget / numOfMonth);
      while (numOfMonth--) {
        budget.push(value);
      }
    } else {
      let budgetWeight = 0;
      this.budgetWeights.forEach((weight, index) => {
        if (index < endMonthIndex - startMonthIndex + 1) {
          budgetWeight += weight;
        }
      });
      this.budgetWeights.forEach((element, index) => {
        if (index < endMonthIndex - startMonthIndex + 1) { budget[index] = Math.round((element / budgetWeight) * prevBudget); }
      });
    }
    annualBudgetArray.splice(startMonthIndex, budget.length, ...budget);

    return annualBudgetArray;
  }

  calculateBudgets() {
    const { budgetType } = this.state;
    const { startMonthIndex, endMonthIndex } = getMonthRange(budgetType, this.props.fiscalYearFirstMonth);

    const { annualBudgetArray } = this.props;

    if (this.state.isCheckAnnual) {
      let prevBudget = this.getCurrentBudget();

      if (isNil(prevBudget)) {
        prevBudget = annualBudgetArray.slice(startMonthIndex, endMonthIndex + 1).reduce((a, b) => a + b, 0);
      }

      const budgetArray = this.updateBudgetArray(startMonthIndex, endMonthIndex, prevBudget, annualBudgetArray);

      const update = {};

      switch (budgetType) {
        case budgetTypeMapping.fiscalCurrent:
          update.annualBudgetFiscalCurrent = prevBudget;
          break;
        case budgetTypeMapping.fiscalNext:
          update.annualBudgetFiscalNext = prevBudget;
          break;
        case budgetTypeMapping.agile:
          update.annualBudget = prevBudget;
          break;
        default:
          break;
      }
      update.annualBudgetArray = budgetArray;
      this.props.updateState(update);
    }
    // In case we want the annual budget will change according to our monthly changes
    // else {
    //   const update = {};
    //   switch (budgetType) {
    //     case budgetTypeMapping.agile:
    //       update.annualBudget = null;
    //       break;
    //     case budgetTypeMapping.fiscalCurrent:
    //       update.annualBudgetFiscalCurrent = null;
    //       break;
    //     case budgetTypeMapping.fiscalNext:
    //       update.annualBudgetFiscalNext = null;
    //       break;
    //   }
    //   this.props.updateState(update);
    // }
  }

  getAttributionSelect(preferenceType, options) {
    return (
      <Select
        selected={this.props.preferences[preferenceType]}
        select={{
          options,
        }}
        onChange={(e) => this.handlePreferencesChange(e.value, preferenceType)}
        className={analyzeStyle.locals.modelSelect}
      />
    );
  }

  setFiscalMonth = (fiscalYearFirstMonth) => this.setState({ fiscalYearFirstMonth }, () => this.props.updateState({}));

  setCurrency = (currency) => this.setState({ currency }, () => this.props.updateState({}));

  setTimezone = (timezone) => this.setState({ timezone }, () => this.props.updateState({}));

  onSave = () => {
    const validationText = this.validate();
    this.setState({ validationError: validationText });

    const { startMonthIndex: start, endMonthIndex: end } = getMonthRange(budgetTypeMapping.fiscalNext, this.props.fiscalYearFirstMonth);
    const { startMonthIndex, endMonthIndex } = getMonthRange(budgetTypeMapping.fiscalCurrent, this.props.fiscalYearFirstMonth);
    const annualBudgetArray = [...this.props.annualBudgetArray];
    const annualBudgetFiscalNext = this.props.annualBudgetFiscalNext || annualBudgetArray.slice(start, end + 1).reduce((a, b) => a + b, 0);

    if (!validationText) {
      const update = this.getDataToUpdate();
      if (this.state.budgetType === budgetTypeMapping.fiscalCurrent && !annualBudgetFiscalNext) {
        update.annualBudgetFiscalNext = this.props.annualBudgetFiscalCurrent;
        const prevBudget = this.state.isCheckAnnual ? this.props.annualBudgetFiscalCurrent : annualBudgetArray.slice(startMonthIndex, endMonthIndex + 1).reduce((a, b) => a + b, 0);
        update.annualBudgetArray = this.updateBudgetArray(start, end, prevBudget, annualBudgetArray);
        this.props.updateState(update);
      }

      this.setState({ saveFail: false, saveSuccess: false });
      this.props.updateUserMonthPlan(update, this.props.region);
      this.setState({ saveSuccess: true });
    } else {
      this.setState({ saveFail: true });
    }
  };

  render() {
    const {
      budgetConstraints,
      annualBudgetArray,
      fiscalYearFirstMonth,
      annualBudgetFiscalCurrent,
      annualBudgetFiscalNext,
      currencies,
      foldersList,
      reportsList,
    } = this.props;

    const timeZones = moment.tz.names() || [];

    const isChannelOptionDisabled = (channel) => this.props.blockedChannels.includes(channel) || this.props.inHouseChannels.includes(channel) || Object.keys(budgetConstraints).includes(channel);

    const channels = {
      select: {
        name: 'channels',
        options: formatChannels(isChannelOptionDisabled),
      },
    };

    /* eslint-disable consistent-return */
    const maxChannels = (value) => {
      if (value.options) {
        value.options.map(maxChannels);
      } else {
        value.disabled = true;
        return value;
      }
    };
    /* eslint-enable consistent-return */

    // Deep copy
    const blockedChannels = JSON.parse(JSON.stringify(channels));
    // We allow only 3 blocked channels.
    if (this.props.blockedChannels.length >= 3) {
      // Disable all options
      blockedChannels.select.options.map(maxChannels);
    }

    const budgetConstraintsChannels = Object.keys(budgetConstraints);

    const { budgetType } = this.state;
    const currentBudget = getAnnualBudget(annualBudgetArray, budgetType, annualBudgetFiscalCurrent, annualBudgetFiscalNext);

    const setIsFiscal = (value) => {
      if (value) {
        if (budgetType === budgetTypeMapping.agile) {
          this.setState({ budgetType: budgetTypeMapping.fiscalCurrent }, this.calculateBudgets);
        }
      } else {
        this.setState({ budgetType: budgetTypeMapping.agile }, this.calculateBudgets);
      }
    };
    const setFiscalYear = (year) => {
      this.setState({ budgetType: year }, this.calculateBudgets);
    };

    const yearSelectOptions = [
      {
        label: getCurrentFiscalYear(fiscalYearFirstMonth).toString(),
        value: budgetTypeMapping.fiscalCurrent,
      },
      {
        label: (getCurrentFiscalYear(fiscalYearFirstMonth) + 1).toString(),
        value: budgetTypeMapping.fiscalNext,
      },
    ];
    const selectedToggleValue = !(budgetType === budgetTypeMapping.agile);

    const months = [...Array(12)].map((_, index) => {
      const mon = moment().month(index).format('MMMM');
      return { label: mon, value: index };
    });

    const currenciesArr = Object.keys(get(currencies, 'rates', {})).map((i) => ({
      value: i,
      label: `${i} ${getSymbolFromCurrency(i) || ''}`,
    }));

    const timezonesOptions = timeZones.map((value) => ({ value, label: value }));
    const forecastingMethodOptions = Object.keys(FORECASTING_METHODS).map((key) => ({
      label: FORECASTING_METHODS[key],
      value: FORECASTING_METHODS[key],
    }));
    const pacingMethodOptions = Object.keys(PACING_METHODS).map((key) => ({
      label: PACING_METHODS[key],
      value: PACING_METHODS[key],
    }));

    const attributionModelsItems = getAttributionModelsWithFlags(this.props.flags);

    function getOptionRendered({ label = '' }) {
      const [currencyCode, currencySign] = label.split(' ');
      return (
        <div style={{ paddingLeft: 7 }}>
          {currencyCode}
          <span style={{ marginLeft: 7 }}>{currencySign || ''}</span>
        </div>
      );
    }

    return (
      <div>
        <Page
          popup={false}
          className={this.classes.static}
          contentClassName={this.classes.content}
          innerClassName={this.classes.pageInner}
          width="100%"
        >
          <Title
            title="Preferences"
            subTitle="What are your marketing goals and constrains? Different objectives dictate different strategies"
          />
          <div className={this.classes.error}>
            <label hidden={!this.props.serverDown}>
              Something is wrong... Let us check what is it and fix it for you
              :)
            </label>
          </div>

          <div className={this.classes.row}>
            <div className={classNames(this.classes.cols, preferencesStyle.locals.homePageSelect)}>
              <Label
                style={{ marginTop: '12px' }}
              >
                Home Page
              </Label>

              <Dropdown
                selectedKey={this.state.homePage}
                options={getReportsPopulatedFolders({
                  foldersList,
                  reportsList,
                })}
                onChange={(e) => this.setState({ homePage: e.value })}
                controlWidth={300}
              />
            </div>
          </div>

          <div className={this.classes.row}>
            <div className={this.classes.cols} style={{ alignItems: 'center' }}>
              <Toggle
                options={[
                  {
                    text: 'Fiscal',
                    value: true,
                  },
                  {
                    text: 'Agile',
                    value: false,
                  },
                ]}
                selectedValue={selectedToggleValue}
                onClick={(value) => {
                  if (value !== selectedToggleValue) {
                    setIsFiscal(value);
                  }
                }}
              />
              {(budgetType < 2) && (
                <Select
                  selected={budgetType}
                  select={{
                    options: yearSelectOptions,
                  }}
                  onChange={(e) => setFiscalYear(e.value)}
                  style={{ width: '135px', marginLeft: '10px' }}
                />
              )}
            </div>
          </div>

          <div className={this.classes.cols}>
            <div className={this.classes.colLeft}>
              {/**
               <div className={ this.classes.row } style={{
              width: '258px'
            }}>
               <Label question>Start Date</Label>
               <Calendar />
               </div> * */}

              <div className={this.classes.row}>
                <Label
                  checkbox={this.state.isCheckAnnual}
                  onChange={() => this.toggleBudgetsCheck()}
                  question={['']}
                  ref="annualBudget"
                  description={['What is your marketing budget for the next 12 months?']}
                >
                  Plan Annual Budget
                  ($)
                </Label>
                <div className={this.classes.cell}>
                  <Textfield
                    disabled={!this.state.isCheckAnnual}
                    value={currentBudget ? formatBudget(currentBudget) : getCurrencySymbol()}
                    onChange={(event) => this.handleChangeBudget((budgetType === budgetTypeMapping.fiscalCurrent ? 'annualBudgetFiscalCurrent' : (budgetType === budgetTypeMapping.fiscalNext ? 'annualBudgetFiscalNext' : 'annualBudget')), event)}
                    style={{ width: '166px' }}
                  />
                  <Label
                    className={preferencesStyle.locals.divideEqually}
                    checkbox={this.state.isDivideEqually}
                    onChange={() => this.handleBudgetDivideChange()}
                  >
                    Divide Equally
                  </Label>
                  {/** <NotSure style={{
                  marginLeft: '10px'
                }} /> * */}
                </div>
              </div>

              <div className={this.classes.row}>
                <Label
                  checkbox={!this.state.isCheckAnnual}
                  onChange={() => this.toggleBudgetsCheck()}
                  question={['']}
                  description={['What is your marketing budget for the next 12 months?']}
                >
                  Plan Monthly Budgets
                  ($)
                </Label>
                {this.state.isCheckAnnual ? null : this.monthBudgets()}
              </div>

              {/**
               <div className={ this.classes.row } style={{
              // maxWidth: '440px',
              // minWidth: '200px',
              width: '258px'
            }}>
               <Select { ... selects.plan } />
               </div>
               * */}

              <div className={this.classes.cell}>
                <Label style={{ width: '225px', marginTop: '12px' }}>default attribution model</Label>
                <div style={{ zIndex: 5 }}>
                  {this.getAttributionSelect('defaultAttributionModel', attributionModelsItems)}
                </div>
              </div>

              <div className={this.classes.cell}>
                <Label style={{ width: '225px', marginTop: '12px' }}>default attribution credit</Label>
                {this.getAttributionSelect('defaultAttributionCredit', attributionCredits)}
              </div>

              <div className={this.classes.cell}>
                <Label style={{ marginTop: '12px' }}>Fiscal year first month</Label>
                <Select
                  selected={this.state.fiscalYearFirstMonth}
                  select={{ options: months }}
                  onChange={(e) => this.setFiscalMonth(e.value)}
                  style={{ width: 125, fontSize: 13 }}
                />
              </div>

              <div className={this.classes.cell}>
                <Label style={{ marginTop: '12px' }}>Currency</Label>
                <Select
                  selected={this.state.currency}
                  select={{ options: currenciesArr }}
                  onChange={(e) => this.setCurrency(e.value)}
                  style={{ width: 105 }}
                  withCurrency
                  optionRenderer={({ label = '' }) => getOptionRendered({ label })}
                />
              </div>

              <div className={this.classes.cell}>
                <Label style={{ marginTop: '12px' }}>Timezone</Label>
                <Select
                  selected={this.state.timezone}
                  select={{ options: timezonesOptions }}
                  onChange={(e) => this.setTimezone(e.value)}
                  style={{ width: 185, fontSize: 13 }}
                />
              </div>

              <div className={this.classes.cell}>
                <Label
                  style={{ marginTop: '12px' }}
                  question={['']}
                  description={['Choose whether to forecast business outcomes based on the current pace of each KPI, or based on the system analysis of your current planned budget and the expected outcomes of it']}
                >
                  Forecasting method
                </Label>
                <Select
                  selected={this.state.forecastMethod}
                  select={{ options: forecastingMethodOptions }}
                  onChange={(e) => this.setState({ forecastMethod: e.value })}
                  style={{ width: 210, fontSize: 13 }}
                />
              </div>

              <div className={this.classes.cell}>
                <Label
                  style={{ marginTop: '12px' }}
                  question={['']}
                  description={['Choose whether to forecast business outcomes based on the current pace of each KPI, or based on the system analysis of your current planned budget and the expected outcomes of it']}
                >
                  Pacing method
                </Label>
                <Select
                  selected={this.state.pacingMethod}
                  select={{ options: pacingMethodOptions }}
                  onChange={(e) => this.setState({ pacingMethod: e.value })}
                  style={{ width: 210, fontSize: 13 }}
                />
              </div>
              <div>
                <div
                  className={preferencesStyle.locals.advancedButton}
                  onClick={() => {
                    this.setState((prevState) => ({ showAdvancedFields: !prevState.showAdvancedFields }));
                  }}
                >
                  Advanced
                </div>
                <div hidden={!this.state.showAdvancedFields}>
                  <div className={this.classes.row}>
                    <Label style={{ fontSize: '20px', fontWeight: 'bold' }}>Channel Constraints (Optional)</Label>
                    <Notice
                      warning
                      style={{
                        margin: '12px 0',
                      }}
                    >
                      * Please notice that adding channel constrains is limiting the InfiniGrow’s ideal planning
                      engine.
                    </Notice>
                  </div>
                  <div className={this.classes.row}>
                    <Label
                      question={['']}
                      description={['Do you want to limit the number of channels in your plan (in parallel, for each month)? \nTo set the number to max available channels, please leave it blank.']}
                    >
                      max
                      number of Channels
                    </Label>
                    <div className={this.classes.cell}>
                      <Textfield
                        value={parseInt(this.props.maxChannels, 10) !== -1 ? this.props.maxChannels : ''}
                        onChange={(event) => this.handleChangeMax('', event)}
                        style={{
                          width: '83px',
                        }}
                      />
                      {/** <NotSure style={{
                  marginLeft: '10px'
                }} /> * */}
                    </div>
                  </div>
                  <div className={this.classes.row}>
                    <Label
                      question={['']}
                      description={['Are there any channels that you’re going to use in any case? Please provide their min/max budgets.']}
                    >
                      Monthly Budget Constraints
                    </Label>
                    <MultiRow numOfRows={budgetConstraintsChannels.length} rowRemoved={this.budgetConstraintRemove}>
                      {({
                        index, removeButton,
                      }) => (
                        <div className={preferencesStyle.locals.channelsRow}>
                          <ChannelsSelect
                            style={{ width: '230px' }}
                            selected={budgetConstraintsChannels[index]}
                            isChannelDisabled={isChannelOptionDisabled}
                            onChange={(event) => this.addBudgetConstraintChannel(index, event)}
                          />
                          <Range
                            disabled={!budgetConstraintsChannels[index]}
                            step={50}
                            allowSameValues
                            minValue={0}
                            maxValue={Math.min(...annualBudgetArray)}
                            value={budgetConstraints[budgetConstraintsChannels[index]]
                              ? budgetConstraints[budgetConstraintsChannels[index]].range
                              : {
                                min: 0,
                                max: -1,
                              }}
                            onChange={(event) => this.handleRangeChange(index, event)}
                          />
                          <div style={{ marginLeft: '25px', alignSelf: 'center' }}>
                            {removeButton}
                          </div>
                        </div>
                      )}
                    </MultiRow>
                  </div>
                  <div className={this.classes.row}>
                    <MultiSelect
                      {...channels}
                      selected={this.props.inHouseChannels}
                      onChange={(event) => this.handleChangeInHouseChannels(event)}
                      label="In-house Channels"
                      labelQuestion={['']}
                      description={['Are there any channels that you don’t want InfiniGrow to allocate budgets to because you’re doing them in-house?']}
                    />
                  </div>
                  <div className={this.classes.row}>
                    <MultiSelect
                      {...blockedChannels}
                      selected={this.props.blockedChannels}
                      onChange={(event) => this.handleChangeBlockedChannels(event)}
                      label="Blocked Channels"
                      labelQuestion={['']}
                      description={['From your experience at the company, are there any channels that you want to block InfiniGrow from using in your marketing planning? \n * Maximum allowed # of blocked channels: 3']}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={this.classes.footer}>
            <div className={this.classes.almostFooter}>
              <label hidden={!this.state.validationError} style={{ color: 'red' }}>
                {this.state.validationError}
              </label>
            </div>
            <SaveButton onClick={this.onSave} success={this.state.saveSuccess} fail={this.state.saveFail} />
          </div>
        </Page>
      </div>
    );
  }
}

export default withLDConsumer()(enhance(Preferences));
