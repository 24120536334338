import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import userStore from 'stores/userStore';
import ChartSkeleton from 'components/common/ChartSkeleton';
import ComparisonValue from 'components/common/ComparisonValue';
import Loading3Dots from 'components/controls/Loading3Dots';

import { getIsGrowthDesired, getGrowthValue } from 'components/pages/analyze/utils/comparisonUtils';
import {
  Line,
  LineChart,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import { getColor } from 'components/utils/colors';
import { formatIndicatorDisplay } from 'components/utils/indicators';
import { getItemLabelWithNoValue } from 'components/utils/logic/utils';
import { averageLine } from 'components/pages/analyze/ImpactBoard/enums';

import style from 'styles/analyze/generated-impact.css';

const styles = style.locals;

export default function ImpactLineChart({
  chartData = [],
  dataNames = [],
  indicator,
  currentSegmentLabel,
  firstSegment,
  colorIndexs,
  chartHeight = 400,
  emptyStateText = 'No values',
  isNonCumulativeMetric,
  growthImpactData = {},
  isLoaded,
  isCompareToPreviousEnabled,
  compareValueIgnoreNames,
  isEnableSkeletonAnimation,
}) {
  const isDataLoaded = chartData.length > 0 || isLoaded;

  useEffect(() => {
    style.use();
    return () => {
      style.unuse();
    };
  }, []);

  const [activeLine, setActiveLine] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const isAverage = activeLine === 'average';

  if (!isDataLoaded) {
    return (
      <ChartSkeleton isEnableSkeletonAnimation={isEnableSkeletonAnimation} />
    );
  }

  function ChartTooltip(tooltipProps) {
    const chosenMetric = userStore.getMetricNickname({ metric: indicator });
    const { payload, label, active } = tooltipProps;
    if (!active || !payload || !isHovering || !activeLine) {
      return null;
    }

    const currentPayload = payload.find((item) => item.name === activeLine);

    const isHideCompare = isAverage || !isCompareToPreviousEnabled || compareValueIgnoreNames[label]?.includes?.(currentPayload.name);
    const index = chartData.findIndex((period) => period.name === label);
    const total = chartData[index]?.total || 0;
    const percentFromTotal = Math.round((currentPayload.value / total) * 100);
    let itemName = getItemLabelWithNoValue({ value: currentPayload.name, segment: firstSegment, segmentLabel: currentSegmentLabel });
    if (currentPayload.name === averageLine.key) {
      itemName = averageLine.label;
    }
    const growthValue = growthImpactData[label]?.[currentPayload.name];

    return (
      <div className={styles.impactChartTooltip}>
        <div className={styles.impactChartTooltipLabel}>
          {`${label} - ${chosenMetric}`}
        </div>

        <div>
          <div
            className={styles.impactChartTooltipValue}
            key={currentPayload.name}
          >
            <div className={styles.row}>
              <div
                className={styles.circle}
                style={{ backgroundColor: currentPayload.color }}
              />
              <div>{itemName}</div>
            </div>

            <div className={styles.row}>
              <span style={{ marginRight: 5 }}>
                {formatIndicatorDisplay(indicator, currentPayload.value, true)}
              </span>

              {(!isNonCumulativeMetric && !isNaN(percentFromTotal)) ? (
                <>
                  {`( ${percentFromTotal} %)`}
                </>
              ) : null}

              {!isHideCompare ? (
                <>
                  {growthValue === undefined ? (
                    <Loading3Dots />
                  ) : (
                    <ComparisonValue
                      value={getGrowthValue({
                        metricName: indicator,
                        growthPerMetric: { [indicator]: growthValue },
                      })}
                      isGrowthDesired={getIsGrowthDesired({ metricName: indicator })}
                      className={styles.comparisonValue}
                      overrideTextColor="#FFFFFF"
                    />
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!dataNames.length) {
    return (
      <div className={styles.chartEmptyState}>
        {emptyStateText}
      </div>
    );
  }

  return (
    <div className={classnames(styles.chartBarItem, styles.chartBar)}>
      <ResponsiveContainer height={chartHeight}>
        <LineChart
          data={chartData}
          className={styles.chartBarWrapper}
          onMouseLeave={() => {
            setIsHovering(false);
            setActiveLine(null);
          }}
          cursor="pointer"
        >
          <CartesianGrid
            vertical={false}
            strokeDasharray="3 3"
            strokeWidth={1}
            stroke="rgba(54, 56, 64, 0.1)"
          />
          <XAxis
            dataKey="name"
            axisLine={false}
            tick={{ fontSize: '12px', color: '#707ea7' }}
            tickLine
            tickMargin={10}
            height={40}
            minTickGap={20}
          />
          <YAxis
            yAxisId="left"
            axisLine={false}
            tick={{ fontSize: '12px', color: '#707ea7' }}
            tickLine={false}
            tickMargin={15}
            tickFormatter={(value) => formatIndicatorDisplay(indicator, value, true)}
          />
          {dataNames.map((item) => {
            const isAverageLine = item === averageLine.key;

            return (
              <Line
                key={`line-${item}`}
                yAxisId="left"
                dataKey={item}
                stroke={isAverageLine ? '#707ea7' : getColor(colorIndexs[item])}
                strokeWidth={2}
                strokeDasharray={isAverageLine ? '5 5' : ''}
                isAnimationActive={false}
                onMouseEnter={() => {
                  setActiveLine(item);
                  setIsHovering(true);
                }}
                strokeOpacity={(activeLine === item || !isHovering) ? 1 : 0.25}
                dot={false}
                activeDot={activeLine === item}
              />
            );
          })}
          <RechartsTooltip
            cursor={false}
            wrapperStyle={{
              zIndex: 5, position: 'fixed', top: 0,
            }}
            offset={0}
            content={<ChartTooltip />}
            animationDuration={50}
            viewBox={{
              x: 0, y: 0, width: 300, height: 300,
            }}
            allowEscapeViewBox={{ x: true, y: true }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
