import { camelCase } from 'lodash';

import userStore from 'stores/userStore';

import { metricsTypes, metricTypeToLabelMapping } from 'components/common/logic/enums';
import { supportedMetricsKeys } from 'components/widgets/funnelAnalysis/enums';

export function getStageOverviewMetricsOptions({ currentStage, stages = [] }) {
  let currentStageIndex = stages.findIndex((stage) => stage === currentStage);
  const isLastStep = currentStageIndex === stages.length - 1;
  if (isLastStep) {
    currentStageIndex -= 1;
  }

  const currentStep = isLastStep ? stages[currentStageIndex] : currentStage;
  if (!currentStep || currentStageIndex < 0) {
    return [];
  }

  const currentStepLabel = userStore.getMetricNickname({ metric: currentStep, isSingular: false });
  const successiveSteps = [];
  for (let stageIndex = currentStageIndex + 1; stageIndex < stages.length; stageIndex++) {
    const stage = stages[stageIndex];
    if (stage) {
      successiveSteps.push({ label: userStore.getMetricNickname({ metric: stage, isSingular: false }), value: stage });
    }
  }
  const allPermutationsOfSuccessiveStepsOptions = successiveSteps.map((successiveStep) => ({
    value: camelCase(`${currentStep} to ${successiveStep.value}`),
    label: `${currentStepLabel} > ${successiveStep.label}`,
  }));
  return allPermutationsOfSuccessiveStepsOptions;
}

export function getSelectedMetricState({ drilldownMetricsData, metric }) {
  if (!drilldownMetricsData || !metric) {
    return {};
  }

  const selectedMetricData = [];
  const drilldownData = Object.entries(drilldownMetricsData[metric?.value]) || [];
  for (const [metricName, metricData] of drilldownData) {
    const metricKey = Object.values(metricsTypes)?.filter((metricType) => metricName.toLowerCase().includes(metricType.toLowerCase()));
    const metricLabel = metricKey[0] ? metricTypeToLabelMapping[metricKey[0]] : '';
    selectedMetricData.push({ metric: metricName, name: metricLabel, ...metricData });
  }

  return {
    selectedMetric: metric.value,
    selectedMetricData,
  };
}

export function isComparisonSupported({ metricLabel }) {
  const isPacingFor = metricLabel?.toLowerCase().includes(supportedMetricsKeys.pacing);
  return !isPacingFor;
}
