import React, {
  useEffect, useState, useRef, useMemo,
} from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import history from 'history';
import { Button } from '@infinigrow/libs';

import EventsTimeline from 'components/pages/users/EventsTimeline';
import Page from 'components/Page';
import Switch from 'components/controls/Switch';
import Spinner from 'components/pages/journeys/Spinner';

import {
  parseServerResponse,
  filterNonAttributedTouchpoints,
} from 'components/pages/analyze/inspectData/logic/drillDownPopup';
import {
  drillDownEventsLimit,
} from 'components/pages/analyze/inspectData/enums';

import style from 'styles/inspectData/drillDownPopup.css';

const styles = style.locals || {};

function DrillDownPopup({
  onClose,
  drillDownData,
  onGoBack,
  title,
  timezone,
  isLoaded,
  segments,
  metricType,
}) {
  useEffect(() => {
    style.use();

    return () => {
      if (!style.unuse) {
        return;
      }
      style.unuse();
    };
  }, []);

  const [allAttributionsState, setAllAttributionsState] = useState('hidden');
  const [selectedTransitionIndex, setSelectedTransitionIndex] = useState(0);
  const [paginationLimits, setPaginationLimits] = useState({
    hidden: drillDownEventsLimit,
    shown: drillDownEventsLimit,
  });
  const paginationLimit = paginationLimits[allAttributionsState];

  const {
    parsedDrillDownEntries,
    isThereMoreOptionsToTheLeft,
    isThereMoreOptionsToTheRight,
  } = useMemo(() => {
    const parsedDrillDownData = parseServerResponse({
      serverResponse: drillDownData,
      timeZone: timezone,
    });

    return {
      parsedDrillDownEntries: Object.entries(parsedDrillDownData[selectedTransitionIndex] ?? {}),
      isThereMoreOptionsToTheLeft: selectedTransitionIndex !== 0,
      isThereMoreOptionsToTheRight: selectedTransitionIndex !== parsedDrillDownData.length - 1,
    };
  }, [drillDownData, selectedTransitionIndex]);

  const filteredEntries = useMemo(() => {
    if (allAttributionsState === 'shown') {
      return parsedDrillDownEntries;
    }

    const filteredData = filterNonAttributedTouchpoints(
      {
        drillDownDataEntries: parsedDrillDownEntries,
        firstSegment: segments?.firstSegmentValue,
        secondSegment: segments?.secondSegment,
      }
    );
    return Object.entries(filteredData);
  }, [allAttributionsState, parsedDrillDownEntries]);

  const paginatedData = useMemo(
    () => Object.fromEntries(filteredEntries.slice(0, paginationLimit)),
    [filteredEntries, paginationLimit]
  );

  const showMore = () => {
    setPaginationLimits(
      (limits) => ({
        ...limits,
        [allAttributionsState]: limits[allAttributionsState] + drillDownEventsLimit,
      })
    );
  };

  function filterAttributionData() {
    setAllAttributionsState((prev) => (prev === 'shown' ? 'hidden' : 'shown'));
  }

  const refPopup = useRef();
  const maxPaginationLimit = filteredEntries.length - 1;

  return (
    <Page popup contentClassName={styles.content} width="660px">
      <div className={styles.wrapper} ref={refPopup}>
        <div
          className={classNames(styles.icon, styles.closeButton)}
          onClick={onClose}
        />
        {title && (
          <div className={styles.row}>
            {onGoBack && (
              <div
                className={styles.arrowLeftIcon}
                onClick={() => onGoBack()}
              />
            )}
            <div
              className={classNames(styles.text, styles.header)}
            >
              {title}
            </div>
          </div>
        )}
        {isLoaded ? (
          <>
            <div className={styles.row}>
              <Switch
                dataTestId="switchToShowAllData"
                isActive={allAttributionsState === 'shown'}
                onSwitchClick={() => filterAttributionData()}
              />
              <div className={classNames(styles.text, styles.switchText)}>
                Show all attributed touchpoints
              </div>
            </div>
            {(isThereMoreOptionsToTheRight || isThereMoreOptionsToTheLeft) && (
              <div className={styles.row}>
                <div
                  className={classNames(styles.row, isThereMoreOptionsToTheLeft ? null : styles.notClickableIndication)}
                  onClick={() => (isThereMoreOptionsToTheLeft ? setSelectedTransitionIndex(selectedTransitionIndex - 1) : null)}
                >
                  <div
                    className={classNames(styles.icon, styles.arrowLeftSmall)}
                  />
                  <div
                    className={styles.directionText}
                    data-disabled={!isThereMoreOptionsToTheLeft}
                  >
                    Previous
                    transition
                  </div>
                </div>
                <div
                  className={classNames(styles.row, isThereMoreOptionsToTheRight ? null : styles.notClickableIndication)}
                  onClick={() => (isThereMoreOptionsToTheRight ? setSelectedTransitionIndex(selectedTransitionIndex + 1) : null)}
                >
                  <div
                    className={styles.directionText}
                    data-disabled={!isThereMoreOptionsToTheRight}
                  >
                    Next
                    transition
                  </div>
                  <div
                    className={classNames(styles.icon, styles.arrowRightSmall)}
                  />
                </div>
              </div>
            )}
            {!isEmpty(paginatedData) && (
              <EventsTimeline
                key={`events-timeline-inspect-drilldown-${allAttributionsState}`}
                timezone={timezone}
                groupedEvents={paginatedData}
                moreToRender={paginationLimit < maxPaginationLimit}
                showMoreEvents={showMore}
                wrapperClassname={styles.eventTimeline}
                eventClassname={styles.eventTimeline}
                segments={segments}
                metricType={metricType}
              />
            )}
            <Button
              className={styles.viewAccountButton}
              type="primaryBlue"
              onClick={() => history.push({
                pathname: '/analyze/journeys',
                state: { searchQuery: title },
              })}
            >
              View Account journey
            </Button>
          </>
        ) : (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        )}
      </div>
    </Page>
  );
}

export default (inject(
  ({
    userStore: {
      userMonthPlan: {
        CRMConfig: {
          timezone,
        } = {},
      } = {},
    } = {},
  }) => ({
    timezone,
  }),
  observer
)(DrillDownPopup));
