import moment from 'moment';
import { camelCase, isNil } from 'lodash';

import userStore from 'stores/userStore';
import servicesStore from 'stores/servicesStore';
import { navigateBetweenAnalyzeTabs } from 'stores/analyze/logic/navigations';

import { Events } from 'trackers/analytics/enums';
import Dropdown from 'components/controls/Dropdown';
import { CONVERSION_RATE_MAPPING_SUFFIX, VELOCITY_MAPPING_SUFFIX } from 'components/utils/indicators';
import { getPacingCalculate } from 'components/utils/logic/utils';
import { funnelNextActions, supportedMetricsKeys } from 'components/widgets/funnelAnalysis/enums';
import { metricTypeToLabelMapping, metricsTypes } from 'components/common/logic/enums';
import { makeFunnelStagesFilter, VARIANTS } from 'components/utils/filters/make';
import { groupBySegmentsOptions } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { getSegmentsOptionsFields } from 'components/pages/analyze/OverviewTab/logic';

export function getStageCRTooltipText({
  volume,
  currentStage,
  nextStage,
  nextStageVolume,
  conversionRate,
  stagesConfigs,
}) {
  if (!conversionRate || !volume || !nextStageVolume) {
    return null;
  }

  const funnelStagesToTheirGroupByType = stagesConfigs?.funnelStagesToTheirGroupByType || {};
  const isSimpleConversionRate = stagesConfigs?.isSimpleConversionRate;

  let conversionRateData = {};
  if (stagesConfigs.crStatisticsJSON && stagesConfigs.crStatisticsJSON[currentStage]) {
    conversionRateData = stagesConfigs.crStatisticsJSON[currentStage];
  }

  const isTheSameObjectToTheNextFunnel = funnelStagesToTheirGroupByType[currentStage] === funnelStagesToTheirGroupByType[nextStage];
  const shouldUseAccountConversionRate = !isTheSameObjectToTheNextFunnel && !isSimpleConversionRate;

  let sourceAccountText = 'account';
  const totalSourceRelatedAccountIds = conversionRateData.total_source_related_account_ids || 0;
  if (totalSourceRelatedAccountIds > 1) {
    sourceAccountText = 'accounts';
  }

  let destAccountText = 'account';
  const calculatedConversionRate = (totalSourceRelatedAccountIds * conversionRate.value) / 100;

  if (conversionRate && calculatedConversionRate > 1) {
    destAccountText = 'accounts';
  }

  const currentStageValue = `${Math.round(volume.value) || 0} ${userStore.getMetricNickname({ metric: currentStage, isSingular: true })}`;
  const nextStageName = userStore.getMetricNickname({ metric: nextStage, isSingular: true });

  if (shouldUseAccountConversionRate) {
    const totalDestEntityIds = conversionRateData.total_dest_entity_ids?.[nextStage] || 0;
    return `${currentStageValue} from ${Math.round(totalSourceRelatedAccountIds)} ${sourceAccountText} have converted into ${Math.round(totalDestEntityIds)} ${nextStage} from ${Math.round(calculatedConversionRate)} ${destAccountText} (${Math.round(conversionRate.value)}%)`;
  }

  return `${currentStageValue} converted to ${Math.round((nextStageVolume.value * conversionRate.value) / 100) || 0} ${nextStageName}`;
}

export function getParsedFunnelAnalysisData({
  data, funnels,
}) {
  const parsedData = {};

  for (const dataKey of Object.keys(data)) {
    if (dataKey.includes(CONVERSION_RATE_MAPPING_SUFFIX) || dataKey.includes(VELOCITY_MAPPING_SUFFIX)) {
      parsedData[dataKey] = data[dataKey]?.total;
    }
  }

  parsedData.revenue = data.newBookings?.total;
  parsedData.revenueROI = data.ROI?.total;
  parsedData.revenueAvgDealSize = data.avgDealSize?.total;

  parsedData.pipeline = data.newPipeline?.total;
  parsedData.pipelineROI = data.pipelineROI?.total;
  parsedData.pipelineAvgDealSize = data.pipelineAvgDealSize?.total;

  for (const funnel of funnels) {
    const newFunnelKey = camelCase(`new ${funnel}`);
    parsedData[funnel] = data[newFunnelKey]?.total;

    const costPerFunnelKey = camelCase(`cost per ${funnel}`);
    parsedData[costPerFunnelKey] = data[costPerFunnelKey]?.total;
  }

  parsedData.stagesConfigs = {
    crStatisticsJSON: data.crStatisticsJSON,
    funnelStagesToTheirGroupByType: data.funnelStagesToTheirGroupByType,
    isSimpleConversionRate: data.isSimpleConversionRate,
  };

  return parsedData;
}

export function getFunnelStagesConfigurationData({
  salesforceapi,
  hubspotapi,
  funnelStagesToTheirGroupByType,
  predefinedFiltersConfig,
  reportSyncData,
  funnels,
}) {
  const configurationDataByFunnelStage = {};

  const funnelCRMMapping = {
    ...salesforceapi?.mapping?.funnelMapping || {},
    ...hubspotapi?.mapping?.funnelMapping || {},
  };

  for (const funnel of funnels) {
    let reportSyncDataByFunnel = null;
    if (reportSyncData && reportSyncData[funnel]) {
      reportSyncDataByFunnel = {
        isSynced: reportSyncData[funnel].isSynced,
        reportUrl: reportSyncData[funnel].reportUrl,
      };
    }

    configurationDataByFunnelStage[funnel] = {
      reportSyncData: reportSyncDataByFunnel,
      groupByType: funnelStagesToTheirGroupByType[funnel],
      mappingRules: funnelCRMMapping?.[funnel]?.rules || [],
      predefinedFilters: predefinedFiltersConfig?.[funnel] || [],
      currentFunnelStage: funnel,
    };
  }

  return configurationDataByFunnelStage;
}

export function getRevenueViewStages() {
  const revenueViewStages = [{
    value: 'revenue',
    label: 'Revenue',
  }];
  const { isPipelineStageHidden } = userStore.userMonthPlan || {};
  if (!isPipelineStageHidden) {
    revenueViewStages.unshift({
      value: 'pipeline',
      label: 'Pipeline',
    });
  }
  return revenueViewStages;
}

export function extractFieldsFromData({
  currentStage, data, fullTimeFrame,
}) {
  const stageVolumeValues = data[currentStage];
  const costPerKey = camelCase(`cost per ${currentStage}`);
  const costPerValues = data[costPerKey];
  const currentStageName = userStore.getMetricNickname({ metric: currentStage, isSingular: true });
  const pacingValue = stageVolumeValues?.value != null ? getPacingCalculate({
    timeframe: fullTimeFrame,
    actual: stageVolumeValues.value,
  }) : null;
  const isPastTimeframe = fullTimeFrame.endDate <= new Date();

  let averageDealSizeKey = metricsTypes.avgDealSize;
  let averageDealSizeValues = data[averageDealSizeKey];

  if (!averageDealSizeValues) {
    averageDealSizeKey = `${currentStage}AvgDealSize`;
    averageDealSizeValues = data[averageDealSizeKey];
  }

  const ROI = `${currentStage}ROI`;
  const ROIValues = data[ROI];

  const extractedData = {
    currentStep: {
      metric: currentStage,
      name: currentStageName,
      ...stageVolumeValues,
    },
    currentStepData: {
      pacing: {
        name: 'Pacing for',
        value: pacingValue,
      },
      costPer: {
        metric: costPerKey,
        name: `Cost per ${currentStageName}`,
        ...costPerValues,
      },
      avgDealSize: {
        name: metricTypeToLabelMapping.avgDealSize,
        ...averageDealSizeValues,
      },
      ROI: {
        metric: ROI,
        name: 'ROI',
        ...ROIValues,
      },
    },
  };

  if (isPastTimeframe) {
    delete extractedData.currentStepData.pacing;
  }
  return extractedData;
}

export function getStageMenuParams({
  onClickOption, currentStage, classNameMenu, timeframe,
}) {
  const segmentsOptionsFields = getSegmentsOptionsFields({ customFieldsIdToLabelMap: userStore.userAccount?.customFieldsIdToLabelMap, customUtmsWhitelist: userStore.userAccount?.customUtmsWhitelist });

  return (
    [
      {
        label: funnelNextActions.breakdown,
        component: (
          <Dropdown
            options={groupBySegmentsOptions({ options: segmentsOptionsFields })}
            selectedKey=""
            onChange={(option) => {
              onClickOption(option);

              servicesStore.eventTracker.track({
                eventName: Events.funnelAnalysis.nextActionFromFunnel,
                properties: {
                  funnelStage: currentStage,
                  nextAction: `${funnelNextActions.breakdown} ${option.suggestionsDropdownType}`,
                },
              });
            }}
            menuIsOpen
            isSearchable
            classNameMenu={classNameMenu}
            isShowDropdownIndicator={false}
            placeholder="Search..."
            autoFocus
            menuListMaxHeight="184px"
            menuShouldScrollIntoView={false}
            menuListWidth="240px"
          />),
        leftIcon: 'breakdown:arrow',
      },
      // TODO: uncomment this when add spotlight
      // {
      //   label: funnelNextActions.spotlight,
      //   leftIcon: breakdown:ai,
      //   action: () => {
      //     // TODO: Implement
      //     servicesStore.eventTracker.track({
      //       eventName: Events.funnelAnalysis.nextActionFromFunnel,
      //       properties: {
      //         funnelStage: currentStage,
      //         nextAction: funnelNextActions.spotlight,
      //       },
      //     });
      //   },
      // },
      {
        label: funnelNextActions.journeys,
        leftIcon: 'breakdown:queryStats',
        action: () => {
          navigateBetweenAnalyzeTabs({
            filters: [makeFunnelStagesFilter(VARIANTS.BECAME_ONE_OF, [currentStage])], tabName: 'journeys', timeframe,
          });

          servicesStore.eventTracker.track({
            eventName: Events.funnelAnalysis.nextActionFromFunnel,
            properties: {
              funnelStage: currentStage,
              nextAction: funnelNextActions.journeys,
            },
          });
        },
      }]
  );
}

export function getNavigationMenuParams({ indicator, widgetHeaderConfig, timeframe }) {
  const includeAttributionStoreFilters = isNil(widgetHeaderConfig);
  const widgetFilters = widgetHeaderConfig?.filters || [];

  return [{
    title: 'View journeys',
    subTitle: `Analyze ${userStore.getMetricNickname({ metric: indicator })} created during the timeframe`,
    navigationFunction: () => navigateBetweenAnalyzeTabs({ filters: [makeFunnelStagesFilter(VARIANTS.BECAME_ONE_OF, [indicator])], tabName: 'journeys', timeframe }),
  },
  {
    title: 'Breakdown by channel',
    subTitle: `Analyze ${userStore.getMetricNickname({ metric: indicator })} attribution by channel`,
    navigationFunction: () => navigateBetweenAnalyzeTabs({
      conversionIndicator: indicator, tabName: 'channels', anchor: 'attribution-table', timeframe, includeAttributionStoreFilters, filters: widgetFilters,
    }),
  },
  {
    title: 'Breakdown by campaign',
    subTitle: `Analyze ${userStore.getMetricNickname({ metric: indicator })} attribution by campaign`,
    navigationFunction: () => navigateBetweenAnalyzeTabs({
      conversionIndicator: indicator, tabName: 'campaigns', anchor: 'attribution-table', timeframe, includeAttributionStoreFilters, filters: widgetFilters,
    }),
  }];
}

export function filterPacingForPastTimeframes({ options, fullTimeFrame }) {
  const isPastFrame = fullTimeFrame.endDate <= moment();

  if (isPastFrame) {
    return options.filter((option) => option.value !== supportedMetricsKeys.pacing);
  }

  return options;
}
